import React, { useRef, useContext, useState, useEffect, useMemo } from 'react';
import './messenger.css';
import { getAvetar } from '../suggestionService';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import UserIcon from '../../../assets/images/profile_pic_1.png';
import kontactLogo from '../../../assets/images/icon_category_kontakt.png';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../../context/loginContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSlidersH, 
  faSearch, 
  faUserFriends, 
  faHeart, 
  faMars, 
  faVenus, 
  faTransgenderAlt, 
  faPeopleArrows
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ChatHistory from './chatHistory';

function UsersList(props) {
  const {
    users,
    isUser,
    setPageNo,
    currentUser,
    isLoading,
    isFilterUser,
    isFileLoader,
    setCurrentUser,
    setFilters,
    filters,
    isUserAvailable,
    isFileResponseReceived, 
    searchQuery,
    setSearchQuery,
    avatars,
    setUsers,
    setIsFileLoader,
    setIsFileResponseReceived,
    setAvatars,
    sendMessage,
    chats,
    userDetails,
    isMessageError,
    setIsMessageError,
    attachmentFiles,
    lastContactElementRef,
    lastOverviewElementRef
  } = props;

  const timeoutRef = useRef(null);
  const [currentAvatar, setCurrentAvatar] = useState('');
  const [showSearch,setShowSearch] = useState(false);
  const [showFilters,setShowFilters] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isOpenMessage, setIsOpenMessage] = useState(false);

  let {
    loginData: {
      memberDetails,
      accessToken,
      messageCount,
      unreadMessagesByAvailableUsers: contextUnreadMessagesByAvailableUsers ,
      unreadMessagesByAllUsers: contextUnreadMessagesByAllUsers 
    },
    dispatch
  } = useContext(LoginContext);

  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const token_lang = {
    language: currentLang,
    accessToken
  };

  const avtar = async (id) => {
    try {
      const result = await getAvetar(token_lang, id);
      if (result?.status === 200) {
        const blob = await result.blob();
        const fileURL = URL.createObjectURL(blob);
        return fileURL;
      }
    } catch (error) {
      console.error('Error fetching avatar:', error);
    }
    return '';
  };

  const observerRef = useRef(null);
  const memberRef = useRef([]);
  const avatarsRef = useRef({});  

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {        
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const avatar_id = entry.target.getAttribute("avatarId");
            if (avatar_id) {
              fetchAvatars(avatar_id);
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    memberRef.current.forEach((el) => {
      if (el) observerRef.current.observe(el);
    });

    return () => {
      observerRef.current.disconnect();
    };
  }, [users]);

  const fetchAvatars = async (avatarId) => {
    if (avatarsRef.current[avatarId]) {
      return;
    }

    try {
      const res = await avtar(avatarId);
      avatarsRef.current[avatarId] = res;
      setAvatars((prev) => ({ ...prev, [avatarId]: res }));
    } catch (error) {
      console.error(`Error fetching avatar for user ${avatarId}:`, error);
    }
  };    

  const getUserChat = (user) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setUserId(user.userId);
      timeoutRef.current = null;
    }, 2000); 
    setPageNo(1)
    setCurrentUser(user);
    setCurrentAvatar(avatars?.users?.avatarId);
    setIsOpenMessage(false);
  };

  const onUsersSeacrh = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    setCurrentUser([]);
    setUserId(null);
  }, [filters]);


  const onFilterChange = (e) => {
    const { name, checked } = e.target;
    setFilters({
      ...filters,
      [name]: checked,
    });
  };

  const toggleFilter = () => {
    setShowFilters(!showFilters);
  };

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };
  
  useEffect(() => { 
    const unreadMessagesByAvailableUsers = contextUnreadMessagesByAvailableUsers || 0;
    const unreadMessagesByAllUsers = contextUnreadMessagesByAllUsers || 0;
    if (!filters.hideUnavailable) {
      const messageCount = contextUnreadMessagesByAllUsers;        
      dispatch({
        type: "MESSAGE_COUNT",
        data: { unreadMessagesByAvailableUsers, unreadMessagesByAllUsers, messageCount },
      });
    }else{
      const messageCount = contextUnreadMessagesByAvailableUsers;        
      dispatch({
        type: "MESSAGE_COUNT",
        data: { unreadMessagesByAvailableUsers, unreadMessagesByAllUsers, messageCount },
      });
    }

  }, [filters.hideUnavailable])

  return (
    <div className="m_suggestion_chat">
    <div className="msgnr_suggestion_container">
      {!isUserAvailable && isUser ? (
        <div className="no_user">
          <span>{t("messenger.noresultfound")}</span>
          <p>{t("messenger.nouseravailable")}</p>
        </div>
      ) : (
        <div className="m_suggestion_search">
          <div className="suggestionsHeader">
            <div className="suggestionsHeaderText">
              {t("messenger.contactsHeadline")}
            </div>
            <div className="suggestionsHeaderButtons">
              <button
                className={showFilters ? "activeFilter" : null}
                aria-label={t("messenger.filtersuggestion")}
                onClick={toggleFilter}
              >
                <FontAwesomeIcon icon={faSlidersH} />
              </button>
              <button
                className={showSearch ? "activeSearch" : null}
                aria-label={t("messenger.searchsuggestion") + " Button"}
                onClick={toggleSearch}
              >
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          </div>
          {showSearch ? (
            <div className="suggestionSearch">
              <InputGroup>
                <InputGroup.Text id="search_suggestions">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                  </svg>
                </InputGroup.Text>
                <Form.Control
                  id="search_suggestions"
                  type="text"
                  aria-label={t("messenger.searchsuggestion")}
                  placeholder={t("messenger.searchsuggestion")}
                  value={searchQuery}
                  onChange={onUsersSeacrh}
                />
              </InputGroup>
            </div>
          ) : null}
          {showFilters ? (
            <div className="suggestionFilter">
              {memberDetails?.partnershipActive === true &&
              memberDetails?.friendshipActive === true ? (
                <>
                  <Form.Group id="formGridCheckbox">
                    <Form.Check
                      className="msg_pre_checkbox"
                      type="checkbox"
                      label={t("messenger.friendship")}
                      name="friendship"
                      checked={filters.friendship}
                      onChange={onFilterChange}
                    />
                  </Form.Group>
                  <Form.Group id="formGridCheckbox">
                    <Form.Check
                      className="msg_pre_checkbox"
                      type="checkbox"
                      label={t("messenger.partnership")}
                      name="partnership"
                      checked={filters.partnership}
                      onChange={onFilterChange}
                    />
                  </Form.Group>
                </>
              ) : null}
              <Form.Group id="formGridCheckbox">
                <Form.Check
                  className="msg_pre_checkbox"
                  type="checkbox"
                  label={t("messenger.contactlist")}
                  name="contactList"
                  checked={filters.contactList}
                  onChange={onFilterChange}
                />
              </Form.Group>
              <Form.Group id="formGridCheckbox">
                <Form.Check
                  className="msg_pre_checkbox"
                  type="checkbox"
                  label={t("messenger.hac")}
                  name="hideUnavailable"
                  checked={filters.hideUnavailable}
                  onChange={onFilterChange}
                />
              </Form.Group>
              <Form.Group id="formGridCheckbox">
                <Form.Check
                  className="msg_pre_checkbox"
                  type="checkbox"
                  label={t("messenger.unanswered")}
                  name="unanswered"
                  checked={filters.unanswered}
                  onChange={onFilterChange}
                />
              </Form.Group>
              <Form.Group id="formGridCheckbox">
                <Form.Check
                  className="msg_pre_checkbox"
                  type="checkbox"
                  label={t("messenger.unread")}
                  name="unread"
                  checked={filters.unread}
                  onChange={onFilterChange}
                />
              </Form.Group>
            </div>
          ) : null}
          {isFilterUser != 0 ? null : (
            <div className="no_user">
              <span>{t("messenger.noresultfound")}</span>
              <p>{t("messenger.nouserfilter")}</p>
            </div>
          )}
          <div className="m_suggestion_list">
            {users?.map((user, index) => (
              <div
                key={`${user?.userId}-${index}`}
                ref={(el) => (memberRef.current[index] = el)}
                avatarId={user.avatarId}
                className={`list_container ${
                  user.userId == (currentUser && currentUser.userId)
                    ? "active_user"
                    : ""
                }`}
                onClick={() => {
                  if (!isLoading && !isFileLoader && !isFileResponseReceived)
                    getUserChat(user);
                }}
                id={user?.userId}
                tabIndex="0"
              >
                <div className="m_suggestion_img">
                  {user?.categories == null && user?.contactListUser ? (
                    <div className="contactlist_avatar">
                      <FontAwesomeIcon icon={faPeopleArrows} />
                    </div>
                  ) : (
                    <img
                      alt={"Avatar " + user.alias}
                      src={
                        avatars?.[user?.avatarId]
                          ? avatars?.[user?.avatarId]
                          : user?.categories == null && user?.contactListUser
                          ? kontactLogo
                          : UserIcon
                      }
                    />
                  )}
                </div>
                <div className={` m_suggestion_userinfo ${user.available ? '' : "for_unavailable_user"}`}>
                  <ul className="m_suggestion_icons">
                    {user.categories?.map((category, index) => (
                      <React.Fragment key={index}>
                        {category.value === "Friendship" && (
                          <li>
                            <FontAwesomeIcon icon={faUserFriends} />
                          </li>
                        )}
                        {category.value === "Partnership" && (
                          <li>
                            <FontAwesomeIcon icon={faHeart} />
                          </li>
                        )}
                      </React.Fragment>
                    ))}
                    {user?.sex?.value == "Male" ? (
                      <li>
                        <FontAwesomeIcon icon={faMars} />
                      </li>
                    ) : null}
                    {user?.sex?.value == "Female" ? (
                      <li>
                        <FontAwesomeIcon icon={faVenus} />
                      </li>
                    ) : null}
                    {user?.sex?.value == "Intersexual" ? (
                      <li>
                        <FontAwesomeIcon icon={faTransgenderAlt} />
                      </li>
                    ) : null}
                    {user?.categories != null && user?.contactListUser ? (
                      <li>
                        <FontAwesomeIcon icon={faPeopleArrows} />
                      </li>
                    ) : null}
                    {userId === user.userId ? null
                      : user?.unread ? (
                        <li>
                          <OverlayTrigger
                            key={'top'}
                            placement={'top'}
                            overlay={
                              <Tooltip id={`tooltip-${'top'}`}>
                                New Message
                              </Tooltip>
                            }
                          >
                            <div className="new_message_indicator"></div>
                          </OverlayTrigger>
                        </li>
                      ) : null}
                  </ul>
                  <p>{user.alias}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      </div>
      <ChatHistory
        chats={
          chats?.find((user) => user.id === currentUser?.userId)?.chat || []
        }
        setUsers={setUsers}
        avatars={avatars}
        userDetails={userDetails}
        isMessageError={isMessageError}
        isFileLoader={isFileLoader}
        setIsFileLoader={setIsFileLoader}
        setIsMessageError={setIsMessageError}
        sourceType={"MESSENGER"}
        sendMessage={sendMessage}
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        isFilterUser={currentUser.length == 0}
        currentAvatar={currentAvatar}
        attachmentFiles={attachmentFiles}
        chatContainerRef={lastContactElementRef}
        chatOverviewRef={lastOverviewElementRef}
        setIsFileResponseReceived={setIsFileResponseReceived}
        isFileResponseReceived={isFileResponseReceived}
        isLoading={isLoading}
        isOpenMessage={isOpenMessage}
        setIsOpenMessage={setIsOpenMessage}
      />
    </div>
  );
}

export default UsersList;