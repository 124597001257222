// export const baseUrl = process.env.REACT_APP_BASE_URL
export const baseUrl = import.meta.env.VITE_BASE_URL;

const mailConfirmationURL = 'member/profile/confirm/email'


export async function confirmEmail(payload) {
  return fetch(`${baseUrl}/${mailConfirmationURL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(payload),
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })    
  ).catch((err) => {
    throw err;
  });
}

export async function requestForConfirmMail(token_lang) {
    if (token_lang?.accessToken) {
      return fetch(`${baseUrl}/${mailConfirmationURL}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token_lang?.accessToken}`,
          'Accept-Language': token_lang?.language
        },
        'mode': 'cors'
      })
        .then((response) =>
          response.json().then((data) => ({
            data: data,
            status: response.status,
          }))
        )
        .then(async (responseData) => {        
          return responseData;
        })
        .catch((err) => {
          throw err;
        });
    } else {
      console.log("no access token!")
    }
  }

