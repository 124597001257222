import React, { useRef, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import GlkHeader from '../../../components/Header/GlkHeader.jsx';
import Banner from '../../../components/banner/banner.jsx';
import suggestionIcon from '../../../assets/images/suggestion.png';
import './messenger.css';
import useWebSocketComponent from './webSocket.jsx';
import { LoginContext } from '../../../context/loginContext.jsx';
import UsersList from './usersList.jsx';
import Spinner from '../../../components/spinner.jsx';
import { useTranslation } from 'react-i18next';

function Messenger() {
  const { sendMessage, userDetails, chatRoom, usersChat, setIsLoading, isLoading} = useWebSocketComponent();
  const [users, setUsers] = useState([]);
  const [chats, setChats] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [recordsRemaining, setRecordsRemaining] = useState(true);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [isUserAvailable, setIsUserAvailable] = useState(false);
  const [isMessageError, setIsMessageError] =  useState(false);
  const [userListPageNo, setUserListPageNo] = useState(1);
  const [isFileLoader, setIsFileLoader] = useState(false);
  const [isMembersLoading, setIsMembersLoading] = useState(true);
  const [isFileResponseReceived, setIsFileResponseReceived] = useState(false);
  const [updateMarkRead, setUpdateMarkRead] = useState(false);
  const [avatars, setAvatars] = useState({});

  const [filters, setFilters] = useState({
    friendship: false,
    partnership: false,
    contactList: false,
    hideUnavailable: true,
    unanswered: false,
    unread: false,
  });

  const {
    loginData: {
      accessToken,
      unreadMessagesByAvailableUsers: contextUnreadMessagesByAvailableUsers,
      unreadMessagesByAllUsers: contextUnreadMessagesByAllUsers
    },
    dispatch
  } = useContext(LoginContext);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const getUsers = {
      type: "AUTH",      
      token: accessToken,
    };
    sendMessage(JSON.stringify(getUsers));
  }, [accessToken]);

  useEffect(() => {
    if (chatRoom?.subType === 'CHAT_ROOMS') {
      setIsMembersLoading(chatRoom?.recordsRemaining)
      if(chatRoom?.recordsRemaining){
        setUserListPageNo(prev => prev + 1);
      }      
      setUsers((prevUsers) => {
        let newUsers = chatRoom?.chatRooms;
        return [...new Set([...prevUsers, ...newUsers])]
      });

      if(chatRoom?.chatRooms){
        setIsUserAvailable(false)
      }     
    }
  }, [chatRoom?.chatRooms])

  useEffect(() => {
    if (userDetails?.error) {
      setIsMessageError(true)
      setIsFileLoader(false);
    }
  }, [userDetails])
  
  useEffect(() => {    
    if (usersChat?.fileType === 'IMAGE' || usersChat?.fileType === 'FILE') {      
      setAttachmentFiles((prevAttachments) => [usersChat, ...prevAttachments]);
      setIsFileResponseReceived(false);
    }

    if (usersChat?.subType === 'CHAT_HISTORY' || usersChat?.type === "TEXT_MESSAGE" || usersChat?.type === "FILE_MESSAGE") {
      if (usersChat?.subType === "CHAT_HISTORY") {
        setRecordsRemaining(usersChat?.recordsRemaining);
      }

      setIsFileLoader(false);

      const newMessages =
      usersChat?.type === "TEXT_MESSAGE" || usersChat?.type === "FILE_MESSAGE"
          ? [usersChat]
          : usersChat?.messages;

      setChats((prevChats) => {
        const isChatHistory = usersChat?.subType === "CHAT_HISTORY";
        const currentUserId = currentUser?.userId;
        const newMessage = newMessages[0];
        const isCurrentUserInvolved =
        newMessage && (currentUserId === newMessage.receiverId || currentUserId === newMessage.senderId);

        const uniqueChats = (messages) => [...new Map(messages.map((msg) => [msg.id, msg])).values()]; 

        const targetUserId = isCurrentUserInvolved
        ? currentUserId
        : newMessage?.senderId || currentUserId;
        const currentUserChatIndex = prevChats.findIndex((chat) => chat.id === targetUserId);
                
        let updatedChat = {
          id: targetUserId,
          pageCount: pageNo,
          chat: [],
        };
      
        if (currentUserChatIndex !== -1) {
          updatedChat.chat = prevChats[currentUserChatIndex].chat;
        }
      
        if (isChatHistory) {
          updatedChat.chat = uniqueChats([...updatedChat.chat, ...newMessages]);
        } else if (isCurrentUserInvolved) {    
          setUpdateMarkRead(true);      
          updatedChat.chat = uniqueChats([...newMessages, ...updatedChat.chat]);
        } else {
          updateUnseenMessagesCount()
          if (currentUserChatIndex !== -1) {
            setUsers((prevUsers) =>
              prevUsers.map((user) =>
                user.userId == targetUserId ? { ...user, unread: true } : user
              )
            );
            const updatedChats = [...prevChats];
            updatedChats[currentUserChatIndex].chat = uniqueChats([
              usersChat,
              ...prevChats[currentUserChatIndex].chat,
            ]);
            return updatedChats;
          } else {
            setUsers((prevUsers) =>
              prevUsers.map((user) =>
                user.userId == targetUserId ? { ...user, unread: true } : user
              )
            );
            const newChatEntry = {
              id: newMessage?.senderId,
              pageCount: 1,
              chat: uniqueChats([usersChat]),
            };
            return [newChatEntry, ...prevChats];
          }
        }
      
        if (currentUserChatIndex !== -1) {
          const updatedChats = [...prevChats];
          updatedChats[currentUserChatIndex] = updatedChat;
          return updatedChats;
        } else {
          return [...prevChats, updatedChat];
        }
      });    
    }

    if (usersChat?.subType === "DELETE") {
      setChats((prevChats) => {
        const currentUserId = currentUser?.userId;
        const messageIdToDelete = usersChat.messageId;
        return prevChats.map((chat) => {
          if (chat.id == currentUserId) {
            const updatedChatMessages = chat.chat.filter(
              (message) => message.id != messageIdToDelete
            );
            return {
              ...chat,
              chat: updatedChatMessages,
            };
          }
          return chat;
        });
      });
    }
  
  }, [usersChat]);  

  const getUserList = (page) => {
    if (!users.length) {
      setIsUserAvailable(true);
    }
    const getUsers = {
      type: "API",
      subType: "CHAT_ROOMS",
      pageNo: page,
      language: i18n.language
    };
    sendMessage(JSON.stringify(getUsers));
  }

useEffect(() => {  
  if (isMembersLoading || isFileLoader && !currentUser?.userId) return;

  if (currentUser?.userId != undefined) {
    const markMesgRead = {
      type: "API",
      subType: "MARK_READ",
      targetUserId: currentUser?.userId,
    };

    const timeoutId = setTimeout(() => {
      setIsLoading(true);
      setUpdateMarkRead(false);  
      sendMessage(JSON.stringify(markMesgRead));
    }, 2000);

    return () => clearTimeout(timeoutId);
  }

}, [currentUser?.userId, isMembersLoading, updateMarkRead]);

useEffect(() => {
  if (userDetails?.subType === "MARK_READ") {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.userId === userDetails.targetUserId
          ? { ...user, unread: false}
          : user
      )
    );
  }
}, [currentUser?.userId]);

  const decreaseCountForSeenMessages = (targetUserId) => {
    const newChats = chats?.filter((chat) => chat.id === targetUserId);
    const seenCount = newChats?.[0]?.chat?.filter((item) => item.seen == false).length;
    let unreadMessagesByAvailableUsers = Math.max(0, contextUnreadMessagesByAvailableUsers - seenCount);
    let unreadMessagesByAllUsers = Math.max(0, contextUnreadMessagesByAllUsers - seenCount);
    if (!filters.hideUnavailable) {
      const messageCount = unreadMessagesByAllUsers;
      dispatch({
        type: "MESSAGE_COUNT",
        data: { unreadMessagesByAvailableUsers, unreadMessagesByAllUsers, messageCount },
      });
    }
    else {
      const messageCount = unreadMessagesByAvailableUsers;
      dispatch({
        type: "MESSAGE_COUNT",
        data: { unreadMessagesByAvailableUsers, unreadMessagesByAllUsers, messageCount },
      });
    }
  }

  useEffect(() => {
    const newMessages =
      usersChat?.type === "TEXT_MESSAGE" || usersChat?.type === "FILE_MESSAGE"
          ? [usersChat]
          : usersChat?.messages;

        const currentUserId = currentUser?.userId;
        const newMessage = newMessages?.[0];
        const isCurrentUserInvolved =
        newMessage && (currentUserId === newMessage.receiverId || currentUserId === newMessage.senderId);
        console.log(userDetails?.subType === "MARK_READ" && !isCurrentUserInvolved);
        
    if (userDetails?.subType === "MARK_READ" && !isCurrentUserInvolved || userDetails?.subType === "MARK_READ" && !(usersChat?.type === "TEXT_MESSAGE" || usersChat?.type === "FILE_MESSAGE")) {
      decreaseCountForSeenMessages(userDetails?.targetUserId);
    }
  }, [userDetails]);

  const updateUnseenMessagesCount = () => {
    let unreadMessagesByAvailableUsers = contextUnreadMessagesByAvailableUsers + 1;
    let unreadMessagesByAllUsers = contextUnreadMessagesByAllUsers + 1;
    const messageCount = unreadMessagesByAvailableUsers;
    dispatch({
      type: "MESSAGE_COUNT",
      data: { unreadMessagesByAvailableUsers, unreadMessagesByAllUsers, messageCount },
    });
  }

  useEffect(() => {
    getUserList(userListPageNo);
  }, [userListPageNo]);

  const data = {
    image: suggestionIcon,
    menuName: t('messenger.suggestions'),
    header:  t('messenger.message'),
    description:  t('messenger.messagedescription'),
    color: "rgb(89, 117, 28)",
  };

  const fetchChatHistory = (page) => {    
    const message = {
      type: "API",
      subType: "CHAT_HISTORY",
      pageNo: page,
      targetUserId: currentUser?.userId,
      pageSize: 20,
    };
    setIsLoading(true);
    if (currentUser?.userId) {
      sendMessage(JSON.stringify(message));
    }
  };

  useEffect(() => {    
    if (currentUser?.userId != undefined) {
      fetchChatHistory(pageNo);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser?.userId) {
      const currentChat = chats.find(
        (chat) => chat.id === currentUser?.userId
      )?.pageCount;
      
      if (pageNo <= currentChat) {
        setPageNo(currentChat);
      } else {
        setPageNo(pageNo);
        fetchChatHistory(pageNo);
      }
    }
  }, [pageNo]);

  const chatContainerRef = useRef(null);
  const lastContactElementRef = useCallback(
    (node) => {
      if (chatContainerRef.current) chatContainerRef.current.disconnect();
      chatContainerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && recordsRemaining) {
            setPageNo((prevMessagePage) => prevMessagePage + 1);
        }
      });
      if (node) chatContainerRef.current.observe(node);
    },
    [recordsRemaining]
  );

  const chatOverviewRef = useRef(null);
  const lastOverviewElementRef = useCallback(
    (node) => {
      if (chatOverviewRef.current) chatOverviewRef.current.disconnect();
      chatOverviewRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && recordsRemaining) {
            setPageNo((prevMessagePage) => prevMessagePage + 1);
        }
      });
      if (node) chatOverviewRef.current.observe(node);
    },
    [recordsRemaining]
  );

  const filteredUsers = useMemo(() => {
    const filtered = users.filter(user => {
      const group1 = filters.friendship || filters.partnership || filters.contactList;
      const group1Match =
        (filters.friendship && user.friendship) ||
        (filters.partnership && user.partnership) ||
        (filters.contactList && user.contactListUser);
      const group2 = filters.hideUnavailable || filters.unanswered || filters.unread;
      const group2Match =
        (!filters.hideUnavailable || user.available) &&
        (!filters.unanswered || user.unanswered) &&
        (!filters.unread || user.unread);
      const searched = user.alias.toLowerCase().includes(searchQuery.toLowerCase());
      return (!group1 || group1Match) && (!group2 || group2Match) && searched;
    });
    
    return Array.from(new Set(filtered.map(user => user.relationshipId)))
      .map(id => filtered.find(user => user.relationshipId === id));
  }, [users, filters, searchQuery]);

  return (
    <div>
      <GlkHeader />
      <main className="main_wrapper messenger_wrapper">
        <Banner data={data} />        
          <div className='messenger_container questionaire_in_menubar'>
            {!isUserAvailable  ?
                  <UsersList
                      chats={chats}
                      users={filteredUsers}
                      isLoading={isLoading}
                      userDetails={userDetails}
                      setAvatars={setAvatars}
                      avatars={avatars}
                      isFileLoader={isFileLoader}
                      setIsFileLoader={setIsFileLoader}
                      currentUser={currentUser}
                      isMessageError={isMessageError}
                      sendMessage={sendMessage}
                      setPageNo={setPageNo}
                      isUser={users?.length == 0} 
                      isUserAvailable={isUserAvailable}
                      isFilterUser={filteredUsers.length}
                      setFilters={setFilters}
                      filters={filters}
                      setCurrentUser={setCurrentUser}
                      isFileResponseReceived={isFileResponseReceived} 
                      searchQuery={searchQuery}
                      setSearchQuery={setSearchQuery}
                      setIsMessageError={setIsMessageError}
                      setUsers={setUsers}
                      lastContactElementRef={lastContactElementRef}
                      lastOverviewElementRef={lastOverviewElementRef}
                      attachmentFiles={attachmentFiles}
                      setIsFileResponseReceived={setIsFileResponseReceived}
                    />
               : <Spinner />}
          </div>
      </main>
    </div>
  );
}

export default Messenger;
