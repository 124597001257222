// export const baseUrl = process.env.REACT_APP_BASE_URL;
export const baseUrl = import.meta.env.VITE_BASE_URL;

const questionairesURL = 'register/questionnaire';
const answerURL = 'register/answers';
const currentURL = 'register/current';
const nextURL = 'register/next';
const countryURL = 'register/location/country';
const regionURL = 'register/location/region';
const zipCodeURL = 'register/location/zip';
const continentURL = 'register/continent';
const addressURL ='register/addresses';
const subscriptionOfferURL = 'products';
const saveSubscriptionOfferURL = 'billing';
const paymentURL = 'billing/payment'; 
const confignotificationURL = 'register/confignotification';
const pendingPaymentURL = 'payment/pending';
const changePaymentMethodURL = 'payment/change/method';
const autofillPaymentAddressURL = 'membership/payment/autofill';
const autoRegisterURL = 'register/autoregister';
const paymentMethodChangeURL = 'billing/method';
const deleteOfferURL = 'actionCode';


export async function getQuestionaires(token_lang, id) {
  if (token_lang.accessToken && id) {
    return fetch(`${baseUrl}/${questionairesURL}${`?id=${id}`}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function questionaireAnswer(data, accessToken, language) {
  let body;
  if (data?.questionnaireId === 12 || data?.questionnaireId === 13) {
    body = {
      "questionnaireId": data.questionnaireId,
      "regionAnswerRequest" : data.answers,
    }
  } else {
    body = {    
      "questionnaireId": data.questionnaireId,
        "request": data.answers
      }
  }
  return fetch(`${baseUrl}/${answerURL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'Accept-Language': language,
    },
    body: JSON.stringify(body),
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })
  ).catch((err) => {
    throw err;
  });
}

export async function getCurrentURL(accessToken) {
  if (accessToken) {
    return fetch(`${baseUrl}/${currentURL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function gotoNextQuestionaire(accessToken) {
  if (accessToken) {
    return fetch(`${baseUrl}/${nextURL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getCountry(token_lang, ContinentId) {
  if (token_lang.accessToken) {
    return fetch(`${baseUrl}/${countryURL}/${ContinentId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}
export async function getRegion(accessToken, countryId) {
  if (accessToken) {
    return fetch(`${baseUrl}/${regionURL}/${countryId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}
export async function getZipCode(accessToken, countryId, page, search) {
  if (accessToken) {
    return fetch(`${baseUrl}/${zipCodeURL}/${countryId}${`?search=${search}`}&${`page=${page}`}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}
export async function getContinentForMemberAddress(token_lang) {
  if (token_lang?.accessToken) {
    return fetch(`${baseUrl}/${continentURL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang?.accessToken}`,
        'Accept-Language': token_lang?.language,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}
export async function saveMemberAddress(data, accessToken) {  
  return fetch(`${baseUrl}/${addressURL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })
  ).catch((err) => {
    throw err;
  });
}
export async function getSubscriptionOffers(token_lang, productType, actionCode) {
  let getProductsUrl = `${baseUrl}/${subscriptionOfferURL}?productType=${productType}`;
  if (actionCode) {
    getProductsUrl = getProductsUrl + `&actionCode=${actionCode}`;
  }
  if (token_lang.accessToken) {
    return fetch(getProductsUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function saveSubscriptoionOffer(data, accessToken) {  
  return fetch(`${baseUrl}/${saveSubscriptionOfferURL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })
  ).catch((err) => {
    throw err;
  });
}

export async function makePayment(data, accessToken) {  
  return fetch(`${baseUrl}/${paymentURL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })
  ).catch((err) => {
    throw err;
  });
}

export async function savePreconfiguration(data, accessToken) {  
  return fetch(`${baseUrl}/${confignotificationURL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })
  ).catch((err) => {
    throw err;
  });
}

export async function getPaymentDetails(token_lang) {
  if (token_lang.accessToken) {
    return fetch(`${baseUrl}/${pendingPaymentURL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function changePaymentMethod(token_lang) {
  if (token_lang.accessToken) {
    return fetch(`${baseUrl}/${changePaymentMethodURL}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getAdressForPayment(accessToken) {
  if (accessToken) {
    return fetch(`${baseUrl}/${autofillPaymentAddressURL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function completeRegistration(accessToken) {
  if (accessToken) {
    return fetch(`${baseUrl}/${autoRegisterURL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function paymentMethodChange(token_lang, payload) {
  if (token_lang.accessToken) {
    return fetch(`${baseUrl}/${paymentMethodChangeURL}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      body: JSON.stringify(payload),
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function deleteOffer(accessToken) {
  if (accessToken) {
    return fetch(`${baseUrl}/${deleteOfferURL}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

