import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import GlkHeader from '../../components/Header/GlkHeader.jsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import NoComponent from '../registrationProcess/noComponent.jsx';
import Spinner from '../../components/spinner.jsx';
import Payment from '../registrationProcess/payment.jsx';
import SubscriptionOffer from '../registrationProcess/subscriptionOffer.jsx';
import PaymentResult from '../registrationProcess/paymentResult.jsx';

function NoActiveSubscription(props) {

    const [pageId, setPageId] = useState(null)
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [spinner, setSpinner] = useState(false);
    const { t} = useTranslation();


    let navigate = useNavigate();
    let componentToRender;

    const handleNextQustionaire = () => {
        if (paymentMethod === 'DIRECT_DEBIT') {
            navigate('/home')
        }
        else {
            navigate('/payment');
        }
    }

    switch (pageId) {
        case 'SUBSCRIPTIONOFFER':
            componentToRender = <SubscriptionOffer
                setPaymentMethod={setPaymentMethod}
                setQuestionaireId={setPageId}
                isUserRegistered={true}
            />;
            break;
        case 'PAYMENT':
            componentToRender = <Payment
                isUserRegistered={true}
                setQuestionaireId={setPageId}
            />;
            break;
        case 'PAYMENTRESULT':
            componentToRender = <PaymentResult
                paymentMethods={paymentMethod}
                handleNextQustionaire={handleNextQustionaire}
                isUserRegistered={true}
            />;
            break;
        default:
            componentToRender = <NoComponent />
    }

    useEffect(() => {
    }, [])

    return (
        <div>
            <GlkHeader showMenuBar={false} />
            <main className='main_wrapper preregistration_wrapper questionnaire_wrapper'>
                <div className="banner_wrapper">
                    <div className="banner_container">
                        <div className='banner_content'>
                            <div className="banner_description">
                                <h1 className='registration_label'>{t('noActiveSub.header')}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="questionaire_in_menubar">
                    {pageId === null ? 
                    <div className="container-fluid registration_steps">
                        <div className="row">
                            <div className="banner_description">
                                <p><strong>{t('noActiveSub.desc')}</strong></p>
                            </div>
                        </div>
                        <div className="mt-3 step_one_next">
                            <Button
                                className='mx-5'
                                variant="primary"
                                onClick={() => setPageId('SUBSCRIPTIONOFFER')}
                            >
                                {t('registration.buttons.next')}
                            </Button>
                        </div>
                    </div>
                        :
                    <div>
                        {spinner ? <Spinner /> : componentToRender}
                    </div>}
                </div>
            </main>
        </div>
    )
}
export default NoActiveSubscription;

