import React, { useState, useEffect, useContext, useRef } from 'react'
import './messenger.css';
import { LoginContext } from '../../../context/loginContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import UserIcon from '../../../assets/images/profile_pic_1.png';
import TextEditor from './textEditor';
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import { 
  faDownload, 
  faXmark, 
  faChevronLeft, 
  faChevronRight, 
  faTimes, 
  faFile, 
  faFileVideo, 
  faFileAudio,  
  faStop, 
  faPause, 
  faEllipsisVertical, 
  faImage, 
  faPen, 
  faPaperclip, 
  faMicrophone,
  faMars,
  faVenus, 
  faTransgenderAlt, 
  faUserFriends, 
  faHeart, 
  faPeopleArrows, 
  faMapMarkerAlt, 
  faSearch,  
  faPaperPlane, 
  faReply,
} from '@fortawesome/free-solid-svg-icons';
import { KeyBindingUtil } from 'draft-js';
import Form from 'react-bootstrap/Form';
import { Spinner, Modal, Button, Alert } from 'react-bootstrap';
import '../../personalDetails/audio.css'
import Picker from '@emoji-mart/react'
import DOMPurify from 'dompurify';
import heic2any from 'heic2any';
import NoChats from '../../../assets/images/NoChats.png';
import { communityContactsFreeText, getUserDetails} from '../suggestionService';
import SuggestionView from '../suggestionView';
import FreeText from './freeText';
let currentIndex = -1;


function ChatHistory(props) {  
  const [userId, setUserId] = useState(null);
  const [message, setMessage] = useState();
  const [file, setFile] = useState([]);
  const [resetFile, setResetFile] = useState(false);
  const [base64String, setBase64String] = useState([]);
  const [subType, setSubType] = useState('TEXT_MESSAGE')
  const [currentAttachmentId, setCurrentAttachmentId] = useState();
  const [searchString, setSearchString] = useState("");
  const [matchingStringWithMessagesAndId, setMatchingStringWithMessagesAndId] = useState([]);
  const [userSearching, setUserSearching] = useState([]);
  const [discardRecording, setDiscardRecording] = useState(true);
  const [isEmoji, setIsEmoji] = useState(false);
  const [mimeType, setMimeType] = useState([]);
  const [fileUrls, setFileUrls] = useState('');
  const [recorder] = useState(
    // new MicRecorder({ bitRate: 128 })
  );
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState('');
  const [isReadyToSend, setIsReadyToSend] = useState(false);
  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [fileSizeMsg, setFileSizeMsg] = useState('')
  const [audioFile, setAudioFile] = useState();
  const [showEditor, setShowEditor] = useState(false);
  const [showMessageSearch,setShowMessageSearch] = useState(false);
  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const [userDetailsViewSugesstion, setUserDetailsViewSugesstion] = useState();
  const [errors, setErrors] = useState({ errorMsg: '', isError: false, statusCode: '', statusError:'' });
  const [avatars, setAvatars] = useState({});
  const [freeText, setFreeText] = useState({open: false, value: []});
  
  const searchRef = useRef(null);
  const overviewRef = useRef(null);
  const msgRef = useRef([]);
  const fileRef = useRef([]);
  const editorRef = useRef(null);

  const {
    chats,
    setUsers,
    sourceType,
    userDetails,
    isMessageError,
    isFileLoader,
    setIsFileLoader,
    setIsMessageError,
    currentUserDetail,
    currentUser,
    setCurrentUser,
    sendMessage,
    isFilterUser,
    attachmentFiles,
    chatContainerRef,
    chatOverviewRef,
    setIsFileResponseReceived,
    isLoading, 
    isOpenMessage, 
    setIsOpenMessage,
  } = props;
  let {
    loginData: {
      accessToken
    },
  } = useContext(LoginContext);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;  

  const inputFileSelect = useRef(null);
  const setSearchParams = (e) => {
    setSearchString(e.target.value);
    displayFocusedResult(e.target.value)
  };

  let searchResults = [];
  const displayFocusedResult = (searchValue) => {
    setMatchingStringWithMessagesAndId([]);
    if (!searchValue) {
      clearAllHighlights();

    }
    if (searchValue.length >= 1) {
      searchValue = searchValue.toLowerCase();
      searchResults = chats?.filter(text => {
        const sanitizedHTML = DOMPurify.sanitize(text.message, { ALLOWED_TAGS: [] });
        let plainTextMessage = sanitizedHTML.replace(/&#x[0-9A-Fa-f]+;/g, '');
        const textarea = document.createElement('textarea');
        textarea.innerHTML = plainTextMessage;
        plainTextMessage = textarea.value;
        const sanitizedQuery = DOMPurify.sanitize(searchValue);
        return sanitizedHTML.toLowerCase().includes(sanitizedQuery);
      });
      let searchResults1 = searchResults.reverse();
      setUserSearching(searchResults);
    }
  }

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/&/g, '&amp;');
  };

  const getMatchingString = () => {
    userSearching.map((item) => {
      const sanitizedHTML = DOMPurify.sanitize(item.message, { ALLOWED_TAGS: [] });
      const matches = [];
      let match;
      const escapedKeyword = escapeRegExp(searchString);
      const regex = new RegExp(escapedKeyword, 'gi');
      const textarea = document.createElement('textarea');
      textarea.innerHTML = sanitizedHTML;
      let plainTextMessage = textarea.value;

      while ((match = regex.exec(plainTextMessage)) !== null) {
        matches.push({ start: match.index, end: match.index + searchString.length });
      }
      if (matches) {
        matches.forEach(element => {
          matchingStringWithMessagesAndId.push([item.sendDate, plainTextMessage, element]);
        });
      }
      setMatchingStringWithMessagesAndId(matchingStringWithMessagesAndId);
    })
  }

  const previousResult = () => {
    if (currentIndex < matchingStringWithMessagesAndId.length) {
      currentIndex++;
      if (currentIndex === matchingStringWithMessagesAndId.length) {
        currentIndex = matchingStringWithMessagesAndId.length - 1;
      }
      highlightText(currentIndex);
      if (matchingStringWithMessagesAndId.length == currentIndex + 1) {
        currentIndex = -1
      }
    }
  }

  const nextResult = () => {
    if (currentIndex <= 0) {
      currentIndex = matchingStringWithMessagesAndId.length;
    }
    if (currentIndex > 0) {
      currentIndex--;
      highlightText(currentIndex);
    }
  }

  const highlightText = (index) => {
    if (userSearching.length === 0) {
      document.getElementById("messageCount").innerHTML = '0/0';
    }
    setTimeout(() => {
      if (userSearching.length && index !== -1) {
        document.getElementById("messageCount").innerHTML = `${index + 1}/${matchingStringWithMessagesAndId.length}`;
      }
    }, 100);
    if (userSearching.length) {
      clearPreviousHighlights(index)
    }
    if (index !== -1) {
      const message = document.getElementById(matchingStringWithMessagesAndId[index][0]);
      const updatedExp = matchingStringWithMessagesAndId[index][1].substring(0, matchingStringWithMessagesAndId[index][2].start) +
        `<mark class="highlight">${matchingStringWithMessagesAndId[index][1].substring(matchingStringWithMessagesAndId[index][2].start, matchingStringWithMessagesAndId[index][2].end)}</mark>` +
        matchingStringWithMessagesAndId[index][1].substring(matchingStringWithMessagesAndId[index][2].end);
      let newText = "<p>" + updatedExp + "</p>"
      message.innerHTML = newText;
      scrollToElement();
    }
  }

  /*when we go to next text for highlight we have to clear last one */
  const clearPreviousHighlights = () => {
    chats.map((text) => {
      const newText = document.getElementById(text.sendDate);
      if (newText) {
        newText.innerHTML = text.message;
      }
    })

  }
  /*clear highlight text when we click clear(x) btn in search box */
  const clearAllHighlights = () => {
    currentIndex = -1;
    chats.map((text) => {
      const newText = document.getElementById(text.sendDate);
      if (newText) {
        newText.innerHTML = text.message;
      }
    })
  }

  /** set highlight text in view */
  function scrollToElement() {
    const elementToScroll = document.querySelector("mark");
    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: "smooth", block: 'nearest' });
    }
  }
  function scrollToSelectedElement(index) {
    resetStates();
    setShowEditor(false);
    setIsOpenMessage(false);
    abortRecording();
    const selectedElementToScroll = msgRef.current[index];
    if (selectedElementToScroll) {
      scrollParentToChild(selectedElementToScroll)
    }
  }

  function scrollParentToChild(child) {
    
    // Where is the parent on page
    let parentRect = searchRef.current.getBoundingClientRect();
  
    // Where is the child
    let childRect = child.getBoundingClientRect();
    const scrollTop = childRect.top - parentRect.top;
    searchRef.current.scrollTop += scrollTop-10;
  
  }

  useEffect(() => {
    clearAllHighlights();
    setMatchingStringWithMessagesAndId([]);
    if (searchString) {
      setTimeout(() => {
        previousResult();
      }, 1000);
      const searchInDelay = setTimeout(() => {
        getMatchingString();
      }, 700)
      return () => { clearTimeout(searchInDelay) }
    }
  }, [searchString])

  const pressDownKey = (event) => {
    if (searchString.length >= 1 && event.which == 40) {
      previousResult();
    }
  }

  const pressUpKey = (event) => {
    if (searchString.length >= 1 && event.which == 38) {
      nextResult();
    }
  }

  const {hasCommandModifier} = KeyBindingUtil;
    const keyBindingFn = (event) => {
        if (event.keyCode === 13 && hasCommandModifier(event)) {
                onSendMessage(message)
                const newState = EditorState.createEmpty()
                setMessage( EditorState.moveFocusToEnd(newState))
            return 'handled'
        }
        return 'not-handled'
    };

    const onSendMessage = (message) => {
      let newValue = '';
      if (message !== undefined) {
        newValue = draftToHtml(convertToRaw(message?.getCurrentContent())).trim();
        setShowEditor(false);
      }
    
      const isEmptyMessage = newValue === '' || newValue === '<p></p>' || newValue === '<p><br></p>';
      const updatedValue = isEmptyMessage ? null : newValue;
    
      let data;
      if (subType === "TEXT_MESSAGE") {
        data = {
          type: "API",
          subType: "TEXT_MESSAGE",
          source: sourceType,
          contactListType: props.contactListType ? props.contactListType : "",
          language: i18n.language,
          [sourceType != 'SUGGESTIONS' ? 'targetUserId' : 'targetUserAlias']: sourceType != 'SUGGESTIONS'  
          ? currentUser?.userId 
            : currentUserDetail,         
             message: updatedValue,
        };
        sendMessage(JSON.stringify(data));
        resetStates();
      } else {
        file?.forEach((fileItem, index) => {
          const attachment = {
            attachmentName: fileItem?.name,
            fileType: fileItem?.type?.includes('image') ? "IMAGE" : "FILE",
            file: base64String[index],
            mimetype: mimeType[index],
          };
    
          data = {
            type: "API",
            subType: "FILE_MESSAGE",
            source: sourceType,
            contactListType: props.contactListType ? props.contactListType : "",
            language: i18n.language,
            targetUserId: currentUser?.userId,
            message: updatedValue,
            attachments: [attachment],
          };
          setIsFileLoader(true);
          sendMessage(JSON.stringify(data));
        });
        resetStates();
      }
    };
    
    const resetStates = () => {
      setMessage();
      setFile([]); 
      setBase64String([]);
      setSubType('TEXT_MESSAGE');
      setIsEmoji(false);
      setMimeType([]);
      setIsReadyToSend(false);
    };
    

  const deleteMessage = (messageId) => {
    const data = {
      type: "API",
      subType: "DELETE",
      messageId: messageId,
    };
    sendMessage(JSON.stringify(data));
  }

  useEffect(() => {
    let plainText = message && message.getCurrentContent().getPlainText();
    if (file?.length != 0 || plainText && plainText?.trim() !== "") {
      setIsReadyToSend(true);
    } else {
      setIsReadyToSend(false);
    }
  }, [file, message])

  useEffect(() => {
    let plainText = message && message.getCurrentContent().getPlainText();
    if (plainText == undefined) {
      setIsReadyToSend(false);
    }
  }, [])
  
  useEffect(() => {
    setIsUserBlocked(currentUser?.blocked)
  },[currentUser])

  const blockOrUnblockUser = (isChecked, targetUserId) => {
    setIsUserBlocked(isChecked);
    const userStatus = {
      type: "API",
      subType: "CHAT_BLOCK",
      targetUserId: targetUserId,
      chatBlock: isChecked
    }
    sendMessage(JSON.stringify(userStatus));    
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.userId === targetUserId ? { ...user, blocked: isChecked} : user
        )
      );
  }  

  //block status of user in real time 
  useEffect(() => {
    if (userDetails?.subType === 'CHAT_BLOCK') {
      const currentUserId = currentUser?.userId;
      const blockStatus = userDetails?.blockStatus == 'true' ? true : false;
      const isCurrentUserInvolved = userDetails && (currentUserId == userDetails.blockedBy || currentUserId === userDetails.blocked);

      if (isCurrentUserInvolved) {
        setCurrentUser((prevData) => {
          const isBlockedBySourceUser = currentUserId.userId == userDetails?.blockedBy && blockStatus;
          return {
            ...prevData,
            blocked: blockStatus,
            blockedBySourceUser: isBlockedBySourceUser,
            showBlockSwitch: !blockStatus
          }
        })
      } else {
        setUsers((prevUsers) =>
          prevUsers.map((user) => {
            const isBlockedBySourceUser = user.userId == userDetails?.blockedBy && blockStatus;
            const isShowBlockSwitch = !blockStatus;
            return user.userId == userDetails?.blockedBy
              ? {
                ...user,
                blocked: blockStatus,
                blockedBySourceUser: isBlockedBySourceUser,
                showBlockSwitch: isShowBlockSwitch
              }
              : user;
          })
        );
      }

    }
  }, [userDetails]);
  
  useEffect(() => {
    let base64Url = accessToken?.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    setUserId(JSON.parse(jsonPayload).user_id)
  }, [])

  useEffect(() => {
    setCurrentAttachmentId()
  }, [attachmentFiles])

  const addFile = async (e) => {
    setIsReadyToSend(true);
    setResetFile(true);
    e.preventDefault();
    if (e.target?.files.length > 0) {
      const filesArray = Array.from(e.target.files);
      const maxFileSizeMB = 20;
      const invalidNameRegex = /[\/\\\.]/g;

      const newFiles = [];
      const newBase64Strings = [];
      const newMimeTypes = [];

      for (const selectedFile of filesArray) {
        const fileSizeInMB = selectedFile.size / (1024 * 1024);
        const fileNameWithoutExtension = selectedFile.name.replace(/\.[^/.]+$/, "");
        const fileExtension = selectedFile.name.split('.').pop();

        if (!fileNameWithoutExtension || invalidNameRegex.test(fileNameWithoutExtension)) {
          const errorMessage = t('messenger.fileerror', { fileName: selectedFile.name });
          setFileSizeMsg(errorMessage);
          setIsReadyToSend(false);
          return;
        }

        if (fileSizeInMB <= maxFileSizeMB) {
          let base64String;
          if (['HEIC', 'heic', 'heif', 'HEIF'].includes(fileExtension)) {
            try {
              base64String = await handleHeicToBase64(selectedFile);
              newMimeTypes.push("image/heic");
            } catch (error) {
              console.error("Error handling HEIC file", error);
              setFileSizeMsg(t('messenger.fileerror', { fileName: selectedFile.name }));
              setIsReadyToSend(false);
              return;
            }
          } else {
            const reader = new FileReader();
            base64String = await new Promise((resolve) => {
              reader.onloadend = () => {
                resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
              };
              reader.readAsDataURL(selectedFile);
            });
            newMimeTypes.push(selectedFile.type);
          }

          newFiles.push(selectedFile);
          newBase64Strings.push(base64String);

          if (newFiles.length === filesArray.length) {
            setFile(newFiles);
            setBase64String(newBase64Strings);
            setMimeType(newMimeTypes);
            setSubType('FILE_MESSAGE');
          }
        } else {
          const errorMessage = t('messenger.filesize', { fileName: selectedFile.name });
          setFileSizeMsg(errorMessage);
          setIsReadyToSend(false);
        }
      }
    }
  };

  const handleHeicToBase64 = async (heicFile) => {
    try {
      const convertedBlob = await heic2any({ blob: heicFile, toType: "image/jpeg" });
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
          resolve(base64String);
        };
        reader.readAsDataURL(convertedBlob);
      });
    } catch (error) {
      console.error("Error converting HEIC to base64:", error);
      throw error;
    }
  };  

  const base64Image = (image) => {
    return `data:image/jpeg;base64,${image}`
  }

  const removePreview = (index) => {
    const updatedFiles = file.filter((_, i) => i !== index);
    const updatedBase64Strings = base64String.filter((_, i) => i !== index);
    const updatedMimeTypes = mimeType.filter((_, i) => i !== index);

    setFile(updatedFiles);
    setBase64String(updatedBase64Strings);
    setMimeType(updatedMimeTypes);

    if (updatedFiles.length == 0) {
      setFile([]);
      setBase64String([]);
      setSubType('TEXT_MESSAGE');
      setMimeType([]);
      setIsReadyToSend(false);
    }
    setResetFile(true);
  };

  useEffect(() => {
    setResetFile(false)    
  }, [resetFile])

  const downloadFile = (attachmentId) => {    
    setIsFileResponseReceived(true); 
    setCurrentAttachmentId(attachmentId);
    const fileData = {
      type: "API",
      subType: "FILE",
      attachmentId: attachmentId,
    };
    sendMessage(JSON.stringify(fileData));
  };

  const fileConverter = (base64Data, attachmentId, fileName) => {
    if (base64Data) {
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      let blob;
      let mimeType;
      switch (getFileType(fileName)) {
        case 'pdf':
          mimeType = 'application/pdf';
          break;
        case 'txt':
          mimeType = 'text/plain';
          break;
        case 'rtf':
          mimeType = 'application/rtf';
          break;
        case 'mp3':
          mimeType = 'audio/mp3';
          break;
        case 'wav':
          mimeType = 'audio/wav';
          break;
        case 'm4a':
          mimeType = 'audio/m4a';
          break;
        case 'mp4':
          mimeType = 'audio/mp4';
          break;
        case 'wmv':
          mimeType = 'video/x-ms-wmv';
          break;
        case 'avi':
          mimeType = 'video/x-msvideo';
          break;
        case 'mov':
          mimeType = 'video/quicktime';
          break;
        default:
          mimeType = 'application/octet-stream';
      }
      blob = new Blob([byteArray], { type: mimeType });

      const pdfUrl = URL.createObjectURL(blob);
      setFileUrls(prevUrls => ({ ...prevUrls, [attachmentId]: pdfUrl }));
      return pdfUrl;
    }
  };

  function getFileType(filename) {
    const [name, extension] = filename.split(/\.([^.]+)$/);    
    return extension;    
  }

  const startRecording = () => {
    setFile(null);
    setDiscardRecording(true);
    setIsOpenMessage(true);
    recorder.start().then(() => {
      setIsRecording(true);
    }).catch((e) => console.error(e));
  };

  const stopRecording = () => {
    recorder.stop().getMp3().then(([buffer, blob]) => {
      const blobURL = URL.createObjectURL(blob);
      setAudioURL(blobURL);
      setAudioFile(blob)
      setIsRecording(false);
    }).catch((e) => console.error(e));
  };

  const abortRecording = () => {
    setIsRecording(false);
    setAudioURL('');
    setIsReadyToSend(false);
  };

  useEffect(() => {
    if (!audioURL && discardRecording) return;

    const blob = audioFile;
    let fileName = `me-at-thevoice_${Date.now()}.mp3`;
    
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
      setBase64String([base64String]);
      const fileData = { name: fileName, type: blob.type };
      setMimeType(["audio/mpeg"]);      
      setFile([fileData]);
      setSubType('FILE_MESSAGE');
    };
  }, [audioURL]);

  const pickEmoji = () => {
    setIsEmoji(true);
  }

  const addEmoji = (e) => {
    let emoji = e.native;
    if(emoji){
      setIsReadyToSend(true);
    }else{
      setIsReadyToSend(false);
    }
    if (message) {
      const newValue = draftToHtml(convertToRaw(message.getCurrentContent()));
      let newVal2 = newValue.slice(0, newValue.length - 5).slice(3);
      const value = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(`${newVal2} ${emoji}`))
      );      
      setMessage(value);
    } else {
      const value = EditorState.createWithContent(
        ContentState.createFromText(emoji)
      );      
      setMessage(value);
    }
    setIsEmoji(false);
  };

  const closeAlert = () => {
    setIsMessageError(false);
    setFileSizeMsg('')
  }

  let resetErrorTime;
  useEffect(() => {
    if (resetErrorTime) {
      clearTimeout(resetErrorTime);
    }
    resetErrorTime = setTimeout(() => {
      closeAlert();
    }, 10000);
  }, [isMessageError, fileSizeMsg])

  const downloadTextAndImageFiles = (fileData, matchingFile) => {
    const { attachmentName, mimetype } = fileData;
    const file = matchingFile?.file;    

    // Convert base64 string to a Blob
    const byteCharacters = atob(file); // Decoding base64
    const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimetype || 'application/octet-stream' });

    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = attachmentName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const toggleMessageEditor = () => {
    setShowEditor(!showEditor);
    setTimeout(function(){
      if (!showEditor) {
        setIsOpenMessage(true);
      }
    },1000);
  };

  const toggleMessageSearch = () => {
    setShowMessageSearch(!showMessageSearch);
  };

  const discardMessage = () => {
    resetStates();
    setShowEditor(false);
    setIsOpenMessage(false);
  };  

  useEffect(() => {
    if (!isOpenMessage) {      
      resetStates();
      setShowEditor(false);
      abortRecording();
    }
  }, [isOpenMessage])

  const answerMessage = (msg,alias) => {
    resetStates();
    setShowEditor(true);
    setIsOpenMessage(true);
    
    if (!showEditor) {
        const blocksFromHTML = convertFromHTML("<div>&shy;<br/>-------------</div><br/><b style='font-size:12px;'>"+alias+" wrote:</b>"+msg);
        const content = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        const answerValue = EditorState.createWithContent(content);  
        setMessage(answerValue);  
    }

  };

  

  /* Auto load visible attachments */
  let observerOptions = {
      rootMargin: '0px',
      threshold: 0.5
  }

  var observer = new IntersectionObserver(observerCallback, observerOptions);

  function observerCallback(entries, observer) {
      entries.forEach(entry => {
          if(entry.isIntersecting) {
            if (!entry.target.classList.contains('viewed')) {
              entry.target.classList.add('viewed');
              setTimeout(function(){
                entry.target.click();
              },500);
            }
          }
      });
  };

  fileRef.current.forEach((i) => {
      if (i) {
          observer.observe(i);
      }
  });
  
  function openProfile(user, category) {    
    const token_language = {
      language: i18n.language,
      accessToken
    };   
    getUserDetails (token_language, user?.alias).then((response) => {
      if (response?.status === 200) {
        setUserDetailsViewSugesstion({
          relationshipId: user.relationshipId,
          markViewed: true,
          delete: false,
          name: user.alias,
          category_messenger: category,
          contactList: currentUser?.contactListUser,
          freeText: freeText.value,
          user_id: user.userId,
          id: response.data?.relationshipId,
          ...response.data
        }        
        );

        setIsOpenProfile(true);
      } else{
        setErrors({ errorMsg: response.data.message , isError: true, statusCode: response?.status, statusError:  response.data.errors});
      }
    })
}
const closeProfile = () => {
  setIsOpenProfile(false)
}

const getFreeText = (user_Id) => {
  const headers = {
    language: i18n.language,
    accessToken
  };  
  communityContactsFreeText(headers, user_Id).then((response) => {
    if (response?.status === 200) {      
      setFreeText({open: true, value: response.data});
    }
  })
}
  
  return (
    <div className='chat_container'>
      {!isFilterUser && !isOpenProfile ? (           
        <>
          <div className="chat_overview">
            <div className="overview_headline">
              {t('messenger.conversationHeadline')}<br/><strong>{currentUser?.alias}</strong>
            </div>
            <div className="overview_content scrollable" id="overview_content" ref={overviewRef}>
              {chats?.map((msg, index) => (
                  <div
                    ref={index == chats.length - 1 ? chatOverviewRef : null}
                    key={"ov"+index}
                    className="overview_message" 
                    onClick = {() => scrollToSelectedElement(index)}
                    >
                    <div className='msg_date'>
                      
                      <div id={"previewMsg"+msg.id} className="message_date_container" data-msg-index={index}>
                        <span className="message_date">
                          {new Date(msg.sendDate.split('.')[0] + 'Z').toLocaleTimeString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})}
                        </span>
                        <span className="message_user">
                          {msg.senderId == userId ?
                            t('messenger.conversationSender') : currentUser?.alias}
                        </span>
                      </div>
                      {msg.attachments?.length == 0 ?
                      <span
                        id={"preview"+msg.sendDate}
                        className="messageText"                         
                        dangerouslySetInnerHTML={{ __html: msg.message?.replace(/(.{70})..+/, "$1&hellip;") }}
                      /> : null}
                      {msg.attachments?.length > 0 ? (
                        <div className='m_file_container'>
                          {msg.attachments.map((attachment, idx) => (
                            <>
                              {attachment?.fileType === 'IMAGE' ? (
                                <div key={"att_"+attachment.id} className='m_full_image'>
                                    <FontAwesomeIcon icon={faImage} />{msg.senderId == userId ? 
                                    t('messenger.imageSent') : t('messenger.imageReceived')}
                                </div>
                              ) : (
                                <>
                                    <>
                                      {
                                        attachmentFiles.some(attachmentFile => attachmentFile.id === attachment.id) ? 
                                        <>
                                          <span>
                                            {getFileType(attachment?.attachmentName) === 'rtf' || getFileType(attachment.attachmentName) === 'pdf' ||
                                              getFileType(attachment.attachmentName) === 'txt' ? (
                                              msg.senderId == userId ? 
                                              <span><FontAwesomeIcon className='m_file_icon' icon={faFile} />{t('messenger.textfileSent')}</span> :  <span><FontAwesomeIcon className='m_file_icon' icon={faFile} />{t('messenger.textfileReceived')}</span>
                                            ) : null}
                                            {getFileType(attachment.attachmentName) == 'mp3' ||
                                              getFileType(attachment.attachmentName) == 'm4a' ||
                                              getFileType(attachment.attachmentName) == 'wav' ? (
                                                msg.senderId == userId ? 
                                                <span><FontAwesomeIcon icon={faFileAudio} />{t('messenger.audiofileSent')}</span> : <span><FontAwesomeIcon className='m_file_icon' icon={faFile} />{t('messenger.audiofileReceived')}</span>
                                            ) : null}
                                            {getFileType(attachment.attachmentName) == 'mp4' ||
                                              getFileType(attachment.attachmentName) == 'mov' ||
                                              getFileType(attachment.attachmentName) == 'qt' ? (
                                                msg.senderId == userId ? 
                                                <span><FontAwesomeIcon icon={faFileVideo} />{t('messenger.videoSent')}</span> : <span><FontAwesomeIcon className='m_file_icon' icon={faFile} />{t('messenger.videoReceived')}</span>
                                            ) : null}
                                            <small className="filename">{attachment?.attachmentName}</small>
                                          </span>
                                        </>
                                        :
                                          <>
                                            <span>
                                              {getFileType(attachment?.attachmentName) === 'rtf' || getFileType(attachment.attachmentName) === 'pdf' ||
                                                getFileType(attachment.attachmentName) === 'txt' ? (
                                                msg.senderId == userId ? 
                                                <span><FontAwesomeIcon className='m_file_icon' icon={faFile} /> {t('messenger.textfileSent')}</span> :  <span><FontAwesomeIcon className='m_file_icon' icon={faFile} /> {t('messenger.textfileReceived')}</span>
                                              ) : null}
                                              {getFileType(attachment.attachmentName) == 'mp3' ||
                                                getFileType(attachment.attachmentName) == 'm4a' ||
                                                getFileType(attachment.attachmentName) == 'wav' ? (
                                                  msg.senderId == userId ? 
                                                  <span><FontAwesomeIcon icon={faFileAudio} />{t('messenger.audiofileSent')}</span> : <span><FontAwesomeIcon icon={faFileAudio} />{t('messenger.audiofileReceived')}</span>
                                              ) : null}
                                              {getFileType(attachment.attachmentName) == 'mp4' ||
                                                getFileType(attachment.attachmentName) == 'mov' ||
                                                getFileType(attachment.attachmentName) == 'qt' ? (
                                                  msg.senderId == userId ? 
                                                  <span><FontAwesomeIcon icon={faFileAudio} />{t('messenger.videoSent')}</span> : <span><FontAwesomeIcon icon={faFileAudio} />{t('messenger.videoReceived')}</span>
                                              ) : null}
                                              <small className="filename">{attachment?.attachmentName}</small>
                                            </span>
                                          </>
                                      }
                                    </>
                                </>
                              )}
                            </>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
                {isLoading ?                 
                <div className="right_side_chat">
                  <Spinner className="file_loader" />
                </div> : null}
                {chats.length < 1 && !isLoading ?                 
                <div className="leftt_side_chat">
                 {t('messenger.noConversation')}
                </div> : null}
            </div>
          </div>
          <div className="chat_main">
            <div className='chat_header'>
              <div className='header_with_searchbar'>
                <div className='chat_header_details '>
           <div className='chat_header_img'  onClick={() => openProfile(currentUser)}>
                    <img src={avatars?.[currentUser?.avatarId] || UserIcon} alt='Profile' />
                  </div>
                  <div className='chat_suggestion_details'>
                    <div className='chat_suggestion_icons share_col'>
                      {/* <div> */}
                      {currentUser?.categories?.map((category, index) => (
                        <React.Fragment key={"sugg"+index} >
                          {category.value === 'Friendship' && <span onClick={() => {sourceType == 'SUGGESTIONS' ? "": openProfile(currentUser, category)}} className="share_icon share_friendship" title={category.value+" Info"}>
																			<FontAwesomeIcon icon={faUserFriends} /> {category.value}
																		</span>}
                          {category.value === 'Partnership' && <span onClick={() => {sourceType == 'SUGGESTIONS' ? "": openProfile(currentUser, category)}} className="share_icon share_partnership" title={category.value+" Info"}>
																			<FontAwesomeIcon icon={faHeart} /> {category.value}
																		</span>}
                        </React.Fragment>
                      ))}
                      {currentUser?.contactListUser ? <span onClick={() => {sourceType == 'SUGGESTIONS' ? "": getFreeText(currentUser?.userId)}} className="share_icon share_contactlist" title={t('messenger.contactlist')+" Info"}>
                        <FontAwesomeIcon icon={faPeopleArrows} /> {t('messenger.contactlist')}
                        </span> : null}
                      </div>
                      {/* <div className='view_profile'>
                        {currentUser?.categories != null ? <span onClick={() => openProfile(currentUser)} className="show_profile_form_messenger" title={t('suggestions.showuserprofile') + " Info"}>
                          <FontAwesomeIcon icon={faUserFriends} />
                        </span> :
                          null}
                        {currentUser?.contactListUser ?
                          <span onClick={() => getFreeText(currentUser?.userId)} className="show_profile_form_messenger" title={'Open free text' + " Info"}>
                            <FontAwesomeIcon icon={faPeopleArrows} />
                          </span> : null}
                      </div> */}
                    {/* </div> */}
                    <div className='chat_user_details'>
               <div className='chat_user_name'  onClick={() => openProfile(currentUser)}>{currentUser?.alias}</div>
                      <div className='chat_user_sex'>
                        {currentUser?.sex?.value == 'Male' ? <FontAwesomeIcon icon={faMars} /> : null}
                        {currentUser?.sex?.value == 'Female' ? <FontAwesomeIcon icon={faVenus} /> : null}
                        {currentUser?.sex?.value == 'Intersexual' ? <FontAwesomeIcon icon={faTransgenderAlt} /> : null}
                         {currentUser?.sex?.value}
                      </div>
                      <div className='user_date' style={{display:'none'}}>{currentUser?.createDate}</div>
                      <div className='chat_address'><FontAwesomeIcon icon={faMapMarkerAlt} />{currentUser?.address}</div>
                    </div>
                    
                      
                    {currentUser?.contactListUser == true &&
                        currentUser?.categories == null &&
                        currentUser?.showBlockSwitch == true ? <Form className="block_user_form">
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch"
                          label={isUserBlocked ? t('messenger.chatblock') : t('messenger.chatpossible')}
                          onChange={(e) => {
                            blockOrUnblockUser(e.target.checked, currentUser?.userId);
                          }}
                          checked={isUserBlocked}
                        />
                      </Form>
                        : null}
                  </div>
                </div> 
              </div>
                {!isUserBlocked ? <>
                  <div className="chat_button_row">
                    <div className="chat_buttons">
                  
                      {!isFileLoader ? <>  
                        {showEditor ? 
                        <button onClick={() => discardMessage()} className='discard_message_button'>
                          <FontAwesomeIcon icon={faTimes} /> <span>{t('messenger.discard')}</span>
                        </button> : null}                     
                        {isReadyToSend && !isRecording ?                         
                        <button
                          className='send_message_button'
                          onClick={() => onSendMessage(message) }
                        >
                          <FontAwesomeIcon icon={faPaperPlane} /> {t('messenger.sendMessage')}
                        </button> : null}
                        {!file?.length && !isReadyToSend && !isRecording && !showEditor ? <> 
                        <div class="message_buttons">
                          <button onClick={() => toggleMessageEditor()} className='write_message_button'>
                            <FontAwesomeIcon icon={faPen} /> <span>{t('messenger.writeMessage')}</span>
                          </button>
                          <button onClick={() => {
                            inputFileSelect.current?.click();
                            setShowEditor(true);
                            setIsOpenMessage(true);
                          }} className='upload_file_button'>
                          <FontAwesomeIcon icon={faPaperclip} /> <span>{t('messenger.sendFile')}</span>
                          </button>
                          <button onClick={() => startRecording()} className='record_audio_button'>
                          <FontAwesomeIcon icon={faMicrophone} /> <span>{t('messenger.recordAudio')}</span>
                          </button>
                        </div>
                        <div className="chat_search">     
                          {showMessageSearch ? 
                          <div className="search_chat">
                            <input
                              id="search_input1"
                              className="search_input1" 
                              aria-label={t('messenger.searchchat')} 
                              onChange={(e) => setSearchParams(e)}
                              onKeyDown={(e) => pressDownKey(e)}
                              onKeyUp={(e) => pressUpKey(e)}
                              value={searchString}
                              type="text"
                              placeholder={t('messenger.searchchat')}
                              autoComplete="off"
                            />
                            {searchString && (
                              <>
                                <button onClick={() => nextResult()}>
                                  <FontAwesomeIcon icon={faChevronLeft} />
                                </button>
                                <span className="message_count" id="messageCount"></span>
                                <button onClick={() => previousResult()}>
                                  <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                                <button className="clear-button" onClick={() => {
                                  setSearchString("");
                                  setShowMessageSearch(false);
                                  clearAllHighlights();
                                }}>
                                  <FontAwesomeIcon icon={faTimes} />
                                </button>
                              </>
                            )}
                          </div> : null}
                          <button 
                            className={showMessageSearch ? 'activeMessageSearch': null}                             
                            aria-label={t('messenger.searchchat')+" Button"} 
                            onClick={toggleMessageSearch} 
                          >
                            <FontAwesomeIcon icon={faSearch} />
                          </button>
                        </div>
                        </> : null}
                      </> : null}
                      <input
                        id="file-upload"
                        ref={inputFileSelect}
                        name="audio-and-image-upload" 
                        aria-label={t('messenger.sendFile')} 
                        onChange={(e) => addFile(e)}
                        type={resetFile ? "reset" : "file"}
                        multiple
                        style={{ display: 'none' }}
                      />
                    </div>      
                  </div>   
                  {isFileLoader ?
                    <div className="right_side_chat">
                    <Spinner className="file_loader" />
                  </div>
                    : null}
                  <div className='record_audio'>
                    <div className='record_voice'>
                      <div>
                        {isRecording ? <>
                          <div className="recordControls">
                            <img className='audiogif' src={require("../../../assets/images/audiogif.gif")} />
                            <FontAwesomeIcon
                              onClick={stopRecording}
                              icon={faPause}
                              color='white'
                              data-tip
                              data-for='for_stop'
                            />
                            <Button
                              className='stopandsaveButton'
                              onClick={stopRecording}>
                              <FontAwesomeIcon icon={faStop} /> {t('member.audio.stopandsave')}
                            </Button>
                            <Button
                              className='abortButton'
                              title={t('member.audio.abort')}
                              onClick={() => {
                                abortRecording();
                              }}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </Button>
                          </div>
                        </> : null}
                      </div>
                    </div>
                  </div>
                  {fileSizeMsg != '' ? <Alert
                    className='member_address'
                    variant="danger"
                    id='main_alert'
                    onClose={() => closeAlert()}
                    dismissible
                  >
                    {fileSizeMsg}
                  </Alert> : null}
                  {isMessageError ? <Alert
                    className='member_address'
                    variant="danger"
                    id='main_alert'
                    onClose={() => closeAlert()}
                    dismissible
                  >
                    {userDetails?.error}
                  </Alert> : null}
                  </> : 
                null} 
            </div>
            <div className='chat_msges_and_texteditor position-relative'>
              <div id="chat_editor">
                {!(currentUser?.contactListUser == true &&
                  currentUser?.categories == null &&
                  currentUser?.showBlockSwitch == true) && isUserBlocked ? <p>{`Chat blocked by ${currentUser?.alias}`} </p> : null}
                {isUserBlocked && (currentUser?.contactListUser == true &&
                  currentUser?.categories == null &&
                  currentUser?.showBlockSwitch == true) ? <p className="blockInfo">Chat blocked by you</p> : null}
              </div>
              <div className='chat_msges_body scrollable' id="chat_msges_body" ref={searchRef}>
                {showEditor ? <>
                <div
                  className='pick_emoji' 
                  onClick={(event) => {
                    event.stopPropagation();
                    pickEmoji();
                }}
                >
                  
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm4 72.6c-20.8 25-51.5 39.4-84 39.4s-63.2-14.3-84-39.4c-8.5-10.2-23.7-11.5-33.8-3.1-10.2 8.5-11.5 23.6-3.1 33.8 30 36 74.1 56.6 120.9 56.6s90.9-20.6 120.9-56.6c8.5-10.2 7.1-25.3-3.1-33.8-10.1-8.4-25.3-7.1-33.8 3.1z"/></svg>
                </div>
                
                <div className='m_text_editor' ref={editorRef}>
                  <TextEditor
                    message={message}
                    setMessage={setMessage}
                    keyBindingFn={keyBindingFn}
                    setIsReadyToSend={setIsReadyToSend} 
                  />
                  {base64String.length > 0 && (
                    <div className='preview_image_container'>
                      {file?.map((file, index) => (
                        <div key={"preview"+index} className="file-preview-item">
                          <div
                            onClick={() => removePreview(index)}
                            className='xmark_preview'>
                            <FontAwesomeIcon className='close_icon' icon={faXmark} />
                          </div>
                          {mimeType[index]?.includes('image') ? (
                            <img
                              src={`data:${file.type};base64,${base64String[index]}`}
                              className="preview-image"
                              alt={`Preview of ${file.name}`}
                            />
                          ) : (
                            <p className="preview-test">
                              <FontAwesomeIcon className='m_file_icon' icon={faFile} />
                              {file.name}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div></> : null}
                {chats?.map((msg, index) => (
                  <div
                    ref={index == chats.length - 1 ? chatContainerRef : null}
                    key={"msg"+index}
                    className={msg.senderId == userId ? 'right_side_chat' : 'left_side_chat'}>
                    {msg.senderId != userId && (
                      <div className='left_side_chat_img'>
                        <img src={avatars?.[currentUser?.avatarId] || UserIcon} alt='User' />
                      </div>
                    )}
                    <div className='msg_date'>
                      
                      <div id={msg.id} ref={el => msgRef.current[index] = el} className="message_date_container">
                        <span className="message_date">
                          {msg.senderId == userId ? 
                            <strong>{t('messenger.sentByMe')}</strong>
                          : <strong>{t('messenger.receivedFrom')} {currentUser?.alias}</strong>}
                          {new Date(msg.sendDate.split('.')[0] + 'Z').toLocaleTimeString([], {year: 'numeric', month: 'long', weekday: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit'})}
                        </span>
                        {msg.senderId == userId ? 
                        <Dropdown>
                          <Dropdown.Toggle as="span" className="icon-toggle" bsPrefix="custom-toggle">
                            <FontAwesomeIcon className="delete_msg" icon={faEllipsisVertical} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                              {msg.attachments.map((attachment) => {
                                const matchingFile = attachmentFiles.find((file) => file.id === attachment.id);
                                if (matchingFile) {
                                  return (
                                    (attachment?.fileType === 'IMAGE' || attachment?.fileType === 'FILE') && (
                                      <Dropdown.Item
                                        key={"dropdown"+attachment.id}
                                        onClick={() => downloadTextAndImageFiles(attachment, matchingFile)}
                                      >
                                        {t('messenger.download')} 
                                      </Dropdown.Item>
                                    )
                                  );
                                }
                                return null;
                              })}
                              <Dropdown.Item onClick={() => deleteMessage(msg.id)}>
                                {t('common.delete')}
                              </Dropdown.Item>
                          </Dropdown.Menu>                        
                        </Dropdown> : 
                        <button onClick={() => answerMessage(msg.message,currentUser?.alias)} className='answer_message_button' aria-label={t('messenger.answer')}>
                          <FontAwesomeIcon icon={faReply} />
                        </button>
                      }
                      </div>
                      <span
                        id={msg.sendDate}
                        className="messageText"
                        data-msg-index={index}
                        dangerouslySetInnerHTML={{ __html: msg.message }}
                      />
                      {msg.attachments?.length > 0 ? (
                        <div className='m_file_container'>
                          {msg.attachments.map((attachment, idx) => (
                            <>
                              {attachment?.fileType === 'IMAGE' ? (
                                <div key={"img"+attachment.id} className='m_full_image'>
                                    <>
                                      {
                                        attachmentFiles.some(attachmentFile => attachmentFile.id === attachment.id) ? null : 
                                          <>
                                            <div 
                                              className={msg.senderId == userId ? 'm_image rdownload' : 'm_image ldownload'} 
                                              id={"img"+attachment.id} 
                                              onClick={() => downloadFile(attachment.id)} 
                                              // ref={el => fileRef.current[index] = el}
                                            >
                                            {currentAttachmentId === attachment.id ? <Spinner /> : <FontAwesomeIcon icon={faDownload} />}
                                            </div>
                                            <span>
                                              <FontAwesomeIcon className='m_file_icon' icon={faFile} /> {attachment.attachmentName}
                                            </span>
                                          </>
                                      }
                                    </>
                                  {attachmentFiles.map((attachmentFile) => (
                                    attachmentFile.id === attachment.id ? <img src={base64Image(attachmentFile.file)} alt={t('messenger.sentByMe')+" "+attachment.id} key={"full"+attachment.id} className="full-image" /> : null
                                  ))}
                                </div>
                              ) : (
                                <>
                                    <>
                                      {
                                        attachmentFiles.some(attachmentFile => attachmentFile.id === attachment.id) ? null :
                                          <>
                                            <div 
                                              className={msg.senderId == userId ? 'm_image rdownload' : 'm_image ldownload'} 
                                              onClick={() => downloadFile(attachment.id)} 
                                              // ref={el => fileRef.current[index] = el}
                                            >
                                              {currentAttachmentId === attachment.id ? <Spinner /> : <FontAwesomeIcon icon={faDownload} />}
                                            </div>
                                            <span>
                                              <FontAwesomeIcon className='m_file_icon' icon={faFile} />
                                              {attachment.attachmentName}
                                            </span>
                                          </>
                                      }
                                    </>
                                  {attachmentFiles.map((attachmentFile) => (
                                    attachmentFile.id === attachment.id ? (
                                      <div key={"attachment"+attachmentFile.id}>
                                        {getFileType(attachmentFile.attachmentName) === 'rtf' ? (
                                          <a href={fileUrls[attachmentFile.id] || fileConverter(attachmentFile.file, attachmentFile.id, attachmentFile.attachmentName)}
                                            download={`${attachmentFile.id}.rtf`}>
                                            Download RTF File
                                          </a>
                                        ) : null}
                                        {getFileType(attachmentFile.attachmentName) === 'pdf' ||
                                          getFileType(attachmentFile.attachmentName) === 'txt' ? (
                                          <iframe src={fileUrls[attachmentFile.id] || fileConverter(attachmentFile.file, attachmentFile.id, attachmentFile.attachmentName)}
                                            width="100%"
                                            height="500px"
                                            title="Viewer" />
                                        ) : null}
                                        {getFileType(attachmentFile.attachmentName) == 'mp3' ||
                                          getFileType(attachmentFile.attachmentName) == 'm4a' ||
                                          getFileType(attachmentFile.attachmentName) == 'wav' ? (
                                          <audio controls>
                                            <source src={fileUrls[attachmentFile.id] || fileConverter(attachmentFile.file, attachmentFile.id, attachmentFile.attachmentName)} />
                                            Your browser does not support the audio element.
                                          </audio>
                                        ) : null}
                                        {getFileType(attachmentFile.attachmentName) == 'mp4' ||
                                          getFileType(attachmentFile.attachmentName) == 'mov' ||
                                          getFileType(attachmentFile.attachmentName) == 'qt' ? (
                                          <>
                                              <video controls width="100%" height="auto">
                                                <source src={fileUrls[attachmentFile.id] || fileConverter(attachmentFile.file, attachmentFile.id, attachmentFile.attachmentName)} />
                                                Your browser does not support the video element.
                                              </video>
                                            </>
                                        ) : null                                       
                                        }
                                      </div>
                                    ) : null
                                  ))}
                                </>
                              )}
                            </>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}                
                {chats.length < 1 && !isLoading ?                 
                <div className="left_side_chat">
                 {t('messenger.noConversation')}
                </div> : null}
                <div className='emoji_container' >
                  {isEmoji ? <Picker
                    onEmojiSelect={addEmoji}
                    emojiTooltip={false}
                    emojiSize={18}
                    onClickOutside={() => {
                      if (isEmoji) {
                        setIsEmoji(false);
                      }
                   }}
                  /> : null}
                </div>
              </div>
              
            </div>
          </div>
 </>
    ) : (

            <div className='noUser_container'>
              <img src={NoChats} width={300} alt='Profile' />
              <h2>{t('messenger.NoUserSelected')}</h2>
              <span>{t('messenger.NoUserDescription')}</span>
            </div>
    )}

    {isOpenProfile && (
      <SuggestionView
        isParentSuggestion={false}
        avatars={avatars}
        setAvatars={setAvatars}
        show={isOpenProfile}
        base64Image={base64Image}
        handleClose={closeProfile}
        suggestion={userDetailsViewSugesstion}
      />
    )}
      <Modal className='openprofile' size='lg' show={freeText.open} onHide={() => setFreeText({ open: false, value: [] })}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>Free text</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{console.log(freeText)
        }
          <FreeText ContactListFreeText={freeText.value} />
        </Modal.Body>
      </Modal>
    </div>    
      )
    }

export default ChatHistory;