import React from 'react'
import { Form, Modal } from 'react-bootstrap';
import './freetext.css';

function FreeText(props) {
    const { ContactListFreeText } = props;
    return (
        <>
            {ContactListFreeText?.natureListName != null ? <div className='freetext_for_messenger'>
                <Form.Group className="mt-3 freetext_in_messenger">
                    <div className="flex-container">

                        <span className="descriptionI_label">
                            <Form.Label className="radio_label" htmlFor={ContactListFreeText?.natureListName}>
                                {ContactListFreeText?.natureListName}
                            </Form.Label>
                        </span>
                        <Form.Control
                            value={ContactListFreeText?.natureListName}
                            as="textarea"
                            disabled
                            id={ContactListFreeText?.natureListName}
                        />
                    </div>
                </Form.Group>
            </div> : null}
            {ContactListFreeText?.projectFreeText != null ? <div className='freetext_for_messenger'>
                <Form.Group className="mt-3 freetext_in_messenger">
                    <div className="flex-container">

                        <span className="descriptionI_label">
                            <Form.Label className="radio_label" htmlFor={ContactListFreeText?.projectListName}>
                                {ContactListFreeText?.projectListName}
                            </Form.Label>
                        </span>
                        <Form.Control
                            value={ContactListFreeText?.projectFreeText}
                            id={ContactListFreeText?.projectListName}
                            as="textarea"
                            disabled
                        />
                    </div>
                </Form.Group>
            </div> : null}
            {ContactListFreeText?.berufFreeText != null ? <div className='freetext_for_messenger'>
                <Form.Group className="mt-3 freetext_in_messenger">
                    <div className="flex-container">
                        <span className="descriptionI_label">
                            <Form.Label className="radio_label" htmlFor={ContactListFreeText?.berufFreeText}>
                                {ContactListFreeText?.berufListName}
                            </Form.Label>
                        </span>
                        <Form.Control
                            value={ContactListFreeText?.berufFreeText}
                            as="textarea"
                            id={ContactListFreeText?.berufListName}
                            disabled
                        />
                    </div>
                </Form.Group>
            </div> : null}
            {ContactListFreeText?.treffenFreeText != null ? <div className='freetext_for_messenger'>
                <Form.Group className="mt-3 freetext_in_messenger">
                    <div className="flex-container">
                        <span className="descriptionI_label">
                            <Form.Label className="radio_label" htmlFor={ContactListFreeText?.treffenListName}>
                                {ContactListFreeText?.treffenListName}
                            </Form.Label>
                        </span>
                        <Form.Control
                            value={ContactListFreeText?.treffenFreeText}
                            as="textarea"
                            disabled
                            id={ContactListFreeText?.treffenListName}
                        />
                    </div>
                </Form.Group>
            </div> : null}
            {ContactListFreeText?.noListMessage != null ? <div className='freetext_for_messenger'>
                <Form.Group className="mt-3 freetext_in_messenger">
                    <div className="flex-container">
                        <span className="descriptionI_label">
                            <Form.Label className="radio_label">
                                {ContactListFreeText?.noListMessage}
                            </Form.Label>
                        </span>
                    </div>
                </Form.Group>
            </div> : null}
        </>
    )
}

export default FreeText;