import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import {downloadPdf} from '../home/homeServices.jsx';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { LoginContext } from '../../context/loginContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilePdf
  } from '@fortawesome/free-solid-svg-icons';
  import usePreventBack from './preventBack/preventBack'

function PersonalPost(props) {
  let {
    loginData: {
      memberDetails,
      accessToken
    },
  } = useContext(LoginContext);
  usePreventBack();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const openPdf = async (type) => {
    const data = {
      type: type,
      language: currentLang,
      accessToken: accessToken
    }
    const response = await downloadPdf(data)
    const blob = await response.blob();
    const file = new Blob([blob], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    window.URL.revokeObjectURL(fileURL)
  }

  return (
    <div>
      <div className="looking_for">
        <div className="questionnaireHeader">
          <h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.analysesHeader')}</strong></h3>
          <h4>{t('registration.personalPost.header')}</h4>
          <p>{t('registration.personalPost.description')}</p>
        </div>
      </div>
      <div className='from_control questionair_container'>
        <div className='questionair_header'>
          <h3 className='questionair_heading'>{t('registration.personalPost.analysis')}</h3>
        </div>
        <div className="mt-3 partnershippost_btn questions_wrapper">
          <p>{t('registration.personalPost.download')}</p>
          { memberDetails.partnershipActive === true ? 
          <Button
            className='mx-3'
            onClick={() => {openPdf('partnership')}}
            variant="primary">
            <FontAwesomeIcon icon={faFilePdf} /> {t('registration.personalPost.partnershippdf')}
          </Button> : null}
          { memberDetails.friendshipActive === true ? 
          <Button
            className='mx-3 friend'
            onClick={() => {openPdf('friendship')}}
            variant="primary">
            <FontAwesomeIcon icon={faFilePdf} /> {t('registration.personalPost.friendshippdf')}
          </Button> : null}
          <Button
            className='mx-3 person'
            onClick={() => {openPdf('personal')}}
            variant="primary">
            <FontAwesomeIcon icon={faFilePdf} /> {t('registration.personalPost.personalpdf')}
          </Button>
          <Button
            className='mx-3 social'
            onClick={() => {openPdf('companyprofile')}}
            variant="primary">
            <FontAwesomeIcon icon={faFilePdf} /> {t('registration.personalPost.socialpdf')}
          </Button>
        </div>
          <div className="fwd_row">
            <Button
              className='mx-5'
              variant="primary"
              onClick={() => props.handleNextQustionaire()}
            >
              {t('registration.buttons.next')}
            </Button>
          </div>
      </div>
    </div>
  )
}

export default PersonalPost;