import React from 'react'
import GlkHeader from '../../components/Header/GlkHeader.jsx';
import icon1 from "../../assets/images/login_icon1.png";
import icon2 from "../../assets/images/login_icon2.png";
import icon3 from "../../assets/images/login_icon3.png";
import user_ico from "../../assets/images/user_ico.png";
import { useTranslation } from "react-i18next";
import './forgotPassword.css'
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const backToLogin = () => {
    navigate('/')
  }

  return (
    <div>
      <GlkHeader />
      <div className="forgetPass_container">
        <div className="fp_main_container">
          <img className="icon_1" src={icon2} alt="login_icon1" aria-hidden="true" />
          <img className="icon_2" src={icon1} alt="login_icon2" aria-hidden="true" />
          <img className="icon_3" src={icon3} alt="login_icon3" aria-hidden="true" />
          <div className="fp_inputs_container">
            <h1 className="forget_password">{t("member.forgotPassword.forgotpassword")}</h1>
            <label htmlFor='email' className="forgot_password">forget password</label>
            <form id="email">
              <div className="fp_inputs">
                <label htmlFor="email">{t("member.forgotPassword.email")}<span className='required_star'>*</span></label>
                <div className="input_Logo_div">
                  <span>
                    <img className="user_ico" src={user_ico} alt="user" />
                  </span>
                  <input
                    id="email"                    
                    type="text"
                    name="email"
                  />
                </div>
              </div>
              <div className="fp_btn">
                <button
                  onClick={() => backToLogin()}
                  aria-label={t('member.forgotPassword.backtologin')}
                  type="btn">
                  {t("member.forgotPassword.backtologin")}
                </button>
                <button
                  aria-label={t('member.forgotPassword.resetpassword')}
                  type="submit">
                  {t("member.forgotPassword.resetpassword")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ForgotPassword;