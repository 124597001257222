import React, { useEffect, useContext, useState } from 'react';
import GlkHeader from '../../components/Header/GlkHeader';
import Banner from '../../components/banner/banner';
import suggestionIcon from '../../assets/images/suggestion.png';
import new_icon from '../../assets/images/new_icon.png';
import icon_affiliation from '../../assets/images/icon_affiliation.png';
import icon_foot_bekommen from '../../assets/images/icon_foot_bekommen.svg';
import icon_foot_vergeben from '../../assets/images/icon_foot_vergeben.svg';
import user_icon from '../../assets/images/user-icon.png';
import './suggestions.css';
import {Button, Row, Form, Col, Accordion, Alert, Spinner} from 'react-bootstrap';

import SuggestionCard from './suggestionCard';
import { getSuggetions, getAvetar } from './suggestionService';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../context/loginContext';
import { getContinentForMemberAddress, getCountry, getRegion } from '../registrationProcess/registrationProcessService';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSexQuestion } from '../registration/preRegistrationService';
import { escapeFilterCharacters } from '../commonMethods';

function Suggestions() {
    const [suggestions, setSuggestions] = useState();
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSuggestion, setSelectedSuggestion] = useState('');
    const [isLastSeen, setIsLastSeen] = useState(false);
    const [filters, setFilters] = useState([]);
    const [hideUnavailable, setHideUnavailable] = useState(true);
    const [search, setSearch] = useState('');
    const [selectFilter, setSelectFilter] = useState();
    const [filtersData, setFiltersData] = useState([]);
    const [storeSearchValue, setStoreSearchValue] = useState([]);
    const [forAvailableFilter, setForAvailableFilter] = useState([]);
    const [alias, setAlias] = useState('');
    const [continentId, setContinentId] = useState('');
    const [countryId, setCountryId] = useState('');
    const [regionId, setRegionId] = useState('');
    const [regionName, setRegionName] = useState('');
    const [freeText, setFreeText] = useState('');
    const [searchValue, setSearchValue] = useState({
        name: '',
        region: '',
        age: '',
        sex: '',
        freeText: ''
    });
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState();
    const [continent, setContinent] = useState([]);
    const [sexQuestion, setSexQuestion] = useState(null);
    const [minAge, setMinAge] = useState('');
    const [maxAge, setMaxAge] = useState('');
    const [genderId, setGenderId] = useState('');
    const [genderValue, setGenderValue] = useState('');
    const [recordPerPage, setRecordPerPage] = useState(10);
    const [paginateData, setPaginateData] = useState({
        data: [],
        limit: recordPerPage,
        activePage: 1
    });
    const [totalPage, setTotalPage] = useState();
    const [error, setError] = useState('');
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [isLoader, setIsLoader] = useState(false);

    const { t, i18n } = useTranslation();

    const data = {
        "image": suggestionIcon,
        "menuName": t('suggestions.mysuggestion'),
        "header": t('member.menubar.suggestions'),
        "description": t('suggestions.bannerdescription'),
        "color": "#59751c"
    }

    let {
        loginData: {
            memberDetails,
            accessToken
        },
    } = useContext(LoginContext);

    const currentLang = i18n.language;

    const token_lang = {
        language: currentLang,
        accessToken
    }

    useEffect(() => {
        suggestion(1, 10);
        setRecordPerPage(10)
        setPaginateData({
          data: [],
          limit: 10,
          activePage: 1
      });
    }, [selectedCategory, selectedSuggestion, filters, hideUnavailable, search, currentLang]);

    const suggestion = (pageNumber, pageRecord) => {
        setIsLoader(true);
        const filterString = [selectedCategory, selectedSuggestion, ...filters, search]
        .filter(item => item && item.trim())
        .join(',').replace(/^,/, '');             
        
        const suggetionData = {
            available: hideUnavailable,
            search: filterString,
            pageNo: pageNumber,
            pageSize: pageRecord,
            lastSeen: isLastSeen
        };
        getSuggetions(token_lang, suggetionData).then((res) => {
            if (res.status === 200) {
                setSuggestions(res.data);
                setIsLoader(false);
                const total_count = res.data?.total;
                const total_page = Math.floor(total_count / pageRecord)
                const remainder = total_count % pageRecord;
                if (remainder !== 0) {
                    setTotalPage(total_page + 1);
                } else {
                    setTotalPage(total_page)
                }
                setPaginateData((prev) => ({
                    ...prev,
                    limit: pageRecord,
                    data: res.data?.users
                }));
            }          
        })
            .catch((error) => { });
    }

    const categoryFilter = (event) => {
        setSelectedCategory(event.target.value);
    };

    const suggestionFilter = (event) => {
        if (event.target.value) {
            setSelectedSuggestion(`20-${event.target.value}`);
        } else setSelectedSuggestion(event.target.value);
    };

    const advanceFilter = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setFilters([...filters, value]);
        } else {
            setFilters(filters.filter(filter => filter !== value));
        }
    };

    const isAvailable = (event) => {
        setHideUnavailable(event.target.checked);
    };

    const getContinents = async () => {
        const result = await getContinentForMemberAddress(token_lang)
        if (result?.status === 200) {
            setContinent(result.data?.locations);
        }
    }

    useEffect(() => {
        if (+selectFilter === 10) {
            getContinents()
        }
        if (+selectFilter === 12) {
            selectGender()
        }
    }, [selectFilter])

    const getRegions = async (countryId) => {
        const res = await getRegion(accessToken, countryId);
        if (res?.status === 200) {
            setRegions(res?.data?.locations)
        }
    }

    const getCountries = async (id) => {
        setError('')
        const data = await getCountry(token_lang, id)
        if (data?.status === 200) {
            setCountries(data?.data.locations)
        }
    }

    const selectCountry = (e) => {
        setError('')
        getRegions(e.target.value);
        setCountryId(e.target.value);
        const selectedCountryId = e.target.value;
        const selectedCountry = countries.find(country => country.id === +selectedCountryId);
        const countryName = selectedCountry ? selectedCountry.value : '';
        setRegionName(countryName);
        setError('')
    }

    const selectRegion = (e) => {
        setError('')
        setRegionId(e.target.value);
        const selectedRegionsId = e.target.value;
        const selectedRegion = regions?.find(region => region.id === +selectedRegionsId);
        const regionName = selectedRegion ? selectedRegion.value : '';
        setRegionName(regionName);
    }

    const selectContinent = (e) => {
        getCountries(e.target.value);
        setContinentId(e.target.value);
        const selectedContinentId = e.target.value;
        const selectedContinent = continent.find(cont => cont.id === +selectedContinentId);
        const continentName = selectedContinent ? selectedContinent.value : '';
        setRegionName(continentName);
    }

    const selectGender = () => {
        const finalData = {
            language: currentLang
        }
        getSexQuestion(finalData).then((res) => {
            if (res?.status === 200 && res.data) {
                setSexQuestion(res?.data)
            }
        });
    }

    const gender = (e) => {
        setGenderId(e.target.value)
        const choiceId = e.target.value;
        const selectedChoice = sexQuestion.choices.find(choice => choice.choiceId === +choiceId);
        if (selectedChoice) {
            setGenderValue(selectedChoice.value);
        }
    }

    const onSelectFilter = (e) => {
        setSelectFilter(e.target.value)
        setFiltersData((prevFilter) =>[ ...new Set([...prevFilter, e.target.value])])
    }    
    
    const isError = () => {
        if (+selectFilter === 1 && alias === "") {
            setError(t('suggestions.alias'));
            return true;
        }
        if (+selectFilter === 10 && (continentId === "")) {
            setError(t('registration.address.continent'));
            return true;
        }        
        if (+selectFilter === 11 && (minAge === "" || maxAge === "")) {
            setError(t('suggestions.age'));
            return true;
        }
        if (+selectFilter === 12 && genderValue === "") {
            setError(t('suggestions.sex'));
            return true;
        }
        if (+selectFilter === 16 && freeText === "") {
            setError(t('suggestions.freetext'));
            return true;
        }
    }

    useEffect(() => {
        setError('')
    }, [selectFilter])

    const onAddFilter = () => {
        setError('');        
        if (!validateAge(minAge, maxAge)) {
            return;
        }
        if (isError()) {
            return;
        }
        searchValue.name = alias;
        searchValue.region = regionName;
        searchValue.freeText = freeText;
        searchValue.age = minAge && maxAge ? `${minAge}-${maxAge}` : '';
        searchValue.sex = genderValue;
        const filterParts = [];

        if (filtersData.includes('1')) {
            if (alias) {
                setAppliedFilters((prevFilter) =>[ ...new Set([...prevFilter, 1])])
                filterParts.push(`${1}-${escapeFilterCharacters(alias)}`);
            } else filterParts.push('');
        }

        if (filtersData.includes('10')) {
            if (continentId || countryId || regionId) {
                setAppliedFilters((prevFilter) =>[ ...new Set([...prevFilter, 10])])
                filterParts.push(`${10}-${continentId}:${countryId}:${regionId}`);
            } else filterParts.push('');
        }

        if (filtersData.includes('11')) {
            if (minAge && maxAge) {
                setAppliedFilters((prevFilter) =>[ ...new Set([...prevFilter, 11])])
                filterParts.push(`${11}-${minAge}:${maxAge}`);
            } else filterParts.push('');
        }
        if (filtersData.includes('12')) {
            if (genderId) {
                setAppliedFilters((prevFilter) =>[ ...new Set([...prevFilter, 12])])
                filterParts.push(`${12}-${genderId}`);
            } else filterParts.push('');
        }
        if (filtersData.includes('16')) {
            if (freeText) {
                setAppliedFilters((prevFilter) =>[ ...new Set([...prevFilter, 16])])
                filterParts.push(`${16}-${escapeFilterCharacters(freeText)}`);
            } else filterParts.push('');
        }

        const filteredArray = filterParts.filter(element => element !== '');
        let result = filteredArray.join(',');

        if (filterParts.length > 0) {
            setStoreSearchValue(filterParts);
            setForAvailableFilter([...forAvailableFilter, selectFilter]);
            setSearch(result);
        } else {
            return;
        }
        setSelectFilter('');
    };

    const onDeleteFilter = (filterId) => {
        let updatedFilterParts = [...storeSearchValue];
        updatedFilterParts = updatedFilterParts.filter(part => !part.startsWith(`${filterId}-`));
        let updatedSearchData = updatedFilterParts.join(',');
        setStoreSearchValue(updatedFilterParts);
        setForAvailableFilter(forAvailableFilter.filter(filter => filter !== filterId));
        setSearch(updatedSearchData);
        setAppliedFilters( (prevFilter) => prevFilter.filter((value) => value != filterId))
        switch (filterId) {
            case '1':
                setAlias('');
                searchValue.name = '';
                break;
            case '10':
                setRegionName('')
                setContinentId('')
                setCountryId('')
                setRegionId('')
                setContinent()
                setCountries()
                setRegions()

                searchValue.region = '';
                break;
            case '11':
                setMinAge('');
                setMaxAge('');
                searchValue.age = '';
                break;
            case '12':
                setGenderId('');
                setGenderValue('');
                searchValue.sex = '';
                break;
            case '16':
                setFreeText('');
                searchValue.freeText = '';
                break;
            default:
                break;
        }
    };

   const removeAllFilter = () => {
    setForAvailableFilter([]);
    setStoreSearchValue([]);
    setAppliedFilters([])
     setSearch([]);
     setAlias("");
     setRegionName("");
     setContinentId("");
     setCountryId("");
     setRegionId("");
     setContinent();
     setCountries();
     setRegions();
     setMinAge("");
     setMaxAge("");
     setGenderId("");
     setGenderValue("");
     setFreeText("");
     searchValue.freeText = "";
     searchValue.sex = "";
     searchValue.age = "";
     searchValue.name = "";
     searchValue.region = '';
   };

    const filtersName = [
        { id: 1, value: 'Alias' },
        { id: 10, value: 'Region' },
        { id: 11, value: 'Age' },
        { id: 12, value: 'Sex' },
        { id: 16, value: 'Free Text' },
    ]

    const availableOptions = filtersName.filter(item => !forAvailableFilter.includes(String(item.id)));

    const onPagination = (pageNumber, recordPerPage) => {
        setPaginateData((prev) => ({ ...prev, activePage: pageNumber }));
        suggestion(pageNumber, recordPerPage)
    };

    const selectPageCounts = (e) => {
        setRecordPerPage(e.target.value);
        onPagination(1, e.target.value);
    }

    const validateAge = (min, max) => {
        if (min && (min < 18 || min > 120)) {
            setError(t('suggestions.minage'));
            return false;
        }
        if ( max && (max > 120 || max < 18)) {
            setError(t('suggestions.maxage'));
            return false;
        }
        if (min && max && min > max) {
            setError('');
            return true;
        }
        setError('');
        return true;
    };

    const handleMinAgeChange = (e) => {
        const value = e.target.value;
        setMinAge(value);
    };

    const handleMaxAgeChange = (e) => {
        const value = e.target.value;
        setMaxAge(value);
    };


    return (
      <div>
        <GlkHeader />
        <main className="main_wrapper suggestions_wrapper">
          <Banner data={data} />
          <div className="questionaire_in_menubar">
            <div className="suggestions_container">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="sugn_acdn_hdr">
                    {memberDetails?.partnershipActive === true &&
                    memberDetails?.friendshipActive === true
                      ? t("suggestions.category&suggestion")
                      : t("suggestions.suggestionFilterHeading")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row className="mb-3 cat_sug_filter">
                      {memberDetails?.partnershipActive === true &&
                      memberDetails?.friendshipActive === true ? (
                        <Form.Group as={Col} controlId="formGridCategory">
                          <Form.Label>{t("suggestions.category")}</Form.Label>
                          <Form.Select
                            defaultValue="All Categories"
                            onChange={categoryFilter}
                          >
                            <option value="">
                              {t("suggestions.allcategory")}
                            </option>
                            <option value="18">
                              {t("myprofile.friendship")}
                            </option>
                            <option value="19">
                              {t("myprofile.partnership")}
                            </option>
                          </Form.Select>
                        </Form.Group>
                      ) : null}

                      <Form.Group
                        as={Col}
                        className="all_sug"
                        controlId="formGridSuggestion"
                      >
                        <Form.Label>{t("suggestions.suggestion")}</Form.Label>
                        <Form.Select
                          defaultValue="All Suggestions"
                          onChange={suggestionFilter}
                        >
                          <option value="">
                            {t("suggestions.allsuggestion")}
                          </option>
                          <option value="POSITIVE">
                            {t("suggestions.accepted")}
                          </option>
                          <option value="NEGATIVE">
                            {t("suggestions.ignored")}
                          </option>
                          <option value="NEUTRAL">
                            {t("suggestions.undecided")}
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="sugn_acdn_hdr">
                    {t("suggestions.filter")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Filter</Form.Label>
                        <Form.Select
                          defaultValue="Please select"
                          onChange={(e) => onSelectFilter(e)}
                        >
                          <option value="">Please select</option>
                          {availableOptions?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.value}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Row>
                    {+selectFilter === 10 ? (
                      <>
                        <Form.Group as={Col}>
                          <Form.Select
                            className="s_filter_width"
                            onChange={(e) => selectContinent(e)}
                          >
                            <option>
                              {t("registration.buttons.pleaseselect")}
                            </option>
                            {continent?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.value}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        {countries?.length ? (
                          <Form.Group as={Col}>
                            <Form.Select
                              className="s_filter_width"
                              onChange={(e) => selectCountry(e)}
                            >
                              <option>
                                {t("registration.buttons.pleaseselect")}
                              </option>
                              {countries?.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.value}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        ) : null}

                        {regions?.length ? (
                          <Form.Group as={Col}>
                            <Form.Select
                              className="s_filter_width"
                              onChange={(e) => selectRegion(e)}
                            >
                              <option>
                                {t("registration.buttons.pleaseselect")}
                              </option>
                              {regions?.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.value}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        ) : null}
                      </>
                    ) : null}

                    {+selectFilter === 1 ? (
                      <Form.Group as={Col}>
                        <Form.Control
                          value={alias}
                          type="text"
                          onChange={(e) => setAlias(e.target.value)}
                        />
                      </Form.Group>
                    ) : null}
                    {+selectFilter == 11 ? (
                      <Form.Group as={Col}>
                        <Form.Label>Min</Form.Label>
                        <Form.Control
                          value={minAge}
                          type="number"
                          onChange={handleMinAgeChange}
                        />
                        <Form.Label>Max</Form.Label>
                        <Form.Control
                          value={maxAge}
                          type="number"
                          onChange={handleMaxAgeChange}
                        />
                      </Form.Group>
                    ) : null}
                    {+selectFilter == 16 ? (
                      <Form.Group as={Col}>
                        <Form.Control
                          type="text"
                          onChange={(e) => setFreeText(e.target.value)}
                        />
                      </Form.Group>
                    ) : null}
                    {+selectFilter == 12 ? (
                      <Form.Group as={Col}>
                        <Form.Select
                          className="s_filter_width"
                          onChange={(e) => gender(e)}
                        >
                          <option>
                            {t("registration.buttons.pleaseselect")}
                          </option>
                          {sexQuestion?.choices.map((choice, index) => (
                            <option
                              key={choice.choiceId}
                              value={choice.choiceId}
                            >
                              {choice.value}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    ) : null}
                    {
                      <Alert
                        className="alert_questionaire"
                        show={error}
                        dismissible
                        onClose={() => setError("")}
                        variant="danger"
                        id={`err_1`}
                      >
                        {error}
                      </Alert>
                    }

                    {selectFilter != "" && (
                      <Form.Group className="suggestion_addfilter" as={Col}>
                        <Button
                          onClick={() => onAddFilter()}
                          className="add-filter-btn"
                          variant="success"
                        >
                          {t("suggestions.addfilter")}
                        </Button>
                      </Form.Group>
                    )}
                    {appliedFilters.length > 1 && (
                      <Form.Group className="suggestion_addfilter" as={Col}>
                        <Button
                          onClick={() => removeAllFilter()}
                          className="add-filter-btn"
                          variant="success"
                        >
                          Remove all
                        </Button>
                      </Form.Group>
                    )}
                    <div className="flex">
                      <div className="text_1 ">
                        <div className="text-left remove_filter_suggestion">
                          {searchValue?.name ? (
                            <>
                              <b>Alias : {searchValue?.name}</b>
                              &nbsp;
                              <span
                                tabindex="0"
                                aria-label="Delete alias filter"
                              >
                                <FontAwesomeIcon
                                  className="trash"
                                  icon={faTrash}
                                  onClick={() => onDeleteFilter("1")}
                                />
                              </span>
                            </>
                          ) : null}
                        </div>
                        <div className="text-left remove_filter_suggestion">
                          {searchValue?.freeText ? (
                            <>
                              <b>Free Text : {searchValue?.freeText}</b>
                              &nbsp;
                              <span
                                tabindex="0"
                                aria-label="Delete free text filter"
                              >
                                <FontAwesomeIcon
                                  className="trash"
                                  icon={faTrash}
                                  onClick={() => onDeleteFilter("16")}
                                />
                              </span>
                            </>
                          ) : null}
                        </div>
                        <div className="text-left remove_filter_suggestion">
                          {searchValue?.region ? (
                            <>
                              <b>Region : {searchValue?.region}</b>
                              &nbsp;
                              <span
                                tabindex="0"
                                aria-label="Delete Region filter"
                              >
                                <FontAwesomeIcon
                                  className="trash"
                                  icon={faTrash}
                                  onClick={() => onDeleteFilter("10")}
                                />
                              </span>
                            </>
                          ) : null}
                        </div>
                        <div className="text-left remove_filter_suggestion">
                          {searchValue?.age ? (
                            <>
                              <b>Age : {searchValue?.age}</b>
                              &nbsp;
                              <span
                                tabindex="0"
                                aria-label="Delete Region filter"
                              >
                                <FontAwesomeIcon
                                  className="trash"
                                  icon={faTrash}
                                  onClick={() => onDeleteFilter("11")}
                                />
                              </span>
                            </>
                          ) : null}
                        </div>
                        <div className="text-left remove_filter_suggestion">
                          {searchValue?.sex ? (
                            <>
                              <b>Sex : {searchValue?.sex}</b>
                              &nbsp;
                              <span
                                tabindex="0"
                                aria-label="Delete Region filter"
                              >
                                <FontAwesomeIcon
                                  className="trash"
                                  icon={faTrash}
                                  onClick={() => onDeleteFilter("12")}
                                />
                              </span>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="sugn_acdn_hdr">
                    {" "}
                    {t("suggestions.additionalfilter")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row className="addtional_filtor_r1">
                      <Form.Group
                        as={Col}
                        className="suggestion_filter_checkbox"
                      >
                        <Form.Check
                          className="msg_pre_checkbox"
                          type="checkbox"
                          value="9"
                          onChange={advanceFilter}
                          id="formGridUnreadSuggest"
                          label="Unread suggestions"
                        />
                        <img src={new_icon} alt="new_icon" />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        className="suggestion_filter_checkbox"
                      >
                        <Form.Check
                          value="17"
                          onChange={advanceFilter}
                          className="msg_pre_checkbox"
                          type="checkbox"
                          id="formGridOnlyInterested"
                          label="Only interested in me"
                        />
                        <img src={icon_affiliation} alt="icon_affiliation" />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        className="suggestion_filter_checkbox"
                      >
                        <Form.Check
                          className="msg_pre_checkbox"
                          type="checkbox"
                          value="15"
                          onChange={advanceFilter}
                          id="formGridDefineFoot"
                          label="Define footprints"
                        />
                        <img
                          src={icon_foot_vergeben}
                          alt="icon_foot_vergeben"
                        />
                      </Form.Group>
                    </Row>
                    <Row className="addtional_filtor_r2">
                      <Form.Group
                        as={Col}
                        className="suggestion_filter_checkbox"
                      >
                        <Form.Check
                          className="msg_pre_checkbox"
                          type="checkbox"
                          value="14"
                          onChange={advanceFilter}
                          id="formGridReceiveFoot"
                          label="Receive footprints"
                        />
                        <img
                          src={icon_foot_bekommen}
                          alt="icon_foot_bekommen"
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        className="suggestion_filter_checkbox"
                      >
                        <Form.Check
                          className="msg_pre_checkbox"
                          type="checkbox"
                          value="23"
                          onChange={advanceFilter}
                          id="formGridCommunityCon"
                          label="Community contacts"
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        className="suggestion_filter_checkbox"
                      >
                        <Form.Check
                          className="msg_pre_checkbox"
                          type="checkbox"
                          checked={hideUnavailable}
                          onChange={isAvailable}
                          id="formGridHideUnavailable"
                          label="Hide unavailable"
                        />
                        <img src={user_icon} alt="user_icon" />
                      </Form.Group>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            {suggestions?.total == 0 && !isLoader ? (
              <Alert
                className="alert_questionaire"               
                variant="danger"
                id={`no_filter`}
              >
                {t("messenger.nouserfilter")}
              </Alert>
            ) : null}
            {isLoader ? (
              <div className="suggestion_loader">
                <Spinner />
              </div>
            ) : (
              <SuggestionCard
                setSuggestions={setSuggestions}
                suggestions={suggestions}
                onPagination={onPagination}
                paginateData={paginateData}
                totalPage={totalPage}
                recordPerPage={recordPerPage}
                selectPageCounts={selectPageCounts}
              />
            )}
          </div>
        </main>
      </div>
    );
}

export default Suggestions;
