import React, { useState, useContext } from 'react'
import GlkHeader from '../../components/Header/GlkHeader.jsx';
import './registration.css';
import { useTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ProgressBar from 'react-bootstrap/ProgressBar';
import preregistrationImg from "../../assets/images/preregistration.png";
import promise from "../../assets/images/promise.png";
import Aboutyou from './aboutyou';
import { validateAlias, validateEmail } from './preRegistrationService'
import AlertModal from '../commonComponents/alert.jsx';
import { autoFocusFirstElement, checkPasswordStrength } from '../commonMethods';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faCheck,
  faEyeSlash,
  faPhoneVolume,
  faSquarePhone,
  faEnvelope,
  faEarthEurope,
} from '@fortawesome/free-solid-svg-icons';
import { LoginContext } from '../../context/loginContext';

function Membership() {
  const [memberRegistration, setMemberRegistration] = useState({});
  const [email, setEmail] = useState({
    isEmailValid: false,
    emailErrorMessage: '',
    isConfirmEmail: false,
    confirmEmailMsg: '',
  });
  const [password, setPassword] = useState({
    userPassword: '',
    passwordErrorMsg: '',
    passwordData: null,
    showPassword: false,
    isPassword: false,
    confirmPassErrorMsg: '',
    isConfirmPassword: false,
    showConfirmPassword: false
  })
  const [isgotoAboutyou, setIsgotoAboutyou] = useState(true)
  const [alias, setAlias] = useState({ isAliasValid: false, aliasErrorMessage: '' });

  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const EMAIL_REG_EX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  let {
    dispatch
  } = useContext(LoginContext);

  const handleChangeLanguage = (value) => {
    i18n.changeLanguage(value);
    dispatch({ type: 'SET_LANGUAGE', data: value });
  }

  const validateData = async (value) => {
    if (value.email !== value.confirmEmail) {
      setEmail((prev) => ({ ...prev, isConfirmEmail: true, confirmEmailMsg: t('member.preregistration.labels.emailNotMatch') }))
      return;
    } else {
      setEmail((prev) => ({ ...prev, isConfirmEmail: false, isEmailValid: false }))
    }

    if (value.password !== value.confirmPassword) {
      setPassword((prevState) => ({
        ...prevState,
        isConfirmPassword: true,
        confirmPassErrorMsg: t(`member.preregistration.errors.confirmPwdBody`)
      }))
      return;
    } else {
      setPassword((prevState) => ({
        ...prevState,
        isPassword: false,
        isConfirmPassword: false
      }))
    }

    if (value.alias == null || value.alias == '') {
      setAlias((prevState) => ({ ...prevState, isAliasValid: true, aliasErrorMessage: t(`member.preregistration.errors.aliasBody`) }))
      return;
    } else {
      setAlias((prevState) => ({ ...prevState, isAliasValid: false, aliasErrorMessage: '' }))
    }

    if (validateField(`password`, value.password) === true) {
      setPassword((prevState) => ({
        ...prevState,
        isPassword: true,
        passwordErrorMsg: t(`member.preregistration.errors.passBody`)
      }))
      return;
    }

    const finalData = {
      body: value,
      language: currentLang
    }
    const emailResult = await validateEmail(finalData)
    const aliasResult = await validateAlias(finalData)

    if (emailResult?.status !== 200) {
      setEmail((prev) => ({ ...prev, isEmailValid: false }))
    }
    else if (emailResult.data.emailValid === false) {
      setEmail((prev) => ({ ...prev, isEmailValid: !emailResult?.data.emailValid, emailErrorMessage: emailResult?.data?.errorMessages?.[0] }))
    }
    if (aliasResult?.status !== 200) {
      setAlias((prevState) => ({ ...prevState, isAliasValid: false }))
    }
    else if (aliasResult.data.aliasValid === false) {
      setAlias((prevState) => ({ ...prevState, isAliasValid: !aliasResult?.data.aliasValid, aliasErrorMessage: aliasResult?.data?.errorMessages?.[0] }))
    }
    return { emailValid: emailResult?.data.emailValid, aliasValid: aliasResult?.data.aliasValid, status: (emailResult?.data.emailValid && aliasResult?.data.aliasValid) }
  }

  const validatePassword = async (value) => {
    const pwdMetaData = await checkPasswordStrength(value, t)
    setPassword((prevState) => ({
      ...prevState,
      passwordData: pwdMetaData
    }))
    return pwdMetaData.strength >= 3
  }

  const validateField = (fieldName, value) => {
    const emailValue = value || "";
    switch (fieldName) {
      case `email`:
        return EMAIL_REG_EX.test(emailValue);
      case `alias`:
        return EMAIL_REG_EX.test(emailValue);
      case `password`:
        return EMAIL_REG_EX.test(emailValue);
      default:
        return false;
    }
  }

  const validateWithTAB = async (evt) => {
    let isError = null;
    if (evt.type === 'keydown' && evt.keyCode === 9 && evt.shiftKey !== true) {
      if (evt.target.id === 'email') isError = handleEmail(evt);
      if (evt.target.id === 'confirmEmail') isError = await handleConfirmEmail(evt);
      if (evt.target.id === 'alias') isError = await handleAlias(evt);
      if (evt.target.id === 'newpassword') isError = await handlePassword(evt);
      if (evt.target.id === 'confirmpassword') isError = handleConfirmPassword(evt);
      if (evt.target.value === '' || isError) evt.preventDefault();
    }
  }

  const handleEmail = (e) => {
    if (!e.target.value) {
      setEmail((prev) => ({ ...prev, isEmailValid: true, emailErrorMessage: t('member.preregistration.labels.email') }))
      return true;
    } else {
      memberRegistration[`email`] = e.target.value;
      setMemberRegistration({ ...memberRegistration });
      if (validateField(`email`, e.target.value)) {
        setEmail((prev) => ({ ...prev, isEmailValid: false, emailErrorMessage: '' }))
        return false;
      } else {
        setEmail((prev) => ({ ...prev, isEmailValid: true, emailErrorMessage: t('member.preregistration.labels.invalidEmail') }))
        return true;
      }
    }
  }

  const handleConfirmEmail = async (e) => {
    if (!e.target.value) {
      setEmail((prev) => ({ ...prev, isConfirmEmail: true, confirmEmailMsg: t('member.preregistration.labels.emailRepeat') }))
      return true;
    } else {
      memberRegistration[`confirmEmail`] = e.target.value;
      setMemberRegistration({ ...memberRegistration })
      if (memberRegistration[`email`] !== memberRegistration[`confirmEmail`]) {
        setEmail((prev) => ({ ...prev, isConfirmEmail: true, confirmEmailMsg: t('member.preregistration.labels.emailNotMatch') }))
        return true;
      } else {
        const finalData = {
          body: memberRegistration,
          language: currentLang
        }
        const emailResult = await validateEmail(finalData)
        if (emailResult.status !== 200 || emailResult.data?.emailValid !== true) {
          setEmail((prev) => ({ ...prev, isEmailValid: !emailResult?.data.emailValid, emailErrorMessage: emailResult?.data?.errorMessages?.[0] }))
          return true;
        }
        setEmail((prev) => ({ ...prev, isConfirmEmail: false, confirmEmailMsg: '' }))
        return false;
      }
    }
  }

  const handleAlias = async (e) => {
    const aliasValue = e.target.value.trim();
    memberRegistration[`alias`] = aliasValue || '';

    if (aliasValue !== '' && validateField(`alias`, aliasValue) === true) {
      setAlias((prevState) => ({ ...prevState, isAliasValid: true, aliasErrorMessage: t(`member.preregistration.errors.aliasBody`) }))
      return true;
    }
    if (aliasValue.length <= 3) {
      setAlias((prevState) => ({ ...prevState, isAliasValid: true, aliasErrorMessage: t(`member.preregistration.errors.aliasMinCharBody`) }))
      return true;
    }
    if (aliasValue.length >= 3 && validateField(`alias`, aliasValue) === false) {
      setAlias((prevState) => ({ ...prevState, isAliasValid: false, aliasErrorMessage: '' }))
      memberRegistration[`alias`] = aliasValue;
      setMemberRegistration({ ...memberRegistration })
      const finalData = {
        body: memberRegistration,
        language: currentLang
      }
      const rsValid = await validateAlias(finalData);
      if (rsValid.data.aliasValid === false) {
        setAlias((prevState) => ({ ...prevState, isAliasValid: !rsValid?.data.aliasValid, aliasErrorMessage: rsValid?.data?.errorMessages?.[0] }))
        return true
      }
    } else {
      memberRegistration[`alias`] = null;
      setMemberRegistration({ ...memberRegistration })
    }
    return false;
  }

  const handlePassword = async (e) => {
    setPassword((prevState) => ({
      ...prevState,
      userPassword: e.target.value,
    }))
    if (e.target.value == '') {
      setPassword((prevState) => ({
        ...prevState,
        isPassword: true,
        passwordErrorMsg: t('member.preregistration.errors.pwdBody')
      }))
      return true;
    }
    if (memberRegistration[`confirmPassword`] !== undefined && (e.target.value !== memberRegistration[`confirmPassword`])) {
      setPassword((prevState) => ({
        ...prevState,
        isConfirmPassword: true,
        confirmPassErrorMsg: t(`member.preregistration.errors.confirmPwdBody`)
      }))
    } else {
      setPassword((prevState) => ({
        ...prevState,
        isConfirmPassword: false,
        confirmPassErrorMsg: ''
      }))
    }
    const isPasswordValid = await validatePassword(e.target.value)
    if (isPasswordValid) {
      memberRegistration[`password`] = e.target.value;
      setMemberRegistration({ ...memberRegistration })
      if (validateField(`password`, e.target.value) === true) {
        setPassword((prevState) => ({
          ...prevState,
          isPassword: true,
          passwordErrorMsg: t(`member.preregistration.errors.passBody`)
        }))
        return true;
      } else {
        setPassword((prevState) => ({
          ...prevState,
          isPassword: false,
          passwordErrorMsg: ''
        }))
        return false;
      }
    } else {
      setPassword((prevState) => ({
        ...prevState,
        isPassword: true,
        passwordErrorMsg: t('member.preregistration.errors.pwdBody')
      }))
      return true;
    }
  }

  const handleConfirmPassword = (e) => {
    if (password?.userPassword && e.target.value == '') {
      setPassword((prevState) => ({
        ...prevState,
        isConfirmPassword: true,
        confirmPassErrorMsg: t('member.preregistration.labels.confirmPassword')
      }))
      return true;
    }
    else {
      setPassword((prevState) => ({
        ...prevState,
        isConfirmPassword: false,
      }))
      memberRegistration[`confirmPassword`] = e.target.value;
      setMemberRegistration({ ...memberRegistration })
      if (password?.userPassword !== memberRegistration[`confirmPassword`]) {
        setPassword((prevState) => ({
          ...prevState,
          isConfirmPassword: true,
          confirmPassErrorMsg: t(`member.preregistration.errors.confirmPwdBody`)
        }))
        return true;
      } else {
        setPassword((prevState) => ({
          ...prevState,
          isConfirmPassword: false,
          confirmPassErrorMsg: ''
        }))
        return false;
      }
    }
  }

  const next = async () => {
    if (!memberRegistration.email) handleEmail({ target: { value: (memberRegistration[`email`] ? memberRegistration[`email`] : ''), id: 'email' } });
    if (!memberRegistration.confirmEmail) handleConfirmEmail({ target: { value: (memberRegistration[`confirmEmail`] ? memberRegistration[`confirmEmail`] : ''), id: 'confirmEmail' } });
    if (!memberRegistration.alias) handleAlias({ target: { value: (memberRegistration[`alias`] ? memberRegistration[`alias`] : ''), id: 'alias' } });
    if (!memberRegistration.password) handlePassword({ target: { value: (memberRegistration[`password`] ? memberRegistration[`password`] : ''), id: 'newpassword' } });
    if (!memberRegistration.confirmPassword) handleConfirmPassword({ target: { value: (memberRegistration[`confirmPassword`] ? memberRegistration[`confirmPassword`] : ''), id: 'confirmpassword' } });
    if (memberRegistration.confirmPassword == memberRegistration.alias && memberRegistration.password == memberRegistration.alias) {
      setPassword((prevState) => ({
        ...prevState,
        isPassword: true,
        passwordErrorMsg: t('member.preregistration.errors.aliaspassmatch')
      }))
      return;
    }

    if (memberRegistration.email && memberRegistration.confirmEmail && (memberRegistration.confirmEmail === memberRegistration.email) && memberRegistration.alias && memberRegistration.password && memberRegistration.confirmPassword && (memberRegistration.confirmPassword === memberRegistration.password)) {
      const isValidData = await validateData(memberRegistration);
      if (isValidData?.status) {
        setIsgotoAboutyou(false);
      } else {
        setIsgotoAboutyou(true);
      }
      autoFocusFirstElement();
    } else {
      autoFocusFirstElement();
    }
  };

  function setIsConfirmPassword() {
    setPassword({
      ...password,
      showPassword: false
    })
  }

  function setIsPassword() {
    setPassword({
      ...password,
      isPassword: false
    })
  }

  function setIsEmailValid() {
    setEmail((prev) => ({ ...prev, isEmailValid: false }))
  }

  function setIsConfirmEmail() {
    setEmail((prev) => ({ ...prev, isConfirmEmail: false }))
  }

  function setIsAliasValid() {
    setAlias((prevState) => ({ ...prevState, isAliasValid: false }))
  }

  return (
    <div className="preRegistration">
      <GlkHeader />
      <main className='main_wrapper preregistration_wrapper'>
        <div className="banner_wrapper">
          <div className="banner_container">
            <div className='banner_content'>
              <div className="banner_description">
                <h1 className='registration_label'>{t('member.registration.registration')}</h1>
                <p><strong>{t('member.registration.h2label')}</strong></p>
                <p>{t('member.registration.plabel')}</p>
                <p>{t('member.registration.plabel2')}</p>
              </div>
            </div>
            <div className="banner_image">
              <img src={preregistrationImg} alt={t('member.registration.registration')} />
            </div>
          </div>
        </div>
        <div className="questionaire_in_menubar">
          <div className="container-fluid registration_steps">
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="step_box one">
                  <div className="step_headline">
                    <div className="step_count">
                      <span aria-label="Information 1">1</span>
                    </div>
                    <h2>{t('member.preregistration.registerHeading')}</h2>
                  </div>
                  <p>
                    {t('member.preregistration.registerSubheading')}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="step_box two">
                  <div className="step_headline">
                    <div className="step_count">
                      <span aria-label="Information 2">2</span>
                    </div>
                    <h2>{t('member.preregistration.profileHeading')}</h2>
                  </div>
                  <p>
                    {t('member.preregistration.profileSubheading')}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="step_box three">
                  <div className="step_headline">
                    <div className="step_count">
                      <span aria-label="Information 3">3</span>
                    </div>
                    <h2>{t('member.preregistration.finalizeHeading')}</h2>
                  </div>
                  <p>
                    {t('member.preregistration.finalizeSubheading')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {isgotoAboutyou ?
            <div className="form_row">
              <div className="pre-register-new">
                <div className="col-12">
                  <img src={promise} alt={t('member.community.imageFor') + " " + t('member.preregistration.promiseHeading')} />
                  <h3>{t('member.preregistration.promiseHeading')}</h3>
                </div>
                <div className="promiseInfo">
                  <div className="col-12">
                    <p><strong>{t('member.preregistration.promiseSubheading')}</strong></p>
                    <p><FontAwesomeIcon icon={faCheck} /> <span>{t('member.preregistration.registerSubText')}</span></p>
                  </div>
                  <div className="col-12">
                    <p><FontAwesomeIcon icon={faCheck} /> <span>{t('member.preregistration.profileSubText')}</span></p>
                  </div>
                  <div className="col-12">
                    <p><FontAwesomeIcon icon={faCheck} /> <span>{t('member.preregistration.finalizeSubText')}</span></p>
                  </div>
                </div>
              </div>
              <div className='membership_account'>
                <div className="reg_stepone">
                  <h3 className="lineH3 text-center"><strong>{t('member.registration.registration')}</strong> &ndash; {t('member.preregistration.step1of3')}</h3>
                  <h4>{t('member.preregistration.accountHeader')}</h4>
                </div>
                <Form autoComplete="off" className='step_one_form'>
                  <Form.Label className="mt-3" htmlFor="email">{t('member.preregistration.email')}*</Form.Label>
                  <Form.Control autoComplete="new-email"
                    type="text"
                    id="email"
                    className={email?.isEmailValid && "is-invalid"}
                    autoFocus={true}
                    aria-label={email?.emailErrorMessage ? email?.emailErrorMessage : t('member.preregistration.labels.email')}
                    onBlur={(e) => handleEmail(e)}
                    onKeyDown={(e) => {
                      validateWithTAB(e);
                    }}
                  />
                  <AlertModal className={'mt-3'} show={email?.isEmailValid} setShow={setIsEmailValid} heading={t('member.preregistration.errors.email')} body={email?.emailErrorMessage}></AlertModal>

                  <Form.Label className="mt-3" htmlFor="confirmEmail">{t('member.preregistration.repeatEmail')}*</Form.Label>
                  <Form.Control autoComplete="new-email"
                    type="text"
                    id="confirmEmail"
                    className={email?.isConfirmEmail && "is-invalid"}
                    aria-label={email?.confirmEmailMsg ? email?.confirmEmailMsg : t('member.preregistration.labels.emailRepeat')}
                    onBlur={(e) => handleConfirmEmail(e)}
                    onKeyDown={(e) => {
                      validateWithTAB(e);
                    }}
                  />
                  <AlertModal className={'mt-3'} show={email?.isConfirmEmail} setShow={setIsConfirmEmail} heading={'Error'} body={email?.confirmEmailMsg}></AlertModal>

                  <Form.Group className="mt-3" as={Col} >
                    <Form.Label htmlFor="alias" aria-hidden="true">{t('member.preregistration.alias')}*</Form.Label>
                    <Form.Control
                      type="text"
                      id="alias"
                      className={alias?.isAliasValid && "is-invalid"}
                      aria-label={alias?.aliasErrorMessage ? alias?.aliasErrorMessage : t('member.preregistration.labels.alais')}
                      autoComplete="new-alias"
                      onBlur={(e) => handleAlias(e)}
                      onKeyDown={(e) => {
                        validateWithTAB(e);
                      }}
                    />
                  </Form.Group>
                  <AlertModal className={'mt-3'} show={alias?.isAliasValid} setShow={setIsAliasValid} heading={t('member.preregistration.errors.alias')} body={alias?.aliasErrorMessage}></AlertModal>

                  <Form.Group className="position-relative" as={Col} >
                    <Form.Label className="mt-3" htmlFor="newpassword">{t('member.preregistration.pwd')}*</Form.Label>
                    <Form.Control autoComplete="new-password"
                      type={password?.showPassword ? "text" : "password"}
                      id="newpassword"
                      className={password?.isPassword && "is-invalid"}
                      aria-label={password?.passwordErrorMsg ? password?.passwordErrorMsg : t('member.preregistration.labels.password')}
                      onChange={(e) => handlePassword(e)}
                      onKeyDown={(e) => {
                        validateWithTAB(e);
                      }}
                    />
                    <span onClick={() => setPassword({ ...password, showPassword: !password?.showPassword })
                    } className={`showPasswordIcon_preReg ${password?.isPassword ? "me-4" : ""}`}>
                      <FontAwesomeIcon icon={password?.showPassword ? faEyeSlash : faEye} />
                    </span>
                  </Form.Group>
                  <AlertModal className={'mt-3'} show={password?.isPassword} setShow={setIsPassword} heading={'Error'} body={password?.passwordErrorMsg}></AlertModal>

                  <div className='progess_bar'>
                    <ProgressBar
                      className='reg_progress_bar'
                      now={password?.passwordData ? (password?.passwordData.strength / 5) * 100 : 0}
                      variant={password?.passwordData ? password?.passwordData.varient : "danger"}
                      label={password?.passwordData?.label}
                      tabIndex="-1"
                      striped
                      aria-label={t('member.preregistration.labels.passwordIs') + " " + password?.passwordData?.label}
                    >
                    </ProgressBar>
                    <p>{t('member.preregistration.pstrength')}</p>
                  </div>

                  <Form.Group className="position-relative" as={Col}>
                    <Form.Label className="mt-3" htmlFor="confirmpassword">{t('member.preregistration.confirmPwd')}*</Form.Label>
                    <Form.Control autoComplete="new-password"
                      type={password?.showConfirmPassword ? "text" : "password"}
                      id="confirmpassword"
                      className={password?.isConfirmPassword && "is-invalid"}
                      aria-label={password?.confirmPassErrorMsg ? password?.confirmPassErrorMsg : t('member.preregistration.labels.confirmPassword')}
                      onChange={(e) => handleConfirmPassword(e)}
                      onKeyDown={(e) => {
                        validateWithTAB(e);
                      }}
                    />
                    <span onClick={() => setPassword({ ...password, showConfirmPassword: !password.showConfirmPassword })}
                      className={`showPasswordIcon_preReg ${password?.isConfirmPassword ? "me-4" : ""}`}>
                      <FontAwesomeIcon icon={password?.showConfirmPassword ? faEyeSlash : faEye} />
                    </span>
                  </Form.Group>
                  <AlertModal className={'mt-3'} show={password?.isConfirmPassword} setShow={setIsConfirmPassword} heading={'Error'} body={password?.confirmPassErrorMsg}></AlertModal>

                  <div className="mt-3 step_one_next">
                    <Button className='mx-5' variant="primary"
                      onClick={() => next()}>
                      {t('member.preregistration.nextButton')}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            :
            <Aboutyou memberRegistration={memberRegistration} setMemberRegistration={setMemberRegistration} />}
        </div>
      </main>
      <footer className='app_footer'>
        <div className='footer_details'>
          <a className='text_white imprint_label' href="https://www.gleichklang.de/impressum/">{t("member.footer.imprint")}</a>
          <p className='text_white'>  Gleichklang limited   Oesterleystr. 1   D-30171 Hannover</p>
          <div className="text_white glk_contact_details_container">
            <div className="contact_number_free">
              <div className='contact_numberAndicon'>
                <div className='for_icon_circle'>
                  <FontAwesomeIcon icon={faPhoneVolume} />
                </div>
                <a className="text_white" href="tel: +49 (0)511 51516660" aria-label="call on 49 (0)511 51516660">+49 (0)511 51516660</a>
              </div>
              <span className="for_font_size">{t('member.footer.contactTime')}</span>
            </div>
            <div className="contact_number_paid">
              <div className='contact_numberAndicon'>
                <div className='for_icon_circle'>
                  <FontAwesomeIcon icon={faSquarePhone} />
                </div>
                <a className="text_white" href="tel: +49 (0) 1805 - 7772 3332" aria-label="Call on +49 (0) 1805 - 7772 3332"> +49 (0) 1805 - 7772 3332 </a>
              </div>
              <span className="for_font_size">(0.14 €/min)</span>
            </div>
            <div className="contact_email">
              <div className='contact_numberAndicon'>
                <div className='for_icon_circle'>
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <a className="text_white" href="mailto:anfragen@gleichklang.de" aria-label="mail to anfragen@gleichklang.de">anfragen@gleichklang.de</a>
              </div>
            </div>
            <div className="contact_website">
              <div className='contact_numberAndicon'>
                <div className='for_icon_circle'>
                  <FontAwesomeIcon icon={faEarthEurope} />
                </div>
                <a className="text_white" href="https://www.gleichklang.de">www.gleichklang.de</a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_select_lng">
          <div className='select_lag'>
            <select
              value={currentLang}
              aria-label={t('member.footer.selectLang')}
              id="language1"
              onChange={(e) => handleChangeLanguage(e.target.value)}
            >
              <option value='en'>English</option>
              <option value='de'>Deutsch</option>
            </select>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Membership;