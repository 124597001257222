import React, { useContext, useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { Row, Col, Form, Modal, Button, Spinner as BootStrapSpinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import UserIcon from '../../assets/images/user-icon01.png';
import noFootprint from '../../assets/images/no_footprint.png';
import { LoginContext } from '../../context/loginContext.jsx';
import {
    faChevronLeft,
    faTrashCan,
    faPen,
    faShareFromSquare,
    faExclamationCircle,
    faUserCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordion from 'react-bootstrap/Accordion';
import './suggestionCard.css';
import Spinner from '../../components/spinner.jsx';
import FootprintKey from './footprints.jsx';
import {
    getAvetar,
    getUserDetails,
    updateMemoText,
    updateFootprints,
    updateRelationship,
    getSuggestionMember,
    getSuggestionsGallery,
    getSuggestionsDataByCategory,
    communityContactsFreeText
} from './suggestionService.jsx';
import Player from '../../components/Audioplayer/Player.jsx';
import { fetchAudio } from '../personalDetails/personalDetailsService.jsx';
import DeleteSuggestions from './deleteSuggestions.jsx';
import heic2any from 'heic2any';
import SuggestionsGalleryImages from './suggestionsGalleryImages.jsx';
import ShareMyGallery from './shareMyGallery.jsx';
import ReportSuggestions from './reportSuggestions.jsx';
import Alert from 'react-bootstrap/Alert';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import ChatHistory from './messenger/chatHistory.jsx';
import useWebSocketComponent from './messenger/webSocket.jsx';
import FreeText from './messenger/freeText.jsx';

function SuggestionView(props) {
    const { sendMessage, userDetails, isLoading } = useWebSocketComponent();
    const [suggestionProfile, setSuggestionProfile] = useState();
    const [footprintType, setFootprintType] = useState('Define');
    const [isFootprintListOpen, setIsFootprintListOpen] = useState(false);
    const [currentSuggestionId, setCurrentSuggestionId] = useState();
    const [isSuggestionChange, setIsSuggestionChange] = useState(false);
    const [categoryData, setCategoryData] = useState();
    const [avatarUrl, setAvatarUrl] = useState('');
    const [audio, setAudio] = useState(null);
    const [memoText, setMemoText] = useState(null);
    const [affiliationType, setAffiliationType] = useState();
    const [isSuggestionDelete, setIsSuggestionDelete] = useState(false);
    const [suggestionGalleryImages, setSuggestionGalleryImages] = useState([]);
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
    const [isGalleryLoading, setIsGalleryLoading] = useState(false);
    const [isShareSuggestions, setIsShareSuggestions] = useState(false);
    const [isReportAbuse, setIsReportAbuse] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [category, setCategory] = useState();
    const [currentUserDetail, setCurrentUserDetail] = useState({});
    const [currentAvatar, setCurrentAvatar] = useState('');
    const [recordsRemaining, setRecordsRemaining] = useState(true);
    const [attachmentFiles, setAttachmentFiles] = useState([]);
    const [users, setUsers] = useState([]);
    const [chats, setChats] = useState([]);
    const [userDetail, setUserDetail] = useState()
    const [chatHistoryModel, setChatHistoryModel] = useState(false)
    const [isMessageError, setIsMessageError] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [userId, setUserId] = useState(null);
    const [isFileLoader, setIsFileLoader] = useState(false)
    const [isOpenMessage, setIsOpenMessage] = useState(false);
    const [isFileResponseReceived, setIsFileResponseReceived] = useState(false);
    const [loadingAudio, setLoadingAudio] = useState(false);
    const [freeText, setFreeText] = useState({open: false, value: []});
    const [isContactList, setIsContactList] = useState(false);  

    const {
        show,
        avatars,
        setAvatars,
        handleClose,
        isParentSuggestion,
        suggestion,
        base64Image,
        setSuggestions
    } = props;

    let {
        loginData: {
            accessToken
        },
    } = useContext(LoginContext);

    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;
    const token_lang = {
        language: currentLang,
        accessToken
    };

    useEffect(() => {
        let base64Url = accessToken?.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        setUserId(JSON.parse(jsonPayload).user_id)
    }, [])

    const onGetUserDetails = (item) => {
        const getUsers = {
            type: "AUTH",
            token: accessToken,
        };
        sendMessage(JSON.stringify(getUsers));
        setCurrentUserDetail(item?.name);
        getUserDetails(token_lang, item?.name)
            .then((res) => {
                if (res?.status === 200) {
                    setUserDetail({
                        ...res.data,
                        alias: item?.name,
                        avatarId: categoryData?.profileData?.avatar, 
                        sex: {
                            value: item?.sex?.value
                        },
                        address: item.addressList &&
                            item.addressList.map(
                                (item2) => {
                                    let addr = '';
                                    if (item2.city !== undefined) {
                                        addr += item2.city + ", ";
                                    }
                                    if (item2.country !== undefined) {
                                        addr += item2.country.value + ", ";
                                    }
                                    if (item2.zip !== undefined) {
                                        addr += item2.zip.value;
                                    }
                                    return addr;
                                }
                            )
                    });
                    setChatHistoryModel(true);

                }
            })
            .catch((error) => {
                console.error('Error fetching user details:', error);
            });
    };

    useEffect(() => {
        setChats([]);
    }, [currentUserDetail]);

    useEffect(() => {
        if (userDetails?.fileType === 'IMAGE' || userDetails?.fileType === 'FILE') {
            setAttachmentFiles((prevAttachments) => [userDetails, ...prevAttachments]);
        }

        if (userDetails?.subType === 'CHAT_HISTORY' || userDetails?.type === "TEXT_MESSAGE" || userDetails?.type === "FILE_MESSAGE") {
            if (userDetails?.subType === "CHAT_HISTORY") {
                setRecordsRemaining(userDetails?.recordsRemaining);
            }
            setIsFileLoader(false);

            if (userDetails?.error) {
                setIsMessageError(true)
                setIsFileLoader(false);
            }
            const newMessages =
                userDetails?.type === "TEXT_MESSAGE" || userDetails?.type === "FILE_MESSAGE"
                    ? [userDetails]
                    : userDetails?.messages;
            setChats((prevMessages) => {
                const isChatHistory = userDetails?.subType === "CHAT_HISTORY";
                const currentUserId = userId;
                const newMessage = newMessages[0];

                const isCurrentUserInvolved = newMessage && (currentUserId === newMessage.receiverId || currentUserId === newMessage.senderId);

                if (isChatHistory) {
                    return [...new Set([...prevMessages, ...newMessages])];
                } else if (isCurrentUserInvolved) {
                    return [...new Set([...newMessages, ...prevMessages])];
                } else {
                    const additionalMessages = userDetails?.messages || [];
                    return [...new Set([...prevMessages, ...additionalMessages])];
                }
            });
        }
        if (userDetails?.subType === "DELETE") {
            setChats((prevMessages) =>
                prevMessages.filter((msg) => msg.id != userDetails.messageId)
            );
        }
    }, [userDetails]);


    const fetchChatHistory = (pageNo) => {
        const message = {
            type: "API",
            subType: "CHAT_HISTORY",
            pageNo: pageNo,
            targetUserAlias: currentUserDetail,
            pageSize: 20,
        };
        if (currentUserDetail?.length) {
            sendMessage(JSON.stringify(message));
        }
    };

    useEffect(() => {
        fetchChatHistory(pageNo);
    }, [pageNo, currentUserDetail]);

    const chatContainerRef = useRef(null);
    const lastContactElementRef = useCallback(
        (node) => {
            if (chatContainerRef.current) chatContainerRef.current.disconnect();
            chatContainerRef.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && recordsRemaining) {
                    setPageNo((prevMessagePage) => prevMessagePage + 1);
                }
            });
            if (node) chatContainerRef.current.observe(node);
        },
        [recordsRemaining]
    );

    const chatOverviewRef = useRef(null);
    const lastOverviewElementRef = useCallback(
      (node) => {
        if (chatOverviewRef.current) chatOverviewRef.current.disconnect();
        chatOverviewRef.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && recordsRemaining) {
              setPageNo((prevMessagePage) => prevMessagePage + 1);
          }
        });
        if (node) chatOverviewRef.current.observe(node);
      },
      [recordsRemaining]
    );

    useEffect(() => {
        getSuggestionMember(token_lang, suggestion.name).then((response) => {
            if (response?.status === 200) {
                setSuggestionProfile(response.data);
                setMemoText(response.data.memoText);
                setAffiliationType(response.data.sourceUserAffiliation?.key)
            }
            else {
                setIsError(true);
                setErrorMessage(response?.data?.message);
            }
        })
    }, [isSuggestionChange])

    const avtar = async (id) => {
        try {
            const result = await getAvetar(token_lang, id);
            if (result?.status === 200) {
                const blob = await result.blob();
                const fileURL = URL.createObjectURL(blob);
                return fileURL;
            }
        } catch (error) {
            console.error('Error fetching avatar:', error);
        }
        return '';
    };

    useEffect(() => {
        if (categoryData?.profileData?.avatar && !isParentSuggestion) {
            avtar(categoryData.profileData.avatar).then((url) => {
                setAvatars({ [categoryData.profileData.avatar]: url });
            });
        }
    }, [categoryData]);

    useEffect(() => {
        if (categoryData && categoryData?.profileData?.audio !== null) {
            const data = {
                accessToken: accessToken,
                language: currentLang,
                audioId: categoryData?.profileData?.audio
            }
            if (data.audioId !== null) { setLoadingAudio(true) }
            fetchAudio(data).then(async (response) => { 
                if (response?.status === 200) {
                    const blob = await response.blob();
                    const fileURL = URL.createObjectURL(blob);
                    setAudio(fileURL)
                }
                setLoadingAudio(false)
            })
        }
    }, [categoryData])

    const defineFootprint = () => {
        setFootprintType('Received')
    }

    const receivedFootprint = () => {
        setFootprintType('Define')
    }

    const sendOrDeleteFootprint = (id, isdelete, footprintKey) => {
        const body = {
            "relationshipId": id,
            "delete": isdelete,
            "footprint": footprintKey
        }
        updateFootprints(token_lang, body).then((result) => {
            if (result?.status === 200) {
                setIsSuggestionChange(!isSuggestionChange);
                closeFootprintKey();
            }
            else {
                setIsError(true);
                setErrorMessage(result?.data?.message);
                closeFootprintKey();
            }
        })
    }

    const addMemoText = (e) => {
        const memoData = {
            relationshipId: suggestion.id,
            memo: e.target.value
        }
        updateMemoText(token_lang, memoData).then((result) => {
            if (result?.status === 200) {
                if (isParentSuggestion) {
                    setSuggestions(prevSuggestions => {
                        const updatedUsers = prevSuggestions.users.map(suggestion =>
                            suggestion.id === memoData.relationshipId ? { ...suggestion, memo: memoData.memo } : suggestion
                        );
                        return { ...prevSuggestions, users: updatedUsers };
                    });                    
                }
            } else {
                setIsError(true);
                setErrorMessage(result?.data?.message);
            }
        })
    }

    const changeFootprint = (suggestionId) => {
        setIsFootprintListOpen(true);
        setCurrentSuggestionId(suggestionId);
    }

    const closeFootprintKey = () => {
        setIsFootprintListOpen(false);
    }

    const onSelectCategory = (category) => {
        setCategory(category);
        if (category !== 'CONTACTLIST') {
            getSuggestionsDataByCategory(token_lang, category, suggestion.name).then((response) => {
                if (response?.status == 200) {
                    setCategoryData(response.data)
                } else {
                    setIsError(true);
                    setErrorMessage(response?.data?.message);
                }
            });
        } else {    
              const headers = {
                language: i18n.language,
                accessToken
              };  
              communityContactsFreeText(headers, suggestion?.user_id).then((response) => {
                if (response?.status === 200) {      
                  setFreeText({open: true, value: response.data});
                }
              })
            }
    };

    useEffect(() => {
        if (suggestion?.categories.length) {
            onSelectCategory(suggestion?.categories[0].key);
        }
    }, []);

    const changeRelationship = (affiliation, markViewed, isdelete) => {
        setAffiliationType(affiliation)
        const relationship = {
            relationshipId: suggestion.id,
            markViewed: markViewed,
            delete: isdelete,
            affiliation: affiliation
        }
        updateRelationship(token_lang, relationship).then((result) => {
            if (result?.status === 200) {
                setIsSuggestionDelete(false);
                if(isdelete === true) {
                    setSuggestions(prevSuggestions => {
                        const updatedUsers = prevSuggestions.users.map(prevSuggestion => 
                            prevSuggestion.id === suggestion.id ? { ...prevSuggestion, available: false, relationshipDeleted: true } : prevSuggestion
                        );
                        return { ...prevSuggestions, users: updatedUsers };
                    });
                    closeDeleteSuggestions();
                    handleClose()
                }
            } else {
                setIsError(true);
                setErrorMessage(result?.data?.message);
            }
        })
    }

    //mark suggestions as viewed
    useEffect(() => {
        const markViewed = true;
        const isdelete = false;
        if (!suggestion?.viewed) changeRelationship(suggestion?.affiliation?.key, markViewed, isdelete)
    }, [])

    const suggestionDelete = () => {
        setIsSuggestionDelete(true)
    }

    const closeDeleteSuggestions = () => {
        setIsSuggestionDelete(false)
    }

    useEffect(() => { 
        setIsContactList(suggestion?.contactList)
        if (suggestion?.category_messenger?.key !== undefined) {
            onSelectCategory(suggestion?.category_messenger?.key) 
        }        
     }, [])

    const categorySelector = useMemo(() => {        
        if (!suggestion?.categories || suggestion.categories.length === 0) {
            return null;
        }
    
        return (
            <div className={`category_in_openprofile ${category == 'CONTACTLIST' ? 'for_contact_list' : 'for_category'}`}>
                <span>{t('suggestions.showinformationfor')}</span>
                <Form.Group controlId="formGridState">
                    <Form.Select
                        onChange={(e) => onSelectCategory(e.target.value)}
                        value={category}
                    >
                        {suggestion.categories.map(category => (
                            <option key={category.key} value={category.key}>
                                {category.value}
                            </option>
                        ))}
                        {isContactList && <option value="CONTACTLIST">Contact List</option>}
                    </Form.Select>
                </Form.Group>
            </div>
        );
    }, [onSelectCategory]);    

    const openGallery = (gallery) => {
        setSuggestionGalleryImages([]);
        setIsGalleryLoading(true);
        const images = gallery?.mediaDTOS;
        if (images.length) {
            images.forEach(async (image) => {
                try {
                    const imageUrl = await onGetImage(image.id, gallery);
                    setSuggestionGalleryImages((prevImages) => [
                        ...prevImages,
                        { image, imageUrl, galleryName: gallery.name },
                    ]);
                    setIsGalleryLoading(false);
                } catch (error) {
                    console.error(`Error fetching image for ID ${image.id}:`, error);
                }
            });
        } else setIsGalleryLoading(false);
        setIsGalleryOpen(true);
    };

    const onGetImage = (imageId, gallery) => {
        return new Promise((resolve, reject) => {
            getSuggestionsGallery(token_lang, suggestion.name, gallery.id, imageId).then(async (response) => {
                if (response.status === 200) {
                    const blob = await response.blob();
                    let fileURL;
                    if (blob.type == 'image/heif') {
                        const jpegBlob = await heic2any({
                            blob: blob,
                            toType: 'image/jpeg',
                            quality: 1
                        });
                        fileURL = URL.createObjectURL(jpegBlob);
                    } else {
                        fileURL = URL.createObjectURL(blob);
                    }
                    resolve(fileURL);
                } else {
                    reject(new Error("Failed to get image."));
                }
            }).catch(error => {
                reject(error);
            });
        });
    };

    const closeImagePopup = () => {
        setIsGalleryOpen(false)
    }

    const closeShareSuggestions = () => {
        setIsShareSuggestions(false);
    }

    const closeReportSuggestions = () => {
        setIsReportAbuse(false)
    }

    return (
        <Modal className='openprofile' size='lg' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h4>
                        <FontAwesomeIcon icon={faUserCircle} />
                        {suggestion?.name}
                        {suggestion?.addressList[0].city && `, ${suggestion?.addressList[0].city}`}
                        {suggestion?.age && `, ${suggestion.age}`}
                        {suggestion?.sex.value && `, ${suggestion.sex.value}`}
                        {suggestion?.category_messenger !== undefined ? null : 
                        <div className='member_header suggestion_msgr'>
                            <div className='home_btns'>
                                <Button onClick={() => onGetUserDetails(suggestion)} className='msg_btn' variant="primary">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                    {t('member.menubar.messages')}
                                    {/* <Badge bg="warning">Message</Badge> */}
                                    <span className="visually-hidden">unread messages</span>
                                </Button>
                            </div>
                        </div>}
                    </h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <main className="main_wrapper open_profile_body">
                    <div className='free_text_wrapper'>
                        <div>
                            <div className={categoryData?.profileData?.avatar ? 'suggestion-card-profile' : ' suggestion-card-profile'}>
                                <img
                                    className="blur_backround"
                                    src={avatars?.[categoryData?.profileData.avatar] || UserIcon}
                                    alt="Avatar"
                                />
                            </div>
                            <div>
                                {audio && audio !== null ?
                                    <div className="openprofile_audio">
                                        <p>{categoryData?.profileData?.audioName}</p>
                                        <Player audioUrl={audio} />
                                    </div> : loadingAudio ?
                                        <div className='subscription_loader'> <BootStrapSpinner /></div> : null
                                }
                            </div>
                            <div className="relationship-popup-memo">
                                <div className="lines position-absolute">
                                </div>
                                <div className="bookLining">
                                    <div className="MultiBookLining">
                                        <span className="InnerBookLining"></span>
                                        <span className="InnerBookLining"></span>
                                        <span className="InnerBookLining"></span>
                                        <span className="InnerBookLining"></span>
                                        <span className="InnerBookLining"></span>
                                        <span className="InnerBookLining"></span>
                                        <span className="InnerBookLining"></span>
                                        <span className="InnerBookLining"></span>
                                        <span className="InnerBookLining"></span>
                                        <span className="InnerBookLining"></span>
                                    </div>
                                </div>
                                <div className="relationship-popup-memo-wrapper">
                                    <h5>{t('suggestions.mynotes')}</h5>
                                    <textarea
                                        onBlur={addMemoText}
                                        onChange={(e) => setMemoText(e.target.value)}
                                        id="w3review"
                                        name="w3review"
                                        rows="8"
                                        aria-label="My notes"
                                        value={memoText}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="categorybtn_freetext">
                            {categorySelector}
                            {category == 'CONTACTLIST' ? <div className="m-sug-description">
                                <FreeText ContactListFreeText={freeText?.value} /></div>
                                : null}                           
                            {category !== 'CONTACTLIST' ? 
                            <div className="m-sug-profie-content">
                                <div className="m-sug-description">
                                    {categoryData?.profileData?.status ? <div>
                                        <p className="des-heading">{t('suggestions.status')}</p>
                                        <p>{categoryData?.profileData?.status}</p>
                                    </div> : null}
                                    {categoryData?.profileData?.freeTextQuestionnaire
                                        ? <p className="des-heading" >{t('suggestions.free_text')}</p> 
                                        : <div className='subscription_loader'> <BootStrapSpinner /></div>}
                                    {categoryData?.profileData?.freeTextQuestionnaire?.questionGroups.map((items, index) => (
                                        index > 0 ?
                                            items.questions[0]?.choiceQuestion?.selectedChoices[0]?.value ?
                                                <p className="color ftextview">
                                                    <strong>{items.questions[0]?.choiceQuestion?.selectedChoices[0]?.value}</strong><br />
                                                    <span dangerouslySetInnerHTML={{ __html: items?.questions[1]?.textQuestion?.answer }}></span>
                                                </p> : null :
                                            <p className="color ftextview">
                                                <span dangerouslySetInnerHTML={{ __html: items?.questions[0]?.textQuestion?.answer }}></span>
                                            </p>
                                    ))}
                                </div>
                            </div> : null}
                        </div>
                    </div>
                    {category !== 'CONTACTLIST' ?
                    <div className="footprint_category_data">
                        <div className='footprint_in_openprofile'>
                            {footprintType === 'Received' ? (
                                <div>
                                    {suggestionProfile?.definedFootprint.image ?
                                        <img src={base64Image(suggestionProfile.definedFootprint.image)} alt="dFootprint" /> :
                                        <button
                                            onClick={() => changeFootprint(suggestion.id)}
                                            className='add_footprint_btn'>
                                            <div className='addfootprint_logo'>
                                                <span className="add_icon">
                                                </span>
                                            </div>{t('suggestions.addfootprint')}
                                        </button>
                                    }
                                    <p><b>{suggestionProfile?.definedFootprint.description?.split(",")[0]}</b><br />
                                        <small>{suggestionProfile?.definedFootprint.description?.split(",")[1]}</small></p>
                                </div>
                            ) : (
                                <div>
                                    {suggestionProfile?.receivedFootprint.image ? (
                                        <>
                                            <img src={base64Image(suggestionProfile.receivedFootprint.image)} alt="rFootprint" />
                                            <p><b>{suggestionProfile?.receivedFootprint.description?.split(",")[0]}</b><br />
                                                <small>{suggestionProfile?.receivedFootprint.description?.split(",")[1]}</small></p>
                                        </>) : (
                                        <>
                                            <img src={noFootprint} alt="rFootprint" />
                                            <p><b>{t('member.footprint.noFootprint')}</b></p>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className='footprint_btn_wrapper'>
                            {footprintType === 'Define' ?
                                <button onClick={() => defineFootprint()} className="btn define-footprint-btn">
                                    <span>
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                        {t('suggestions.definefootprint')}
                                    </span>
                                </button> : null}
                            {footprintType == 'Received' ?
                                <>
                                    <button onClick={() => receivedFootprint()} className="btn receive-footprint-btn">
                                        <span>
                                            <FontAwesomeIcon icon={faChevronLeft} />
                                            {t('suggestions.receivefootprint')}
                                        </span>
                                    </button>
                                    {suggestionProfile?.definedFootprint.image ?
                                        <div className="added-foot-edit">
                                            <div className="change-footprint" aria-label="Change footprint">
                                                <button onClick={() => changeFootprint(suggestion.id)}>
                                                    <FontAwesomeIcon icon={faPen} />
                                                    <span>{t('suggestions.changefootprint')}</span>
                                                </button>
                                            </div>
                                            <div className="Delete-footprint" aria-label="Delete footprint">
                                                <button onClick={() => sendOrDeleteFootprint(suggestion.id, true, null)}>
                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                    <span>{t('suggestions.deletefootprint')}</span>
                                                </button>
                                            </div>
                                        </div> : null}
                                </>
                                : null}
                        </div>
                        {isError ? <Alert
                            className='member_address'
                            variant="danger"
                            id='main_alert'
                            onClose={() => setIsError(false)}
                            dismissible
                        >
                            <p>{errorMessage}</p>
                        </Alert> : null}
                        {
                            suggestionProfile || categoryData ?
                                <>
                                    {suggestionProfile?.profileQuestions?.map((profile) => (
                                        <Accordion key={profile.id}>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className='accordian_personal_detail'>{profile?.name}</Accordion.Header>
                                                <Accordion.Body className='profile_accordian_body'>
                                                    {profile?.questions?.map((question) => (
                                                        <div key={question?.name} className='profileQ_container'>
                                                            <div className="profileQ_details">
                                                                {question?.type === 'ChoiceQuestion' ? (
                                                                    <div className='q_details'>
                                                                        <p className='q_heading p_heading'>{question?.name}</p>
                                                                        <p className='q_description'>{question?.choiceQuestion?.selectedChoices?.map((ans, index) => (
                                                                            <span key={index}>
                                                                                {ans.value}
                                                                                {index !== question.choiceQuestion.selectedChoices.length - 1 && ','}{' '}
                                                                            </span>
                                                                        ))}</p>
                                                                    </div>
                                                                ) : null}
                                                                {question?.type === 'NumberQuestion' ? (
                                                                    <div className='q_details'>
                                                                        <p className='q_heading p_heading'>{question?.name}</p>
                                                                        <p className='q_description'>{question?.numberQuestion?.answer}</p>
                                                                    </div>
                                                                ) : null}
                                                                {question?.type === "TextQuestion" ? (
                                                                    <div className='q_details'>
                                                                        <p className='q_heading p_heading'>{question?.name}</p>
                                                                        <p className='q_description'>{question?.textQuestion?.answer}</p>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    ))}
                                    {categoryData?.profileQuestions?.map((profile, index) => (
                                        <Accordion key={profile?.id}>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className='accordian_personal_detail'>{profile?.name}</Accordion.Header>
                                                <Accordion.Body className='profile_accordian_body'>
                                                    {profile?.questions?.map((question) => (
                                                        <div key={question.id} className='profileQ_container'>
                                                            <div className="profileQ_details">
                                                                {question?.type === 'ChoiceQuestion' ? (
                                                                    <div className='q_details'>
                                                                        <p className='q_heading'>{question?.name}</p>
                                                                        <p className='q_description'>{question?.choiceQuestion?.selectedChoices?.map((ans, index) => (
                                                                            <span key={index}>
                                                                                {ans.value}
                                                                                {index !== question.choiceQuestion.selectedChoices.length - 1 && ','}{' '}
                                                                            </span>
                                                                        ))}</p>
                                                                    </div>
                                                                ) : null}
                                                                {question?.type === 'NumberQuestion' ? (
                                                                    <div className='q_details'>
                                                                        <p className='q_heading'>{question?.name}</p>
                                                                        <p className='q_description'>{question?.numberQuestion?.answer}</p>
                                                                    </div>
                                                                ) : null}
                                                                {question?.type === "TextQuestion" ? (
                                                                    <div className='q_details'>
                                                                        <p className='q_heading'>{question?.name}</p>
                                                                        <p className='q_description'>{question?.textQuestion?.answer}</p>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    ))}
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header className='accordian_personal_detail'>{t('suggestions.photogallerie')}</Accordion.Header>
                                            <Accordion.Body >
                                                <div>
                                                    {categoryData?.galleries?.length ? <p>{t('suggestions.clickongallery')}</p> : <p>{t('suggestions.noimageshared')}</p>}
                                                </div>
                                                <div className='s_gallery_wrapper'>
                                                    {categoryData?.galleries?.map((gallery, index) => (
                                                        gallery.mediaDTOS ?
                                                            <div key={gallery?.id}>

                                                                <div
                                                                    onClick={() => openGallery(gallery)}
                                                                    className='s_gallery_container'>
                                                                    <img className='' src={gallery?.thumbnails?.[0] ? base64Image(gallery?.thumbnails?.[0].thumbnail) : UserIcon} />
                                                                </div>
                                                                <div className='g_name_container'>
                                                                    <span className='galleryname'>{gallery?.name.length > 20 ? gallery?.name.substring(0, 20) : gallery?.name}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            : null
                                                    ))}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </>
                                :
                                <div className='openprofile_spinner'>
                                    <Spinner />
                                </div>
                        }
                    </div> : null}
                    <Row className="mt-3 cat_sug_filter">
                        <Col md={6} className='all_sug'>
                            <Form.Group controlId="formGridState">
                                <Form.Label>{t('suggestions.myratings')}</Form.Label>
                                <Form.Select
                                    value={affiliationType}
                                    onChange={(e) => changeRelationship(e.target.value, false, false)}
                                >
                                    <option value="POSITIVE">{t('suggestions.accepted')}</option>
                                    <option value="NEGATIVE">{t('suggestions.ignored')}</option>
                                    <option value="NEUTRAL">{t('suggestions.undecided')}</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={6} className="d-flex align-items-center justify-content-end">
                            <div
                                onClick={() => setIsShareSuggestions(true)}
                                className="text-center mx-2 opensuggestionIcon">
                                <FontAwesomeIcon icon={faShareFromSquare} />
                                <div>{t('suggestions.share')}</div>
                            </div>
                            <div
                                onClick={suggestionDelete}
                                className="text-center mx-2 opensuggestionIcon">
                                <FontAwesomeIcon icon={faTrashCan} />
                                <div>{t('suggestions.deletesuggestion')}</div>
                            </div>
                            <div
                                onClick={() => setIsReportAbuse(true)}
                                className="text-center mx-2 opensuggestionIcon">
                                <FontAwesomeIcon icon={faExclamationCircle} />
                                <div>{t('suggestions.reportabuse')}</div>
                            </div>
                        </Col>
                    </Row>
                    <Modal
                        className='chatHistory_model'
                        fullscreen
                        show={chatHistoryModel}
                        onHide={() => setChatHistoryModel(false)}
                    >
                        <Modal.Header closeButton />
                        <div className='communityContacts_chatHistory'>
                            <ChatHistory
                                chats={chats}
                                avatars={avatars}
                                setUsers={setUsers}
                                currentUser={userDetail}
                                setCurrentUser={setUserDetail}
                                userDetails={userDetails}
                                sendMessage={sendMessage}
                                isFileLoader={isFileLoader}
                                setIsFileLoader={setIsFileLoader}
                                sourceType={'SUGGESTIONS'}
                                currentUserDetail={currentUserDetail}
                                currentAvatar={currentAvatar}
                                isMessageError={isMessageError}
                                attachmentFiles={attachmentFiles}
                                setIsMessageError={setIsMessageError}
                                chatContainerRef={lastContactElementRef}
                                chatOverviewRef={lastOverviewElementRef} 
                                setIsFileResponseReceived={setIsFileResponseReceived}
                                isFileResponseReceived={isFileResponseReceived} 
                                isLoading={isLoading} 
                                isOpenMessage={isOpenMessage} 
                                setIsOpenMessage={setIsOpenMessage}
                            />
                        </div>
                    </Modal>
                </main>
                {isFootprintListOpen ? <FootprintKey
                    show={isFootprintListOpen}
                    handleClose={closeFootprintKey}
                    sendOrDeleteFootprint={sendOrDeleteFootprint}
                    currentSuggestionId={currentSuggestionId}
                /> : null}
                {isSuggestionDelete ?
                    <DeleteSuggestions
                        show={isSuggestionDelete}
                        handleClose={closeDeleteSuggestions}
                        changeRelationship={changeRelationship}
                        affiliationType={affiliationType}
                    /> : null
                }
                {isGalleryOpen ?
                    <SuggestionsGalleryImages
                        suggestionGalleryImages={suggestionGalleryImages}
                        isGalleryLoading={isGalleryLoading}
                        show={isGalleryOpen}
                        close={closeImagePopup}
                    />
                    : null
                }
                {isShareSuggestions ?
                    <ShareMyGallery
                        base64Image={base64Image}
                        show={isShareSuggestions}
                        close={closeShareSuggestions}
                        relationshipId={suggestion.id}
                    />
                    : null
                }
                {isReportAbuse ? <ReportSuggestions
                    show={isReportAbuse}
                    close={closeReportSuggestions}
                    targetUserAlias={suggestion.name}
                    relationshipId={suggestion.id}
                /> : null}
            </Modal.Body>
        </Modal>
    )
}

export default SuggestionView;