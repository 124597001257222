function LoginReducer(states, action) {
    switch (action.type) {
      case 'LOGIN': {
        return {
          ...states,
          memberLogin: action.data 
        };
      }
      case 'MEMBER_DETAILS': {
        return {
          ...states,
          memberDetails: action.data
        };
      }
      case 'ACCESS_TOKEN': {
        return {
          ...states,
          accessToken: action.data
        };
      }
      case 'CURRENT_URL': {
        return {
          ...states,
          currentUrl: action.data
        };
      }
      case 'IS_CONFIRM_MAIL_POPUP': {
        return {
          ...states,
          isConfirmMailPopup: action.data
        };
      }
      case 'SET_LANGUAGE': {
        localStorage.setItem('language', action.data); 
        return {
          ...states,
          language: action.data
        };
      }
      case "MESSAGE_COUNT":        
        return {
          ...states,
          unreadMessagesByAvailableUsers: action.data.unreadMessagesByAvailableUsers,
          unreadMessagesByAllUsers: action.data.unreadMessagesByAllUsers,
          messageCount: action.data.messageCount
        };      
      default:
        return states;
    }
  }
  
  export default LoginReducer;
  