import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleInfo
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert';
import 'draft-js/dist/Draft.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from 'react-select';

function ChoiceQuestions(props) {
    const {
        question,
        questionaire,
        questionReference,
        errResponse,
        answers,
        setAnswers,
        onAlertOpen,
        qgroup,
        handleOptionClick,
        setStoreQuestionaire,
        updateErrorState,
        clearErrorState
    } = props;

    const { t, i18n } = useTranslation();

    const validateTapRadio = (e, question) => {
        if (e.target.type === "radio" && e.type === "keydown" && e.keyCode === 9) {
            if (!e.target.checked && question.required) {
                updateErrorState(t('registration.questionnaires.fieldRequired'), question.id);
                e.preventDefault()
            } else {
                clearErrorState(question.id)
            }
        }
    };

    const validateTabSelect = (e, question, selectedValue) => {
        if (e.type === "keydown" && e.keyCode === 9 && e.key === "Tab") {
            if (selectedValue === "" && question.required) {
                updateErrorState(t('registration.questionnaires.fieldRequired'), question.id);
                e.preventDefault();
            } else {
                clearErrorState(question.id)
            }
        }
    };

    const validateTabCheckbox = (e, data) => {
        const elements = document.querySelectorAll('input[name="'+data.id+'"]');
        if (e.target.type === "checkbox" && e.type === "keydown" && e.keyCode === 9 && e.shiftKey !== true) {
            var isChecked = false;
            var isLastCheckbox = false;
            elements.forEach((ele, i) => {
                if(ele.checked) isChecked = true;
                if(e.target.id == elements[elements.length-1].id) isLastCheckbox = true;
            });
            if (!isChecked && isLastCheckbox && data.required) {
                updateErrorState(t('registration.questionnaires.fieldRequired'), data.id);
                e.preventDefault()
            } else {
                if(isChecked && errResponse?.errorMessageByQuestionId?.[data.id]) clearErrorState(data.id)
            }
        }
    };

    const selectStyles = () => ({
        container: (base) => ({
            ...base,
            width: '100%',
            maxWidth: '100%',
        }),
        singleValue: (base) => ({
            ...base,
            whiteSpace: 'normal',
        }),
        control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused
                ? '0 0 0 4px rgba(13, 110, 253, 0.25)'
                : 'none',
            borderColor: state.isFocused || state.isHovered
                ? '#86b7fe' : '#9ea5ab',
            transition: 'border-color 0.2s ease, box-shadow 0.2s ease',
            '&:hover': {
                borderColor: '#9ea5ab',
            },
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused
                ? '#0d6efd'
                : '#fff',

            color: state.isFocused
                ? '#fff'
                : '#333',
            cursor: 'pointer',
            fontSize: '1rem',
            fontWeight: '400',
        }),
        menu: (base) => ({
            ...base,
            zIndex: 1000,
        }),
    });
    
    const changeCheckBox = (e, choice) => {
        if (e.target.checked) {
            handleOptionClick(question, choice.id, qgroup)
        } else if (answers[question.id] && answers[question.id].answerValues.indexOf(choice.id) >= 0) {
            answers[question.id].answerValues.splice(answers[question.id].answerValues.indexOf(choice.id), 1)
            if (answers[question.id].answerValues.length == 0) delete answers[question.id];
            let shouldEnable = false
            Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                question.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                    shouldEnable = answers[question.id] && answers[question.id].answerValues.indexOf(enablerOption) >= 0;
                    if (shouldEnable) { return true; }
                    return false;
                })
                const tempQn = { ...questionaire }
                tempQn.questionGroups.filter(qg => qg.id === qgroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable;
                if (!shouldEnable) {
                    answers[key] = {};
                }
                setStoreQuestionaire(tempQn)
            })
            setAnswers(answers)
        }
    }

    let comboBox;
    if (question.choiceQuestion?.type === 'ComboBox')
        comboBox =
            <div key={`combo-${question.id}`} className='questionair_alignment'
                ref={(e) => questionReference(question, e)}
            >
                {question.enabledQuestion === true ?
                    <div>
                        <Form.Group className="mt-3 dropdown">
                            <span className="disriptionI_label">
                                {question?.description ?
                                    <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        onClick={() => onAlertOpen(question?.description)}
                                    /> : null}
                                <Form.Label htmlFor={question.id} id={`label-${question.id}`}>{question.name}
                                    <span className='required_asterisks' aria-hidden="true">{question.required ? '*' : null}</span>
                                </Form.Label>
                            </span>
                            <Select
                                id={question.id}
                                inputId={question.id}
                                className={`dropdwon_select_questionair ${!answers[question.id] && question.required && errResponse?.errorMessageByQuestionId?.[question.id] ? 'is-invalid' : ''}`}
                                aria-label={!answers[question.id] && question.required && errResponse?.errorMessageByQuestionId?.[question.id] ? t('registration.questionnaires.fieldRequired') : question.name}
                                aria-required={question.required ? 'true' : 'false'}
                                value={answers[question.id]?.answerValues?.[0] ?
                                    { value: answers[question.id]?.answerValues?.[0], label: question.choiceQuestion.choices.find(choice => choice.id === answers[question.id]?.answerValues?.[0]).value } : { value: '', label: t('registration.questionnaires.pleaseSelectOption') }
                                }
                                onChange={(selectedOption) => {
                                    const selectedValue = selectedOption ? selectedOption.value : '';
                                    handleOptionClick(question, selectedValue, qgroup);
                                }}
                                onKeyDown={(e) => validateTabSelect(e, question, answers[question.id]?.answerValues?.[0] || "")}
                                options={[
                                    { value: '', label: t('registration.questionnaires.pleaseSelectOption') },
                                    ...question.choiceQuestion.choices.map(choice => ({
                                        value: choice.id,
                                        label: choice.value,
                                    })),
                                ]}
                                placeholder={t('registration.questionnaires.pleaseSelectOption')}
                                styles={selectStyles()} 
                                menuPlacement="auto"
                                menuPosition="fixed" 
                            />

                            {question.askVisibleToOthers === true ?
                                <Form.Check id={`profileVisible${question.id}`}
                                    name={`profileVisible${question.id}`}
                                    type="checkbox"
                                    label={t('common.visibleToOthers')}
                                    className='msg_pre_checkbox profile_visible'
                                    onClick={(e) => {
                                        if (answers[question.id] && answers[question.id].answerValues.length > 0) {
                                            answers[question.id].profileVisible = e.target.checked
                                            setAnswers(answers)
                                        }
                                    }}
                                >
                                </Form.Check> : null}
                        </Form.Group>

                        <Alert className='alert_questionaire' show={!answers[question.id] && question.required && errResponse?.errorMessageByQuestionId?.[question.id] ? true : false} 
                            variant="danger" id={`err_${question.id}`}>{errResponse?.errorMessageByQuestionId?.[question.id]}</Alert>

                    </div> : null}
            </div>

    let radioBtns;
    if (question.choiceQuestion?.type === 'Radio')
        radioBtns =
            <div key={`radio-${question.id}`} className='radio_section'
                ref={(e) => questionReference(question, e)}
            >
                {question.enabledQuestion === true ? <div>
                    <fieldset role="radiogroup"
                        aria-required={question.required ? 'true' : 'false'}
                        aria-labelledby={`legend-${question.id}`}>
                        <legend className="disriptionI_label inlineLegend" id={`legend-${question.id}`}>
                            <h4>
                                {question?.description ? (
                                    <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        onClick={() => onAlertOpen(question?.description)}
                                    />
                                ) : null}
                                {question.name} <span className='required_asterisks' aria-hidden="true">{question.required ? '*' : null}</span>
                            </h4>
                        </legend>
                        <div id={question.id} className='radio_groups' role='radiogroup'>
                            {question.choiceQuestion.choices.map(choice => (
                                <Form.Group key={choice.id} className="category_f" id={`label-${choice.id}-${question.id}`}>
                                    <Form.Check
                                        checked={answers[question.id]?.answerValues?.includes(choice.id)}
                                        id={`radio-${choice.id}-${question.id}`}
                                        type="radio"
                                        name={question.id}
                                        label={choice.value}
                                        className='msg_pre_checkbox'
                                        onClick={(e) => {
                                            handleOptionClick(question, choice.id, qgroup)
                                        }}
                                        onKeyDown={(e) => validateTapRadio(e, question)}
                                    />
                                </Form.Group>
                            ))
                            }
                        </div>
                        {question.askVisibleToOthers === true ?
                            <Form.Check id={`profileVisible${question.id}`}
                                name={`profileVisible${question.id}`}
                                type="checkbox"
                                label={t('common.visibleToOthers')}
                                className='msg_pre_checkbox'
                                onClick={(e) => {
                                    if (answers?.[question.id].answerValues.length > 0) {
                                        answers[question.id].profileVisible = e.target.checked
                                        setAnswers(answers)
                                    }
                                }}
                            >
                            </Form.Check> : null}

                            <Alert className='alert_questionaire' show={!answers[question.id] && question.required && errResponse?.errorMessageByQuestionId?.[question.id] ? true : false}
                                variant="danger" id={`err_${question.id}`}>{errResponse?.errorMessageByQuestionId?.[question.id]}</Alert>
                    </fieldset>
                </div> : null}
            </div>


    let checkBoxes;
    if (question.choiceQuestion?.type === 'Checkbox')
        checkBoxes =
            <div key={`check-${question.id}`} className='questionair_alignment'
                ref={(e) => questionReference(question, e)}
            >
                {question.enabledQuestion === true ? <div>
                    <fieldset>
                        <legend className="disriptionI_label inlineLegend">
                            <h4>{question?.description ?
                                <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    onClick={() => onAlertOpen(question?.description)}
                                /> : null}
                                {question.name} <span className='required_asterisks' aria-hidden="true">{question.required ? '*' : null}</span>
                            </h4>
                        </legend>
                        <div className='checkbox_questionair'>
                            {question.choiceQuestion.choices.map(choice => (
                                <Form.Group key={choice.id} className="category_f">
                                    <Form.Check
                                        defaultChecked={answers[question.id]?.answerValues.includes(choice.id)}
                                        id={`checkbox-${choice.id}-${question.id}`}
                                        name={question.id}
                                        type="checkbox"
                                        label={choice.value}
                                        className='msg_pre_checkbox'
                                        onClick={(e) => changeCheckBox(e, choice)}
                                        onKeyDown={(e) => validateTabCheckbox(e, question)}
                                    />
                                </Form.Group>
                            ))}
                            {question.askVisibleToOthers === true ?
                                <Form.Check id={`profileVisible${question.id}`}
                                    name={`profileVisible${question.id}`}
                                    type="checkbox"
                                    label={t('common.visibleToOthers')}
                                    className='msg_pre_checkbox'
                                    onClick={(e) => {
                                        if (answers[question.id] && answers[question.id].answerValues.length > 0) {
                                            answers[question.id].profileVisible = e.target.checked
                                            setAnswers(answers)
                                        }
                                    }}
                                >
                                </Form.Check> : null}
                        </div>                        
                        <Alert className='alert_questionaire' show={!answers[question.id] && question.required && errResponse?.errorMessageByQuestionId?.[question.id] ? true : false}
                            variant="danger" id={`err_${question.id}`}>{errResponse?.errorMessageByQuestionId?.[question.id]}</Alert>
                    </fieldset>
                </div> : null}
            </div>

    return (
        <div>
            {comboBox}
            {radioBtns}
            {checkBoxes}
        </div>
    )
}

export default ChoiceQuestions;