import React, { useState, useContext, useEffect } from "react";
import { Form, Button, Modal, ModalBody, Spinner, Alert } from "react-bootstrap";
import { paymentMethodChange } from "../registrationProcess/registrationProcessService.jsx";
import { useTranslation } from "react-i18next";
import { LoginContext } from "../../context/loginContext.jsx";
import DDPaymentForm from "./DDPaymentForm.jsx";
import { getSubcription } from "./membershipServices.jsx";

function SubscriptionDetail(props) {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [confirmChangeMethod, setConfirmChangeMethod] = useState(false);
  const [newPaymentMethod, setNewPaymentMethod] = useState(null);
  const [isPaymentDetailsOpen, setIsPaymentDetailOpen] = useState(false);
  const [subscription, setSubscription] = useState();
  

  let {
    loginData: { accessToken },
  } = useContext(LoginContext);
  const { t, i18n } = useTranslation();

  const headers = {
    language: i18n.language,
    accessToken,
  };

  const close = () => {
    setConfirmChangeMethod(false);
  };

  function changePaymentMethod(e) {
    const newPaymentMethod = e.target.value;
    setNewPaymentMethod(newPaymentMethod);
    setConfirmChangeMethod(true);
  }

  function confirmChangePaymentMethod(e) {
    const payload = {
      paymentMethod: newPaymentMethod,
      paymentRequest: null,
    };
    if (newPaymentMethod == "DIRECT_DEBIT") {
      setIsPaymentDetailOpen(true);
    } else {
      paymentMethodChange(headers, payload)
        .then((result) => {
          if (result?.status === 200) {
            props.setToastMessage(
              t("mymembership.subscription.p_method_change")
            );
            props.handleShowToast();
            setConfirmChangeMethod(false);
            setSelectedPaymentMethod(newPaymentMethod);
            if (newPaymentMethod == "DIRECT_DEBIT") {
              setIsPaymentDetailOpen(true);
              props.setCurrentPaymentMethod('DIRECT_DEBIT')
            }else{
              props.setCurrentPaymentMethod('PREPAYMENT')
            }
          } else {
            setConfirmChangeMethod(true);
          }
        })
        .catch((error) => {
          console.error("Error in API call", error);
        });
    }
  }

  function closePaymentDetails() {
    setIsPaymentDetailOpen(false);
    setConfirmChangeMethod(false);
  }

  useEffect(() => {
    getSubcription(headers).then((result) => {
      if (result?.status === 200) {
        setSubscription(result.data);
        setSelectedPaymentMethod(result.data.paymentMethod);
        props.setCurrentPaymentMethod(result.data.paymentMethod)
      }
    }).catch((error) => {
      console.error("Error in API call", error);
    });
  }, []);

  return (
    <>
      {subscription ? (
        <Form>
          <div className="form_head_part">
            <h4>{t("mymembership.subscription.serviceoffer")}</h4>
            <span></span>
          </div>
          {subscription?.state !== null ? (
            <>
              <div className="sub_desc">
                <p>{t("mymembership.subscription.state")}</p>
                <p>{subscription?.state}</p>
              </div>
              <div className="sub_desc">
                <p>{t("mymembership.subscription.membershipName")}</p>
                <p>{subscription?.productName}</p>
              </div>
              <div className="sub_desc">
                <p>{t("mymembership.subscription.start")}</p>
                <p>{subscription?.startDate}</p>
              </div>
              <div className="sub_desc">
                <p>{t("mymembership.subscription.end")}</p>
                <p>{subscription?.endDate}</p>
              </div>
            </>
          ) : (
            <Alert className="member_address" variant="danger" id="main_alert">
              <p>{t("mymembership.subscription.no_active_subscription")}</p>
            </Alert>
          )}
          <div className="sub_desc">
            <p>{t("mymembership.subscription.paymentMethod")}</p>
          </div>
          <div className="active_categories">
            <Form.Group className="category_f" id="formGridCheckbox">
              <Form.Check
                name="payment"
                className="msg_pre_checkbox"
                type="radio"
                onChange={changePaymentMethod}
                checked={selectedPaymentMethod == "DIRECT_DEBIT"}
                value={"DIRECT_DEBIT"}
                label={t("mymembership.subscription.directDebit")}
              />
            </Form.Group>
            <Form.Group className="category_f" id="formGridCheckbox">
              <Form.Check
                name="payment"
                className="msg_pre_checkbox"
                type="radio"
                value={"PREPAYMENT"}
                checked={selectedPaymentMethod == "PREPAYMENT"}
                onChange={changePaymentMethod}
                label={t("mymembership.subscription.prepayment")}
              />
            </Form.Group>
          </div>
          <div>
            <DDPaymentForm
              setQuestionaireId={""}
              show={isPaymentDetailsOpen}
              close={closePaymentDetails}
              setToastMessage={props.setToastMessage}
              handleShowToast={props.handleShowToast}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
            />
          </div>
          <div className="modal show">
            <Modal className="registration_modal" show={confirmChangeMethod}>
              <ModalBody>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="popupForm.ControlInput1"
                  >
                    <Form.Label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "mymembership.subscription.confirm_change_message"
                          ),
                        }}
                      />
                    </Form.Label>
                  </Form.Group>
                </Form>
              </ModalBody>
              <Modal.Footer>
                <Button variant="outline-danger" onClick={(e) => close()}>
                  {t("registration.buttons.cancel")}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => confirmChangePaymentMethod()}
                >
                  {t("registration.buttons.proceed")}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Form>
      ) : (
        <div className="subscription_loader">
          {" "}
          <Spinner />
        </div>
      )}
    </>
  );
}

export default SubscriptionDetail;
