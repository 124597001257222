import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import promise from "../../assets/images/promise.png";
import AGBdataprotection from './AGBdataprotection';
import { getSexQuestion, validatePromoCode } from './preRegistrationService'
import { useTranslation } from 'react-i18next';
import AlertModal from '../commonComponents/alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import "./aboutyou.css";
import "react-datepicker/dist/react-datepicker.css";
import { Alert, Row, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { autoFocusByID, autoFocusFirstElement, numberOnlyWithDigit } from '../commonMethods';

function Aboutyou(props) {
    const { memberRegistration, setMemberRegistration } = props;
    const [isgotoNext, setIsgotoNext] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [sexInfo, setSexInfo] = useState({
        genderType: null,
        showSexAlert: "",
        sexAlert: false
    })
    const [category, setCategory] = useState({
        categoryErrorMeg: "",
        isCategoryError: false
    })
    const [dateOfBirth, setDateOfBirth] = useState({
        showDateOfBirthAlert: "",
        dateOfBirthError: false,
        monthOfBirth: '',
        dayOfBirth: '',
        yearOfBirth: '',
        monthAlert: false,
        dayAlert: false,
        yearAlert: false
    })
    const [promoCode, setPromoCode] = useState({isPromoCodeApplied : false, isPromoCodeValid: false});

    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;

    useEffect(() => {
        const finalData = {
            language: currentLang
        }
        getSexQuestion(finalData).then((res) => {
            if (res?.status === 200 && res.data) {
                setSexInfo((prev) => ({
                    ...prev,
                    genderType: res?.data
                }))
            }
        });
    }, [currentLang])

    useEffect(() => {
        if(dateOfBirth?.monthOfBirth && dateOfBirth?.dayOfBirth && dateOfBirth?.yearOfBirth) {
            let newDayOfBirth = dateOfBirth?.dayOfBirth;
            let newMonthOfBirth = dateOfBirth?.monthOfBirth;
            if(dateOfBirth?.dayOfBirth.length === 1) newDayOfBirth = '0'+dateOfBirth?.dayOfBirth;
            if(dateOfBirth?.monthOfBirth.length === 1) newMonthOfBirth = '0'+newMonthOfBirth;

            let newRsDate = dateOfBirth?.yearOfBirth+"-"+newMonthOfBirth+"-"+newDayOfBirth;
            setMemberRegistration((prevData) => ({
                ...prevData,
                birthDate:  new Date(newRsDate),
            }));
        }
    }, [dateOfBirth?.monthOfBirth, dateOfBirth?.dayOfBirth, dateOfBirth?.yearOfBirth])

    const handleGenderError = (evt) => {
        let message = t('member.preregistration.errors.aboutYou')
        if (evt.target.value === '' || evt.target.value === undefined) {
            let dobMsg = t('member.preregistration.errors.sex')
            setSexInfo((prev) => ({...prev, showSexAlert: message + (dobMsg ? " " + dobMsg : ""), sexAlert: true}))
            return true;
        } else {
            setSexInfo((prev) => ({...prev, showSexAlert: '', sexAlert: false}))
            return false;
        }
    }

    const validateWithTAB = (evt) => {
        let isError = null;
        if(evt && evt.type === 'keydown' && evt.keyCode === 9) {
            if(evt.target.id === 'formGridSexAnswer') isError = handleGenderError(evt)
            if(evt.target.id === 'month') isError = handleMonth(evt)
            if(evt.target.id === 'day') isError = handleDay(evt)
            if(evt.target.id === 'year') isError = handleYear(evt)
            if(evt.target.value === '' || isError) evt.preventDefault();
        }
    }

    const isLeapYear = (year) => {
        return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
      };
      
      const getDaysInMonth = (month, year) => {
        const daysInMonths = [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        return daysInMonths[month - 1];
      };
      
      const isValidateBirthDate = (evt) => {
        if (!memberRegistration[`birthDate`]) {
          if (!dateOfBirth?.monthOfBirth) 
            setDateOfBirth((prev) => ({...prev, monthAlert: true}))
          if (!dateOfBirth?.dayOfBirth)
            setDateOfBirth((prev) => ({...prev, dayAlert: true})) 
          if (!dateOfBirth?.yearOfBirth) 
            setDateOfBirth((prev) => ({...prev, yearAlert: true}))
          return false;
        }
      
        const daysInMonth = getDaysInMonth(dateOfBirth?.monthOfBirth, dateOfBirth?.yearOfBirth);
        if (dateOfBirth?.dayOfBirth > daysInMonth) {
            setDateOfBirth((prev) => ({...prev, dayAlert: true}))
          let categoryMsg = '';
          if (dateOfBirth?.monthOfBirth == 2 && dateOfBirth?.dayOfBirth == 29) {
            categoryMsg = isLeapYear(dateOfBirth?.yearOfBirth)
              ? t(`member.preregistration.errors.max${29}`)
              : t(`member.preregistration.errors.max${28}`);
          } else {
            categoryMsg = t(`member.preregistration.errors.max${daysInMonth}`);
          }
          setDateOfBirth((prev) => ({...prev, showDateOfBirthAlert: categoryMsg, dateOfBirthError: true}))
          return false;
        }
      
        setDateOfBirth((prev) => ({...prev, dayAlert: false, dateOfBirthError: false}))
        const years = calculateAge(memberRegistration[`birthDate`]);
        setDateOfBirth((prev) => ({...prev, showDateOfBirthAlert: ''}))
      
        if (years) {
          if (years < 18 || years >= 120) {
            let dobMsgAllow = t('member.preregistration.labels.dateOfBirthOver18');
            setDateOfBirth((prev) => ({...prev, showDateOfBirthAlert: (dobMsgAllow ? " " + dobMsgAllow : ""), dateOfBirthError: true}))

            setShowAlert(true);
            autoFocusByID('birthDate');
            return false;
          } else {
            setShowAlert(false);
            setDateOfBirth((prev) => ({...prev, dateOfBirthError: false}))
            return true;
          }
        }
      };
      

    const validateCategory = (evt) => {
        let message = t('member.preregistration.errors.aboutYou')
        if ((memberRegistration[`categories`] && memberRegistration[`categories`].length === 0) || memberRegistration[`categories`] == undefined) {
            let categoryMsg = t('member.preregistration.errors.categories')
            setCategory((prev) => ({...prev, categoryErrorMeg: message + (categoryMsg ? " " + categoryMsg : ""), isCategoryError: true}))
            autoFocusByID("catFRIENDSHIP");
        } else {
            setCategory((prev) => ({...prev, categoryErrorMeg: "", isCategoryError: false}))    
        }
    }

    const onGoToNext = () => {
        handleGenderError({ target: { value: memberRegistration[`sexAnswer`], id: 'formGridSexAnswer' } })
        validateCategory({ target: { value: memberRegistration[`categories`] } })
        if (!isValidateBirthDate(memberRegistration[`birthDate`])) {
            if (dateOfBirth?.monthOfBirth > 12) setDateOfBirth((prev) => ({...prev, monthAlert: true}))
            if (dateOfBirth?.dayOfBirth > 31) setDateOfBirth((prev) => ({...prev, dayAlert: true}))
            autoFocusFirstElement();
            return;
        }        
        if (!(memberRegistration[`actionCode`] == '' || memberRegistration[`actionCode`] == undefined) && !promoCode.isPromoCodeApplied) {
                setPromoCode({ ...promoCode, isValidate: false, isPromoEmpty: false, isPromoCodeValid: false });                
                setTimeout(() => {
                    setPromoCode({ ...promoCode, isValidate: true, isPromoEmpty: false, isPromoCodeValid: false });
                }, 0);           
            return;
        }
        setDateOfBirth((prev) => ({...prev, dateOfBirthError: false}))
        if ((memberRegistration[`categories`] &&
            (memberRegistration[`categories`].indexOf("PARTNERSHIP") >= 0 || memberRegistration[`categories`].indexOf("FRIENDSHIP") >= 0))
            && memberRegistration[`sexAnswer`] && memberRegistration[`birthDate`]) {
            setIsgotoNext(false)
        } else {
            setShowAlert(true)
            autoFocusFirstElement();
        }
    }

    const calculateAge = (birthDate) => {
        const today = new Date();
        let age = today?.getFullYear() - birthDate?.getFullYear();
        const monthDifference = today?.getMonth() - birthDate?.getMonth();
        const dayDifference = today?.getDate() - birthDate?.getDate();    
        if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
            age--;
        }    
        return age;
    };

    const validateWithTABCheckbox = (evt) => {
        if (evt.type === 'keydown' && evt.keyCode === 9 && evt.target.id === 'catPARTNERSHIP') {
            validateCategory({ target: { value: memberRegistration[`categories`] } })
        }
    }

    const handleMonth = (e) => {
        if(e.target.value > 12 || e.target.value == 0) {
            setDateOfBirth((prev) => ({...prev, monthAlert: true}))
            return true;
        } else {
            setDateOfBirth((prev) => ({...prev, monthAlert: false}))
            return false;
        }
    }

    const handleDay = (e) => {
        if(e.target.value > 31 || e.target.value == 0) {
            setDateOfBirth((prev) => ({...prev, dayAlert: true}))
            return true;
        } else {
            setDateOfBirth((prev) => ({...prev, dayAlert: false}))
            return false;
        }
    }
    
    const handleYear = (e) => {
        setDateOfBirth((prev) => ({...prev, yearOfBirth: e.target.value, showDateOfBirthAlert: '', dateOfBirthError: false}))

        if(e.target.value && (e.target.value > (new Date()?.getFullYear() - 18) || (new Date()?.getFullYear() - e.target.value) > 120)) {
            setDateOfBirth((prev) => ({...prev, yearAlert: true}))
            if((new Date()?.getFullYear() - e.target.value) < 18 || (new Date()?.getFullYear() - e.target.value) > 120) {
                setDateOfBirth((prev) => ({...prev, showDateOfBirthAlert: t('member.preregistration.labels.dateOfBirthOver18')}))   
            }
            return true;
        } else {
            if(e.target.value) {
                setDateOfBirth((prev) => ({...prev, yearAlert: false}))
                return false;
            } else {
                setDateOfBirth((prev) => ({...prev, yearAlert: true}))
                return true;
            }
        }
    }

    function applyPromoCode() {
        const headers = {
            language: currentLang,
        };

        setPromoCode({ ...promoCode, isPromoCodeValid: false });
        if ((memberRegistration[`actionCode`] == '' || memberRegistration[`actionCode`] == undefined)) {
            setPromoCode({ ...promoCode, isPromoEmpty: false, isPromoCodeValid: false, isValidate: false });
            setTimeout(() => {
                setPromoCode({ ...promoCode, isPromoEmpty: true, isPromoCodeValid: false, isValidate: false });
            }, 0);
            return;
        }        
        validatePromoCode(headers, memberRegistration[`actionCode`]).then((result) => {
            if (result?.status === 200) {
                setPromoCode({ isPromoCodeApplied: true, isPromoCodeValid: false });
            }
            else {
                memberRegistration[`actionCode`] = ''
                setMemberRegistration({ ...memberRegistration })
                setPromoCode({ isPromoCodeApplied: false, isPromoCodeValid: true });
            }
        });
    }

    function selectFriendship(e) {
        if (e.target.checked) {
            memberRegistration[`categories`] = memberRegistration[`categories`] ? [...memberRegistration[`categories`], "FRIENDSHIP"] : ["FRIENDSHIP"];
            setCategory((prev) => ({...prev, isCategoryError: false}))
        } else if (memberRegistration[`categories`] && memberRegistration[`categories`].indexOf("FRIENDSHIP") >= 0) {
            memberRegistration[`categories`].splice(memberRegistration[`categories`].indexOf("FRIENDSHIP"), 1);
        }
        setMemberRegistration({ ...memberRegistration })
    }
    
    function selectPartnership(e) {
        if (e.target.checked) {
            memberRegistration[`categories`] = memberRegistration[`categories`] ? [...memberRegistration[`categories`], "PARTNERSHIP"] : ["PARTNERSHIP"];
            setCategory((prev) => ({...prev, isCategoryError: false}))
        } else if (memberRegistration[`categories`] && memberRegistration[`categories`].indexOf("PARTNERSHIP") >= 0) {
            memberRegistration[`categories`].splice(memberRegistration[`categories`].indexOf("PARTNERSHIP"), 1);
        }
        setMemberRegistration({ ...memberRegistration })
    }

    function removePromoCode() {
        memberRegistration[`actionCode`] = ''
        setMemberRegistration({ ...memberRegistration })
        setPromoCode({ isPromoCodeApplied: false, isPromoCodeValid: false });
    }

    const componentMonthOfBirthDay = 
        <Form.Group className="mt-3 birthdate" as={Col}>
            <Form.Label htmlFor="month">{t('member.preregistration.month')}*</Form.Label>
            <Form.Control 
                autoFocus={currentLang === 'de' ? false : true}
                autoComplete='off'
                type="text"
                id="month"
                value={dateOfBirth?.monthOfBirth}
                className={dateOfBirth?.monthAlert && "is-invalid"}
                aria-label={dateOfBirth?.monthAlert && dateOfBirth?.monthOfBirth ? t('member.preregistration.labels.invalidMonthOfBrith') : t('member.preregistration.labels.monthOfBrith')}
                onChange={(e) => setDateOfBirth((prev) => ({...prev, monthOfBirth: e.target.value}))}
                onBlur={(e) => handleMonth(e)}
                onKeyUp={(e) => numberOnlyWithDigit(e, 2)}
                onKeyDown={(e) => {
                    validateWithTAB(e);
                }}
            />
        </Form.Group>

    const componentDayOfBirthDay = 
        <Form.Group className="mt-3 birthdate" as={Col}>
            <Form.Label htmlFor="day">{t('member.preregistration.day')} *</Form.Label>
            <Form.Control 
                autoFocus={currentLang === 'de' ? true : false}
                autoComplete='off'
                type="text"
                id="day"
                value={dateOfBirth?.dayOfBirth}
                className={dateOfBirth?.dayAlert && "is-invalid"}
                aria-label={dateOfBirth?.dayAlert && dateOfBirth?.dayOfBirth ? t('member.preregistration.labels.invalidDayOfBrith') : t('member.preregistration.labels.dayOfBrith')}
                onChange={(e) => setDateOfBirth((prev) => ({...prev, dayOfBirth: e.target.value}))}
                onBlur={(e) => handleDay(e)}
                onKeyUp={(e) => numberOnlyWithDigit(e, 2)}
                onKeyDown={(e) => {
                    validateWithTAB(e)
                }}
            />
        </Form.Group>


    return (
        <>
            {isgotoNext ?
                <div className="form_row">
                    <div className="pre-register-new">
                        <div className="col-12">
                        <img src={promise} alt={t('member.community.imageFor')+ " "+t('member.preregistration.promiseHeading')} />
                        <h3>{t('member.preregistration.promiseHeading')}</h3>
                        </div>
                        <div className="promiseInfo">
                        <div className="col-12">
                        <p><strong>{t('member.preregistration.promiseSubheading')}</strong></p>
                            <p><FontAwesomeIcon icon={faCheck} /> <span>{t('member.preregistration.registerSubText')}</span></p>
                        </div>
                        <div className="col-12">
                            <p><FontAwesomeIcon icon={faCheck} /> <span>{t('member.preregistration.profileSubText')}</span></p>
                        </div>
                        <div className="col-12">
                            <p><FontAwesomeIcon icon={faCheck} /> <span>{t('member.preregistration.finalizeSubText')}</span></p>
                        </div>
                        </div>
                    </div>
                    <div className='membership_account'>
                        <div className="reg_stepone">
                            <h3 className="lineH3 text-center"><strong>{t('member.registration.registration')}</strong> {t('member.preregistration.step2of3')}</h3>
                            <h4>{t('member.preregistration.profileHeading')}</h4>
                        </div>
                        <Form className='step_one_form'> 
                            <p className='lable birthdateLabel'>{t('member.preregistration.dob')}*</p>
                            <Row className="mb-3 birthdate">
                                {currentLang === 'de' ? 
                                    <>
                                        {componentDayOfBirthDay}
                                        {componentMonthOfBirthDay}
                                    </>
                                : 
                                    <>
                                        {componentMonthOfBirthDay}
                                        {componentDayOfBirthDay}
                                    </>
                                }
                                <Form.Group className="mt-3 birthdate" as={Col}>
                                    <Form.Label htmlFor="year">{t('member.preregistration.year')} *</Form.Label>
                                    <Form.Control 
                                        autoComplete='off'
                                        type="text"
                                        id="year"
                                        className={dateOfBirth?.yearAlert && "is-invalid"}
                                        aria-label={dateOfBirth?.yearAlert && dateOfBirth?.yearOfBirth ? t('member.preregistration.labels.invalidYearOfBrith') : t('member.preregistration.labels.yearOfBrith')}
                                        onBlur={(e) => handleYear(e)}
                                        onKeyUp={(e) => numberOnlyWithDigit(e, 4)}
                                        onKeyDown={(e) => {
                                            validateWithTAB(e)
                                        }}
                                    />
                                </Form.Group>
                            </Row>
                            {(dateOfBirth?.monthAlert) ?
                                <Alert className='member_address' variant="danger">
                                    <p>
                                        {dateOfBirth?.monthAlert && <>{dateOfBirth?.monthOfBirth ? t('member.preregistration.labels.invalidMonthOfBrith') : t('member.preregistration.labels.monthOfBrith')} <br /></>}
                                    </p>
                                </Alert>
                            : null}
                            {(dateOfBirth?.dayAlert) ?
                                <Alert className='member_address' variant="danger">
                                    <p>
                                        {dateOfBirth?.dayAlert && <>{dateOfBirth?.dayOfBirth ? t('member.preregistration.labels.invalidDayOfBrith') : t('member.preregistration.labels.dayOfBrith')} <br /></>}
                                    </p>
                                </Alert>
                            : null}
                            {(dateOfBirth?.yearAlert) ?
                                <Alert className='member_address' variant="danger">
                                    <p>
                                        {dateOfBirth?.yearAlert && <>{dateOfBirth?.yearOfBirth ? t('member.preregistration.labels.invalidYearOfBrith') : t('member.preregistration.labels.yearOfBrith')} <br /></>}
                                    </p>
                                </Alert>
                            : null}
                            {dateOfBirth?.dateOfBirthError ?
                                <Alert className='member_address' variant="danger">
                                    <p>
                                        {dateOfBirth?.showDateOfBirthAlert}
                                    </p>
                                </Alert> 
                            : null}

                            <Form.Group className="mt-3 matching_categories" as={Col} controlId="formGridSexAnswer">
                                
                                <Form.Label>{t('member.preregistration.yourSex')}*</Form.Label>
                                <Form.Select 
                                    className={sexInfo?.sexAlert && "is-invalid"} 
                                    defaultValue={null} 
                                    onChange={(e) => {
                                        memberRegistration[`sexAnswer`] = e.target.value;
                                        setMemberRegistration({ ...memberRegistration })
                                        handleGenderError(e)
                                    }}
                                    onKeyDown={(e) => {                                    
                                        validateWithTAB(e);
                                    }}
                                    aria-label={(showAlert && !memberRegistration[`sexAnswer`]) ? t('member.preregistration.errors.sex'): t('member.preregistration.labels.gender')}
                                >
                                    <option value="" aria-label={t('member.preregistration.labels.gender')}>{t('member.preregistration.pleaseSelectOption')}</option>
                                    {sexInfo?.genderType?.choices.map((choice, index) => (
                                        <option key={choice.choiceId} id={`sexOption-${index}`} value={choice.choiceId}>{choice.value}</option>
                                    ))}
                                </Form.Select>
                                <AlertModal className={'mt-3'} show={sexInfo?.sexAlert} setShow={() => setShowAlert(false)} heading={'Error'} body={sexInfo?.showSexAlert}></AlertModal>
                            </Form.Group>
                            
                            <p className='lable mt-3 matching_categories'>{t('member.preregistration.categories')}*</p>
                            <div className='active_categories'>
                                <Form.Group className="my-1 category_f" id="formGridCheckbox">
                                    <Form.Check 
                                        id='catFRIENDSHIP' 
                                        className={category?.isCategoryError ? 'is-invalid msg_pre_checkbox' : 'msg_pre_checkbox' }
                                        type="checkbox" 
                                        label={t('member.preregistration.categoryFriendship')}
                                        onChange={(e) => selectFriendship(e)}
                                        onKeyDown={(e) => {
                                            validateWithTABCheckbox(e);
                                        }}
                                        aria-label={(showAlert && memberRegistration[`categories`] && (memberRegistration[`categories`].indexOf("PARTNERSHIP") < 0 && memberRegistration[`categories`].indexOf("FRIENDSHIP") < 0)) ? t('member.preregistration.errors.categories'): t('member.preregistration.labels.friendship')}
                                    />
                                </Form.Group>
                                <Form.Group className="my-1 category_f" id="formGridCheckbox">
                                    <Form.Check 
                                        id='catPARTNERSHIP' 
                                        className={category?.isCategoryError ? 'is-invalid msg_pre_checkbox' : 'msg_pre_checkbox' }
                                        type="checkbox" 
                                        label={t('member.preregistration.categoryPartnership')}
                                        onChange={(e) => selectPartnership(e)}
                                        onKeyDown={(e) => {
                                            validateWithTABCheckbox(e);
                                        }}
                                        aria-label={(showAlert && memberRegistration[`categories`] && (memberRegistration[`categories`].indexOf("PARTNERSHIP") < 0 && memberRegistration[`categories`].indexOf("FRIENDSHIP") < 0)) ? t('member.preregistration.errors.categories'): t('member.preregistration.labels.partnership')}
                                    />
                                </Form.Group>
                            </div>
                            <AlertModal className={'mt-2'} show={category?.isCategoryError} setShow={setShowAlert} heading={'Error'} body={category?.categoryErrorMeg}></AlertModal>

                            <Form.Label className="promo_label" htmlFor="promotionCode">{t('member.preregistration.promo')}</Form.Label>
                            <p className="mt-3 promo">{t('member.preregistration.promoCodeDesc')}</p>
                            {!promoCode.isPromoCodeApplied ?
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="input"
                                        id="promotionCode"
                                        placeholder={t('member.preregistration.promohere')}
                                        onChange={(e) => {
                                            memberRegistration[`actionCode`] = e.target.value
                                            setMemberRegistration({ ...memberRegistration })
                                        }}
                                        value={promoCode.isPromoCodeApplied ? "" : memberRegistration[`actionCode`]}
                                        aria-label={t('member.preregistration.labels.promotionCode')}
                                    />
                                    <Button onClick={() => applyPromoCode()}
                                        variant="success"
                                        className='apply_code_btn'>
                                        {t('registration.subscription.apply')}
                                    </Button>
                                </InputGroup>
                                : null}
                            {promoCode.isPromoCodeApplied ? <Alert className='member_address' variant="success">
                                <div className='promo_code_alert'>
                                    <p>{t('member.preregistration.code_applied', { actionCode: memberRegistration['actionCode'] })}</p>
                                    <OverlayTrigger
                            key={'top'}
                            placement={'top'}
                            overlay={
                              <Tooltip id={`tooltip-${'top'}`}>
                               {t('member.preregistration.remove_code')}
                              </Tooltip>
                            }
                          >
                         <FontAwesomeIcon onClick={removePromoCode} icon={faTrashCan} />

                          </OverlayTrigger>
                                </div>
                            </Alert> : null}   

                            {promoCode.isPromoCodeValid ? <Alert className='member_address' variant="danger" dismissible>
                                <div className='promo_code_alert'>
                                    <p>{t('member.preregistration.invalid_promo')}</p>                                    
                                </div>
                            </Alert> : null }
                            {promoCode.isValidate ? <Alert className='member_address' variant="danger" dismissible>
                                <div className='promo_code_alert'>
                                    <p>{t('member.preregistration.validate_promo')}</p>                                    
                                </div>
                            </Alert> : null }
                            {promoCode.isPromoEmpty ? <Alert className='member_address' variant="danger" dismissible>
                                <div className='promo_code_alert'>
                                    <p>{t('member.preregistration.empty_promo')}</p>
                                </div>
                            </Alert> : null }                      
                            <div className="mt-3 step_one_next">
                                <Button className='mx-5' variant="primary" onClick={() => onGoToNext()}>
                                    {t('member.preregistration.nextButton')}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
                :
                <AGBdataprotection memberRegistration={memberRegistration}  setMemberRegistration={setMemberRegistration} />
            }
        </>
    )
}

export default Aboutyou