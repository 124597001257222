import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faEnvelope, faPen, faChevronCircleRight} from '@fortawesome/free-solid-svg-icons';
import community_icon_1 from '../../assets/images/community1.jpg';
import community_icon_2 from '../../assets/images/community2.jpg';
import community_icon_3 from '../../assets/images/community3.jpg';
import community_icon_4 from '../../assets/images/community4.jpg';
import GlkHeader from '../../components/Header/GlkHeader.jsx';
import membershipIcon from '../../assets/images/mem_img.png';
import Person1 from '../../assets/images/personality.png';
import Person2 from '../../assets/images/pairship.png';
import Person3 from '../../assets/images/community.png';
import Person4 from '../../assets/images/friendship.png';
import thumbUp from '../../assets/images/perfect.png';
import {Button, Badge} from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import './home.css';
import News from './news.jsx';
import Footprint from './footprint.jsx';
import IncompleteQuestionnaire from './incompleteQuestionnaire.jsx';
import {getHomeDetails, getIncompleteQuetions, incompleteQuestionnaires, downloadPdf} from './homeServices.jsx';
import { useEffect, useState, useRef, useContext } from 'react';
import { LoginContext } from '../../context/loginContext.jsx';
import MailConfirmation from './MailConfirmation.jsx';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Toaster from '../Toaster/Toaster.jsx';
import YourRelationships from './yourRelationships.jsx';
import { useNavigate } from "react-router-dom";

function Home() {
  const [homeDetails, setHomeDetails] = useState(null);
  const [incompleteQueData, setIncompleteQueData] = useState(null);
  const [loadQuestionnaire, setLoadQuestionnaire] = useState(null);
  const [showQuestionnairePopup, setShowQuestionnairePopup] = useState(false);
  const [refreshIncompleteQ, setRefreshIncompleteQ] = useState(false);
  const [refreshNews, setRefreshNews] = useState(false);
  const [mailConfirmation, setMailConfirmation] = useState({isMailConfirm: '', email: null})

  let {
    loginData: {
      accessToken,
	  memberLogin,
	  isConfirmMailPopup,
    }, 
	dispatch
  } = useContext(LoginContext);  

	
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
	let navigate = useNavigate();
  
  
  const token_lang = {
    language: currentLang,
    accessToken
  }

  useEffect(() => {
		setMailConfirmation({
		  isMailConfirm: memberLogin?.mailConfirmed,
		  email: memberLogin?.username,
		});
	 }, [memberLogin])

  function closeMailConformation() {
	setMailConfirmation({isMailConfirm: '', email: null})
	dispatch({ type: 'IS_CONFIRM_MAIL_POPUP', data: false });
  }

	const toastRef = useRef();
	let toastMessage = t('member.personaldata.changeemail.email_request')

	const handleShowToast = () => {
		const toastElement = toastRef.current;
		const options = {
			animation: true,
			autohide: true,
			delay: 5000,
		};
		if (toastElement) {
			const bootstrapToast = new bootstrap.Toast(toastElement, options);
			bootstrapToast.show();
		}
	};
  
  useEffect(() => {
    getHomeDetails(token_lang).then((res) => {
      if (res?.status === 200) {
        setHomeDetails(res.data);    
        setRefreshNews(false);    
      }
    })    
  }, [currentLang, accessToken, refreshNews])

  useEffect(() => {
    getIncompleteQuetions(token_lang).then((response) => {
      if(response?.status === 200){
        setIncompleteQueData(response.data);
        setRefreshIncompleteQ(false);
      }
    }) 
  },[currentLang, accessToken, refreshIncompleteQ]);

  const getQuestionnaire = (id, questionsId) => {
    const data = {
      id: id,
      questionsId: questionsId,
      language: currentLang,
      accessToken: accessToken
    }
    incompleteQuestionnaires(data).then((result) => {
      setLoadQuestionnaire(result.data);
      setShowQuestionnairePopup(true);
    })
  }

  const closeQuestionnairePopup = () => setShowQuestionnairePopup(false)

  const openPdf = async (type) => {
    const data = {
      type: type,
      language: currentLang,
      accessToken: accessToken
    }
    const response = await downloadPdf(data)
    const blob = await response.blob();
    const file = new Blob([blob], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    window.URL.revokeObjectURL(fileURL)
  }

  function navigateToPage(path) {
    const pathUrl = {
      suggestions: "/suggestions/suggestions",
      messenger: "/suggestions/messenger",
    };

    navigate(pathUrl[path]);
  }
	
  return (
    <>
      <GlkHeader />
			<main className="main_wrapper">
			
				<div className="membership_area">
				
					<div className="home_banner">
						<div className="home_banner_content">
							<h1>{t('member.homebanner.makeImpact')}</h1>
							<h2>{t('member.homebanner.heading')}</h2>
							<p>{t('member.homebanner.text')}</p>
							<p>{t('member.homebanner.text2')}</p>
							<p><Trans i18nKey="member.homebanner.text3" 
							components={{ faqLink: <a target='_blank' rel='noreferrer noopener' href='https://www.gleichklang.de/faq/'>link</a> }}/></p>
							<div className='home_btns'>
								<Button onClick={() => navigateToPage('suggestions')} className='suggest_btn' variant="success">
									<FontAwesomeIcon icon={faUserPlus} />
									{t('member.menubar.suggestions')}
									<Badge bg="warning">{homeDetails?.suggestions}</Badge>
									<span className="visually-hidden">{t('member.header.unread_suggestions')}</span>
								</Button>

								<Button onClick={() => navigateToPage('messenger')} className='msg_btn' variant="primary">
									<FontAwesomeIcon icon={faEnvelope} />
									{t('member.menubar.messages')}
									<Badge bg="warning">{homeDetails?.messages}</Badge>
									<span className="visually-hidden">{t('member.header.unread_messages')}</span>
								</Button>
							</div>
						</div>
					</div>

					<div className="news_footprint_container">
						{homeDetails?.news?.length ? 
						<News
						 news={homeDetails?.news} 
						 setNewsRefresh={setRefreshNews}
						 /> : 
						 <div className="news_section">
								<h3>{t('member.news.newsheading')}</h3>
								<p className="noContent">{t('member.news.noUnread')}</p>
							</div>
						 }
						{
							homeDetails?.footPrints?.length ? <Footprint footprint={homeDetails?.footPrints} /> : 
							<div className="footprint_section">
								<h3>{t('member.footprint.footprint')}</h3>
								<p className="noContent">{t('member.footprint.noFootprint')}</p>
							</div>
							}
					</div>
					
					<div className="membership_section">
						<div className="membership_texts">
							<h3> {t('member.yourmembership.membership')} </h3>
						      <p aria-label="button You have mail from us!">
								<Button className="membership_btn">{t('member.yourmembership.maildesc')} </Button>
								</p>
							<p aria-label="button Your subscription will run for 4 months!">
								<Button className="membership_btn">{t('member.yourmembership.subscription')}</Button>
							</p>
						</div>
						<div className="membershipIcon">
							<img src={membershipIcon} alt={t('member.yourmembership.membership')} />
						</div>
					</div>
				
				</div>
				
				<div className="home_profile">
					<section className="home_profile_section">
						<div className="home_profile_container">
								<div className="your_profile_texts">
									<h3>{t('member.yourprofile.yourprofile')}</h3>
									{
										incompleteQueData?.questionnaires && incompleteQueData.questionnaires !== null ?
										<div className="text_unans">
											<p>{t('member.yourprofile.text')}</p>
										</div> 
									: <div className="text_ans">
											<img src={thumbUp} alt="Thumb up" />
											<h2>{t('member.yourprofile.perfect')}</h2>
											<p>{t('member.yourprofile.allFilled')}</p>
										</div> 
									}									
									{incompleteQueData?.questionnaires?.map((data, index) => (
										<p 
										tabIndex='0'
										key={index}  
										className="label"
										onClick={() => getQuestionnaire(data.id, data.unAnsweredQuestions)}
										>{data.title} <FontAwesomeIcon icon={faPen} /></p>
									))}
								</div>
						</div>
						<div className='home_profile_popup'>
							{showQuestionnairePopup ?
								<IncompleteQuestionnaire
									questionnaire={loadQuestionnaire}
									show={showQuestionnairePopup}
									handleClose={closeQuestionnairePopup}
									setRefreshIncompleteQ={setRefreshIncompleteQ}
								/>
								: null}
						</div>
						<YourRelationships 
							incompleteQueData={incompleteQueData}
							setIncompleteQueData={setIncompleteQueData}
						/>
					</section>					
				</div>

				<div className="analyses_section">
					<h3> {t('member.analyses.analyses')} </h3>
					<p>  {t('member.analyses.description')}  </p>
					<div className="download_questionair_pdf">
						<div className="profile_pdf_sec" 
							onClick={() => {openPdf('personal')}}
							tabIndex="0" 
							aria-label="Download personality profile PDF">
							<div>
								<img src={Person1} alt="Personality profile link" />
							</div>
							<p>{t('member.analyses.personalityProfile')}</p>
						</div>
						<div className="profile_pdf_sec" 
							onClick={() => {openPdf('partnership')}}
							tabIndex="0" 
							aria-label="Download partnership profile PDF">
							<div>
								<img src={Person2} alt="Partnership profile link" />
							</div>
							<p>{t('member.analyses.partnershipProfile')}</p>
						</div>
						<div className="profile_pdf_sec" 
							onClick={() => {openPdf('companyprofile')}}
							tabIndex="0" 
							aria-label="Download social profile PDF">
							<div>
								<img src={Person3} alt="Social profile link" />
							</div>
							<p>{t('member.analyses.socialProfile')}</p>
						</div>
						<div className="profile_pdf_sec" 
							onClick={() => {openPdf('friendship')}}
							tabIndex="0" 
							aria-label="Download friendship profile PDF">
							<div>
								<img src={Person4} alt="Friendship profile link" />
							</div>
							<p>{t('member.analyses.friendshipProfile')}</p>
						</div>
					</div>
				</div>

				<div className="option_section">
					<h3> {t('member.option.option')} </h3>
					<div className="clear"></div>
					<div className="option_texts">
						<a href="https://www.gleichklang.de/optimierung/" target="_blank">{t('member.option.requestsupport')}</a>
						<p>{t('member.option.requestsupportText')}</p>
					</div>
					<div className="option_texts">
						<a href="/member/member/membership/subscription#upgrades">
						{t('member.option.forlife')}
						</a>
						<p> {t('member.option.forlifeText')} </p>
					</div>
					<div className="option_texts">
						<a href="https://de.research.net/r/effekte" target="_blank">{t('member.option.opportunities')}</a>
						<p> {t('member.option.opportunitiesText')} </p>
					</div>
					<div className="option_texts">
						<a href="https://www.gfgebauer.de/#coaching" target="_blank">{t('member.option.learn')}</a>
						<p> {t('member.option.learnText')} </p>
					</div>
					<div className="option_texts">
						<a href="https://gleichklang-studien.de/" target="_blank">{t('member.option.yourself')}</a>
						<p> {t('member.option.yourselfText')} </p>
					</div>
				</div>

				<div className="community_sec">
					<h3>{t('member.community.community')} </h3>
					<p>{t('member.community.communityDesc')}</p>
					<div className="community_items">
						<div className="community_box">
							<img src={community_icon_1} alt={t('member.community.imageFor')+" "+t('member.community.order')}/>
								<a target="_blank" href="https://www.gleichklang.de/flyer/">{t('member.community.order')} <FontAwesomeIcon icon={faChevronCircleRight} /></a>
						</div>
						<div className="community_box">
							<img src={community_icon_2} alt={t('member.community.imageFor')+" "+t('member.community.recommend')}/>
								<a target="_blank" href="https://www.gleichklang.de/werbemoeglichkeit-empfehlen/">{t('member.community.recommend')} <FontAwesomeIcon icon={faChevronCircleRight} /></a>
						</div>
						<div className="community_box">
							<img src={community_icon_3} alt={t('member.community.imageFor')+" "+t('member.community.email')}/>
									<a target="_blank" href="https://www.gleichklang.de/freunde-zu-gleichklang-einladen/">{t('member.community.email')} <FontAwesomeIcon icon={faChevronCircleRight} /></a>
						</div>
						<div className="community_box">
							<img src={community_icon_4} alt={t('member.community.imageFor')+" "+t('member.community.mail')}/>
									<a target="_blank" href="https://www.gleichklang.de/freunde-zu-gleichklang-einladen/#perpost">{t('member.community.mail')} <FontAwesomeIcon icon={faChevronCircleRight} /></a>
						</div>
					</div>
				</div>				
			  {
				  mailConfirmation?.email != null && isConfirmMailPopup === true && (
					  <MailConfirmation
						  showPopup={!mailConfirmation?.isMailConfirm}
						  close={closeMailConformation}
						  email={mailConfirmation?.email}
						  handleShowToast={handleShowToast}
					  />
				  )
			  }
			<Toaster toastMessage={toastMessage} toastRef={toastRef} type={'success'}/>

					
			</main>

    </>
  );
}

export default Home;