import React, { useState, useRef, useEffect, useContext } from 'react'
import './questionnaire.css';
import Button from 'react-bootstrap/Button';
import { questionaireAnswer, completeRegistration } from '../registrationProcessService'
import { LoginContext } from '../../../context/loginContext';
import { useTranslation } from "react-i18next";
import RegionalSearch from '../regionalSearch';
import DescriptionAlert from '../descriptionAlert';
import { autoFocusByID } from '../../commonMethods';
import usePreventBack from '../preventBack/preventBack';
import 'draft-js/dist/Draft.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    EditorState,
    ContentState,
    convertFromHTML,
} from "draft-js";
import ChoiceQuestions from './ChoiceQuestions';
import NumberQuestions from './NumberQuestions';
import TextQuestions from './TextQuestions';

function Questionaire(props) {
    const [answers, setAnswers] = useState({})
    const [errResponse, setErrResponse] = useState({ errorMessageByQuestionId: {} })
    const [idescription, setIdescription] = useState();
    const [isIAlertOpen, setIsIAlertOpen] = useState(false);
    const [freeTextError, setFreeTextError] = useState(null);
    const [freeTextId, setFreeTextId] = useState(null);
    const [errorIds, setErrorIds] = useState([]);
    const [errorMgsByID, setErrorMgsByID] = useState("");
    const [editorStates, setEditorStates] = useState({});
    const [selectedChildrenAge, setSelectedChildrenAge] = useState([]);
    const [numberOfChildren, setNumberOfChildren] = useState()
    const [childrenAgeData, setChildrenAgeData] = useState(null)
    const [childrenObjectData, setChildrenObjectData] = useState(null)
    const [isIncompleteProximity, setIsIncompleteProximity] = useState(false)
    const [showProximityAlert, setShowProximityAlert] = useState(false)
    const [communityContactErrors, setCommunityContactErrors] = useState([])

    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;
    const scrollRef = useRef(null);
    const questionRefs = useRef(new Map());
    const handleClose = () => setIsIAlertOpen(false);

    const onAlertOpen = (description) => {
        setIsIAlertOpen(true);
        setIdescription(description)
    }

    let {
        loginData: {
            accessToken
        },
        dispatch
    } = useContext(LoginContext);

    usePreventBack();

    useEffect(() => {
        errorIds.sort(function (a, b) {
            return a.gQIndex - b.gQIndex || a.qIndex - b.qIndex; 
        });
        if (errorIds.length > 0) {
            const firstErrorElement = questionRefs.current.get(errorIds[0].QID);
            if (firstErrorElement) {
                firstErrorElement.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.scrollTo(0, 0)
        }
    }, [errorIds]);

    useEffect(() => {
        props?.questionaire?.questionGroups?.filter((e) => e.id === 121).map((questionGroup, index) => {
            setChildrenObjectData(questionGroup);
            questionGroup?.questions?.filter((e) => e.id === 202).map((question) => {
                setNumberOfChildren(question.numberQuestion.answer);
            });
            questionGroup?.questions?.filter((e) => e.id === 204).map((question) => {
                setChildrenAgeData(question)
                setSelectedChildrenAge(
                    question.textQuestion?.answer
                        ?.split(',')
                        .map(item => parseInt(item.trim(), 10))
                        .filter(value => !isNaN(value))
                );
            });
        });
    }, [props]);

    const handleFreeText = (e, question) => {
        if (question.required) {
            const answerText = e.target.value.trim();
            let lineCount = answerText.split(/\r|\r\n|\n/).length > 10;
            let errorMessage = "";
            if (e.target.value?.length >= 1 && e.target.value.trim() == "") {
                errorMessage = t('suggestions.onlySpacesNotAllowed');
            } 
            else if (e.target.value === "") {
                errorMessage = t('registration.questionnaires.fieldRequired');
            } 
            if (answerText.length < 200) {
                errorMessage = t('suggestions.freetextlimit');
            }  
            if (answerText.length > 800) {
                errorMessage = t('suggestions.freetextlimit800');
            }  
            if (lineCount) {
                errorMessage = t('suggestions.freetextline');
            }

            if (
                e.target.value === "" ||
                answerText.length < 200 ||
                answerText.length > 800 ||
                lineCount
            ) {
                updateErrorState(errorMessage, question.id);

                const errorExists = communityContactErrors.some(
                    (error) => error.id === question.id
                );

                if (!errorExists) {
                    communityContactErrors.push({ errorMessage, id: question.id });
                }
                setCommunityContactErrors([...communityContactErrors]);
                return true;
            }
            else {
                clearErrorState(question.id);
                setCommunityContactErrors(communityContactErrors?.filter(
                    (error) => error.id != question.id
                ))
                return false;
            }
        }
    }        

    const forQuetionaireAnswer = () => {
        const newErrorIds = [];
        if (isIncompleteProximity== true) {
            setShowProximityAlert(true);
            return false;
        } else {
            props.questionaire?.questionGroups?.forEach((qgroup, qgIndex) => {
                qgroup.questions.forEach((data, qIndex) => {
                    if (data.required && (!answers[data.id] || answers[data.id].answerValues?.length === 0 ||answers[data.id].answerValues?.includes(""))) {
                        newErrorIds.push({'gQIndex': qgIndex, 'qIndex': qIndex, 'QID':  data.id.toString(), 'isMinMax': false})
                    }
                });
            });
        }

        var newErrorIdsMerge = null;
        const valMulRangeRe = validateMulRangeResubmit();
        if(valMulRangeRe.isSubmit) {
            newErrorIdsMerge = newErrorIds.concat(valMulRangeRe.errorQID);
        } else {
            newErrorIdsMerge = newErrorIds;
        }
        setErrorIds(newErrorIdsMerge);
        validateUIResubmit(newErrorIdsMerge)
        if (communityContactErrors.length != 0) {
            const firstErrorElement = questionRefs.current.get(communityContactErrors?.[0].id.toString());            
            setTimeout(() => {
                if (firstErrorElement) {
                    firstErrorElement.scrollIntoView({ behavior: 'smooth' });
                }                
            }, 300);
            return;
        }

        if(valMulRangeRe.isSubmit) return;

        const answerBody = {
            questionnaireId: props?.questionaire?.id,
            answers: answers
        };
        try {

            let cleanedAnswerBody = { ...answerBody };
            let cleanedRequest = {};
            for (let key in cleanedAnswerBody?.answers) {
                if (!cleanedAnswerBody?.answers[key]?.answerValues?.includes("")) {
                    cleanedRequest[key] = cleanedAnswerBody.answers[key];
                } else {
                    delete cleanedAnswerBody.answers[key];
                }
            }

            questionaireAnswer(cleanedAnswerBody, accessToken, currentLang)
                .then((res) => {
                    if (res?.status === 200) {
                        setAnswers({});
                        setErrResponse({errorMessageByQuestionId: {}});
                        dispatch({ type: 'CURRENT_URL', data: res.data });
                        if (res.data?.nextQuestionnaire !== null) {
                            props.setQuestionaireId(res.data?.nextQuestionnaire);
                        } else {
                            props.setQuestionaireId(res.data?.nextStep);
                        }
                    } else {
                        setErrResponse(res.data);
                    }
                })
        }
        catch (error) {
        }
    };

    const handleOptionClick = (question, answer, questionGroup, editorState) => {
        if (question?.id === 202 && 1 <= answer && answer <= 20) {
            setNumberOfChildren(answer);
            if (answer < selectedChildrenAge?.length) {
                setSelectedChildrenAge([]);
                handleOptionClick(childrenAgeData, [''], childrenObjectData);
            }
        }
        if (question.type === 'TextQuestion' && question.textQuestion.type === "RichTextArea") {
            setFreeTextError(null)
            setFreeTextId(question.id)
            let plainText = editorState?.getCurrentContent().getPlainText();

            if (question.required === true && plainText?.length >= 200 && errResponse?.errorMessageByQuestionId?.[question.id]) {
                let errResponseTemp = errResponse
                delete errResponseTemp.errorMessageByQuestionId[question.id]
                setErrResponse(errResponseTemp)
            }
        }
        if (question.type === 'ChoiceQuestion' && Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).length > 0) {
            if (question.choiceQuestion.multiSelect === true) {
                let shouldEnable = false
                Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                    question.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                        shouldEnable = parseInt(answer) === enablerOption ||
                            (answers[question.id] && answers[question.id].answerValues.indexOf(enablerOption) >= 0);
                        return shouldEnable;
                    })
                    const tempQn = { ...props.questionaire }
                    tempQn.questionGroups.filter(qg => qg.id === questionGroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable
                    props.setStoreQuestionaire(tempQn)
                })

                if (answers[question.id] && answers[question.id].answerValues.indexOf(answer) < 0) {
                    answers[question.id].answerValues = [...answers[question.id].answerValues, answer]
                    updatedAnswersValues(answers, questionGroup)
                } else if (!answers[question.id]) {
                    answers[question.id] = { "answerValues": [answer], "profileVisible": false }
                    updatedAnswersValues(answers, questionGroup)
                }
                return;
            }
            else {
                let shouldEnable = false;
                Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                    question.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                        shouldEnable = parseInt(answer) === enablerOption
                        return shouldEnable;
                    })
                    const tempQn = { ...props.questionaire }
                    tempQn.questionGroups.filter(qg => qg.id === questionGroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable
                    if (!shouldEnable) {
                        answers[key] = { "answerValues": [], "profileVisible": false }
                        hideChild(key, questionGroup.id, tempQn, answers)
                    }
                    
                    if (!shouldEnable) {
                        setCommunityContactErrors( communityContactErrors?.filter(
                            (error) => error.id != Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys)?.[0]
                        ))
                    }                  
                    props.setStoreQuestionaire(tempQn)
                })
            }
        }
        if (question.type === 'ChoiceQuestion' && question.choiceQuestion.multiSelect === true) {
            if (answers[question.id] && answers[question.id].answerValues.indexOf(answer) < 0) {
                answers[question.id].answerValues = [...answers[question.id].answerValues, answer]
            } else if (!answers[question.id]) {
                answers[question.id] = { "answerValues": [answer], "profileVisible": false }
            }
            updatedAnswersValues(answers, questionGroup)
        } else {
            if (answers[question.id]) {
                if (question.id == 204) {
                    answers[question.id].answerValues = answer;
                }
                else {
                    answers[question.id].answerValues = [answer]
                }
            } else {
                answers[question.id] = { "answerValues": [answer], "profileVisible": false }
            }
            updatedAnswersValues(answers, questionGroup)
        }
    };    

    const updatedAnswersValues = (answers, questionGroup) => {
        const newAnswers = {...answers};
        const newErrorIds = [];
        for (let key in answers) {
            if (Object.keys(answers[key]).length !== 0 && !answers[key]?.answerValues?.includes("")) {
                newAnswers[key] = answers[key];
            } else {
                const isMinMax = questionGroup?.questions.filter((val) => (val.id == key && val.numberQuestion !== null && val.numberQuestion.minValue)).length > 0 ? true : false;
                newErrorIds.push({'QID': key.toString(), 'isMinMax': isMinMax})
                delete newAnswers[key];
            }
        }
        validateUIResubmit(newErrorIds);
        setAnswers(newAnswers);
    }

    const hideChild = (parentId, qgroupId, storeQuestionnaire, savedAnswers) => {
        const questionGroup = storeQuestionnaire.questionGroups.filter(qg => qg.id === qgroupId)[0]
        const parentQuestion = questionGroup.questions.filter(ques => ques.id === parseInt(parentId))[0]
        if (parentQuestion.type === 'ChoiceQuestion' && Object.keys(parentQuestion.choiceQuestion.enabledQuestionActivatingChoiceKeys).length > 0) {
            Object.keys(parentQuestion.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                savedAnswers[key] = {}
                storeQuestionnaire.questionGroups.filter(qg => qg.id === qgroupId)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = false;
            })
        }
    }
    
    const validateUIResubmit = (errorIds) => {        
        //Resolving the “State Not Updating Immediately” Issue in React.js
        setErrResponse((prevItems) => {
            for (const error of errorIds) {
                if(!error.isMinMax) prevItems.errorMessageByQuestionId[error.QID] = t('registration.questionnaires.fieldRequired');
            }
            return prevItems;
        });
    }
    const validateMulRangeResubmit = () => {
        const ele = document.querySelectorAll('input[min]');
        let isSubmit = false;
        let errorQID = [];
        if(ele.length > 0) {
            const eventTarget = {};
            for (const element of ele) {
                const qId =  element.id;
                const GqId = element.getAttribute('data-qid');
                const gqindex = element.getAttribute('data-gqindex');
                const qIndex = element.getAttribute('data-qindex');
                eventTarget.target = {value: element.value, min: element.min, max: element.max}
                if(element.value) {
                    const res = validateMultipleRanges(eventTarget, qId, GqId)
                    if(res){
                        isSubmit = true
                        errorQID.push({'gQIndex': Number(gqindex), 'qIndex': Number(qIndex), 'QID':  qId.toString(), 'isMinMax': true})
                    }
                }
            }
            return {isSubmit: isSubmit, errorQID: errorQID};
        }
        return false;
    }

    const updateErrorState = (message, qId) => {
        //Resolving the “State Not Updating Immediately” Issue in React.js
        setErrResponse((prevItems) => {
            prevItems.errorMessageByQuestionId[qId] = message;
            return prevItems;
        });
        setErrorMgsByID(message);
    }

    const clearErrorState = (qId) => {
        //Resolving the “State Not Updating Immediately” Issue in React.js
        setErrResponse((prevItems) => {
            if (prevItems.errorMessageByQuestionId && prevItems.errorMessageByQuestionId[qId]) {
                delete prevItems.errorMessageByQuestionId[qId];
            }
            return prevItems;
        });
        setErrorMgsByID('');
    };

    const validateMultipleRanges = (e, qId, GqId) => {
        let ele = null;
        if (document.querySelectorAll(`[data-qid='${GqId}']`).length > 0) {
            ele = document.querySelectorAll(`[data-qid='${GqId}']`)
        } else {
            ele = document.querySelectorAll(`[data-qid='${qId}']`)
        }
        if (ele.length >= 2) {
            let range = [];
            ele.forEach((v, k) => {
                range.push({ value: v.value ? Number(v.value) : '', Gqid: GqId, qId: v.id, minRange:  Number(v.min), maxRange: Number(v.max), isFocus: (qId == v.id) });
            });

            var newErrorIdsMes = [];
            if(range[0].value !== '' || range[1].value !== '') {
                range.forEach((val, i) => {
                    const minRange = val.minRange;
                    const maxRange = val.maxRange;
                    const currentValue = val.value;
                    if(i == 0) {
                        if(range[0].value != '' && (currentValue < minRange || currentValue > maxRange)) {
                            newErrorIdsMes.push({qId: val.qId, message: t("registration.questionnaires.minMaxRangeError", { minRange, maxRange })})
                        }
                        if(range[0].value != '' && (currentValue >= minRange || currentValue <= maxRange)) {
                            clearErrorState(range[0].qId);
                        }
                    }
                    // Check second of max value
                    if(i == 1) {
                        const firstValue = range[0].value;
                        const secondValue = range[1].value;
                        const resMinMax = secondValue - firstValue;
                        // Condition Mix/Max Age
                        if((val.Gqid == 2 || val.Gqid == 16) && (range[1].value != '' && range[0].value != '') && resMinMax >= 5) {
                            clearErrorState(val.qId);
                        }
                        if(val.Gqid == 13 && (range[1].value != '' && range[0].value != '') && resMinMax >= 10) {
                            clearErrorState(val.qId);
                        }
                        if((val.Gqid == 2 || val.Gqid == 16) && range[1].value != '' && range[0].value == '') {
                            newErrorIdsMes.push({qId: val.qId, message: t("registration.questionnaires.minAge")})
                        }
                        if(val.Gqid == 13 && range[1].value != '' && range[0].value == '') {
                            newErrorIdsMes.push({qId: val.qId, message: t("registration.questionnaires.minHeight")})
                        }
                        if(range[1].value != '' && (currentValue < minRange || currentValue > maxRange)) {
                            newErrorIdsMes.push({qId: val.qId, message: t("registration.questionnaires.maximumValue", { minRange, maxRange })})
                        }
                        if(range[1].value == '') {
                            newErrorIdsMes.push({qId: val.qId, message: t("registration.questionnaires.maximumValue", { minRange, maxRange })})
                        }
                        // Check two value has amount and (at least 5 year for age/ at least 10 cm for height)
                        if(range[0].value !== '' && range[1].value !== '') {
                            if ((val.Gqid == 2 || val.Gqid == 16) && resMinMax < 5) {
                                newErrorIdsMes.push({qId: val.qId, message: t("registration.questionnaires.maximumRange", { minRange, maxRange })})
                            }
                            if (val.Gqid == 13 && resMinMax < 10) {
                                newErrorIdsMes.push({qId: val.qId, message: t("registration.questionnaires.maximumHeight", { minRange, maxRange })})
                            }
                        }
                    }
                });
                if(newErrorIdsMes.length > 0) {
                    range.forEach((vErr, i) => {
                        const rsMessage = newErrorIdsMes.filter((vf) => vf.qId == vErr.qId).map((item) => item.message);
                        if(rsMessage.length > 0) updateErrorState(rsMessage, vErr.qId);
                    });

                    const isErrorCurrentInput = newErrorIdsMes.filter((vf) => vf.qId == qId);
                    //Check event using key tab
                    if(e.keyCode == 9 && isErrorCurrentInput.length == 0) {
                        const isFirstInput = newErrorIdsMes.filter((vf) => vf.qId == range[0].qId);
                        //Check error first input and cursor go back first input
                        if(isFirstInput.length > 0) autoFocusByID(range[0].qId);
                        return false;
                    }
                    return true;
                } else {
                    range.forEach((vErr, i) => {
                        clearErrorState(vErr.qId);
                    })
                    return false;
                }
            } else {
                // If user leave blank, we don't check the min/max value
                range.forEach((vErr, i) => {
                    clearErrorState(vErr.qId);
                });
                return false;
            }
        } else {
            // Check only one field Mix/Max only
            const minRange = Number(e.target.min);
            const maxRange = Number(e.target.max);
            const currentValue = (e.target.value == '') ? '' : Number(e.target.value);
            const isRequired = document.getElementById(qId).getAttribute('aria-required');
            if ((currentValue !== '' || isRequired == 'true') && (currentValue < minRange || currentValue > maxRange)) {
                updateErrorState(t("registration.questionnaires.minMaxError", { minRange, maxRange }), qId);
                return true;
            } 
            clearErrorState(qId);
            return false;
        }
    };

    const autofillAnswers = () => {
        const updatedAnswers = {};

        props.questionaire?.questionGroups?.forEach((qgroup) => {
            qgroup.questions.forEach((question) => {
                if (question.type === 'ChoiceQuestion') {
                    if (question.choiceQuestion?.choices.length > 0) {
                        const firstChoiceId = question.choiceQuestion.choices[1].id;
                        updatedAnswers[question.id] = { "answerValues": [firstChoiceId], "profileVisible": false };
                    }
                }
                if (question.type === 'TextQuestion' || question.type === 'NumberQuestion') {
                    if (question.id == 179) {
                        updatedAnswers[question.id] = { "answerValues": [123], "profileVisible": false };
                    } else if(question.numberQuestion){
                        //Check if user enter on the field Mix/Max
                        const valAnswer = document.getElementById(question.id) != null ? document.getElementById(question.id).value : '';
                        if(valAnswer != '') {
                            updatedAnswers[question.id] = { "answerValues": [valAnswer], "profileVisible": false }
                        } else {
                            updatedAnswers[question.id] = { "answerValues": [], "profileVisible": false }
                        }
                    } else {
                        updatedAnswers[question.id] = { "answerValues": [], "profileVisible": false }
                    };
                    if (question?.textQuestion?.type == 'RichTextArea' && (question.id == 172 || question.id == 165)) {
                        const freeTextContent = "<p>With Gleichklang you can now take the decisive step on the way to a happy partnership or a real friendship After registration you can view and fill in all questionnaires and view your results. This is not binding and you are not obliged to decide to use the paid placement service.This is not binding and you are not obliged to decide to use the paid placement service.<p>";
                        const initialEditorState =
                            EditorState.createWithContent(ContentState.createFromBlockArray(
                                convertFromHTML(freeTextContent).contentBlocks,
                                convertFromHTML(freeTextContent).entityMap
                            ))

                        setEditorStates(prevEditorStates => ({
                            ...prevEditorStates,
                            [question.id]: initialEditorState
                        }));
                        updatedAnswers[question.id] = { "answerValues": [freeTextContent], "profileVisible": false };
                    }
                }
            });
        });
        setErrResponse({errorMessageByQuestionId: {}})
        setAnswers(updatedAnswers);
        setTimeout(()=> {
            const valMulRangeRe = validateMulRangeResubmit();
            if(valMulRangeRe.isSubmit) {
                setErrorIds(valMulRangeRe.errorQID);
            }
        }, 500);
    };

    const questionReference = (question, evt) => {
        if (evt) {
            questionRefs.current.set(question.id.toString(), evt);
        } else {
            questionRefs.current.delete(question.id.toString());
        }
    }

    const autoRegister = () => {
        completeRegistration(accessToken).then((result) => {
            if (result?.status === 200) {
                props.setQuestionaireId(result.data?.nextStep);
            } else {
                setErrResponse(result.data);
            }
        })
    }


    let questionnaireHeader;
    if (props.questionaire?.id == props.allQuestionairIds?.partner?.key) { //Partnership 1
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.partnershipHeader')}</strong> &ndash; {t('member.preregistration.step1of3')}</h3>
            <h4>{t('registration.questionnaires.searchingForHeader')}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.partnerschaft?.key) { //Partnership 2
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.partnershipHeader')}</strong> &ndash; {t('member.preregistration.step2of3')}</h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.['partner.region_question']?.key) { //Partnership 3
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.partnershipHeader')}</strong> &ndash; {t('member.preregistration.step3of3')}</h3>
            <h4>{t('registration.questionnaires.reginalSearchHeader')}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.freund?.key) { //Friendship 1
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.friendshipHeader')}</strong> &ndash; {t('member.preregistration.step1of3')}</h3>
            <h4>{t('registration.questionnaires.searchingForHeader')}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.freundschaft?.key) { //Friendship 2
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.friendshipHeader')}</strong> &ndash; {t('member.preregistration.step2of3')}</h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.['friend.region_question']?.key) { //Friendship 3
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.friendshipHeader')}</strong> &ndash; {t('member.preregistration.step3of3')}</h3>
            <h4>{t('registration.questionnaires.reginalSearchHeader')}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.kontaktliste?.key) { //Personal Details 1
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step1of5')}</h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.person?.key) { //Personal Details 2
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step2of5')}</h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.aussehen?.key) { //Personal Details 3
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step3of5')}</h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.persoenlichkeit?.key) { //Personal Details 4
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step4of5')}</h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.hobby_v?.key) { //Personal Details 5
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step5of5')}</h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.gesellschaft?.key) { //Social Engagement 1
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.socialEng')}</strong> &ndash; {t('member.preregistration.step1of1')}</h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.ptext?.key) { //Self description partnership
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.m_notification.yourPartnershipProfile')}</strong></h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.ftext?.key) { //Self description friendship
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.m_notification.yourFriendshipProfile')}</strong></h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.registration?.key) { //Member Survey
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.m_notification.helpUs')}</strong></h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else {
        questionnaireHeader = <h4>{props.questionaire?.name}</h4>;
    }

    return (
        <div className={"questionnaire_form questionnaire" + props.questionaire?.id}>
            <div className="looking_for">
                {questionnaireHeader}
                <p dangerouslySetInnerHTML={{ __html: props.questionaire?.description }}></p>
            </div>
            {import.meta.env.VITE_ENV != 'PROD' ? <div className="mt-3 mb-3 auto_fill_wrapper">
                {(props.questionaire?.id == 14 || props.questionaire?.id == 4) ? null :
                    <Button
                        className='mx-5'
                        variant="secondary"
                        onClick={() => autoRegister()}
                    >
                        {t('registration.buttons.autoregister')}
                    </Button>
                }
                {props.questionaire?.id == 12 ||
                    props.questionaire?.id == 13 ? null : <Button
                        className='mx-5'
                        variant="success"
                        onClick={autofillAnswers}
                    >
                    {t('registration.buttons.autofill')}
                </Button>}
                <Button
                    className='mx-5'
                    variant="primary"
                    onClick={() => forQuetionaireAnswer()}
                >
                    {t('registration.buttons.next')}
                </Button>
            </div> : null}
            <div className='from_control questionair_container' id='questionnaire_container' ref={scrollRef}>
                {props.questionaire?.questionGroups?.map((qgroup, gqIndex) => (
                    <div key={qgroup.name}>
                        <div className='questionair_header'>
                            <h3 className='questionair_heading'>{qgroup.name}</h3>
                        </div>
                        <div className="questions_wrapper">
                            <p className="group_description" dangerouslySetInnerHTML={{ __html: qgroup.description }}></p>
                            {
                                qgroup.questions.map((question, i) => {

                                    if (question.type === 'ChoiceQuestion') {
                                        return (
                                            <ChoiceQuestions
                                                key={`ChoiceQuestion-${question.id || i}`}
                                                question={question}
                                                questionReference={questionReference}
                                                errResponse={errResponse}
                                                answers={answers}
                                                setAnswers={setAnswers}
                                                onAlertOpen={onAlertOpen}
                                                qgroup={qgroup}
                                                handleOptionClick={handleOptionClick}
                                                questionaire={props?.questionaire}
                                                setStoreQuestionaire={props.setStoreQuestionaire}
                                                updateErrorState={updateErrorState}
                                                clearErrorState={clearErrorState}
                                            />
                                        )
                                    }

                                    if (question.type === 'NumberQuestion')
                                        return (
                                            <NumberQuestions
                                                key={`NumberQuestion-${question.id || i}`}
                                                question={question}
                                                questionReference={questionReference}
                                                errResponse={errResponse}
                                                answers={answers}
                                                setAnswers={setAnswers}
                                                onAlertOpen={onAlertOpen}
                                                qgroup={qgroup}
                                                handleOptionClick={handleOptionClick}
                                                updateErrorState={updateErrorState}
                                                clearErrorState={clearErrorState}
                                                errorMgsByID={errorMgsByID}
                                                validateMultipleRanges={validateMultipleRanges}
                                                gqIndex={gqIndex}
                                                qIndex={i}
                                            />
                                        )

                                    if (question.type === 'TextQuestion')
                                        return (
                                            <TextQuestions
                                                key={`TextQuestion-${question.id || i}`}
                                                question={question}
                                                questionReference={questionReference}
                                                errResponse={errResponse}
                                                answers={answers}
                                                setAnswers={setAnswers}
                                                onAlertOpen={onAlertOpen}
                                                qgroup={qgroup}
                                                handleOptionClick={handleOptionClick}
                                                updateErrorState={updateErrorState}
                                                clearErrorState={clearErrorState}
                                                errorMgsByID={errorMgsByID}
                                                validateMultipleRanges={validateMultipleRanges}
                                                editorStates={editorStates}
                                                setEditorStates={setEditorStates}
                                                freeTextError={freeTextError}
                                                freeTextId={freeTextId}
                                                numberOfChildren={numberOfChildren}
                                                setSelectedChildrenAge={setSelectedChildrenAge}
                                                selectedChildrenAge={selectedChildrenAge}
                                                handleFreeText={handleFreeText}    
                                                communityContactErrors={communityContactErrors}                                            
                                            />
                                        )

                                    if (question.type === 'RegionQuestion')
                                        return (
                                            <RegionalSearch
                                                key={`region-${question.id}`}
                                                question={question}
                                                questionaireId={props.questionaire?.id}
                                                setAnswers={setAnswers}
                                                isFromRegistration={true} 
                                                isIncompleteProximity={isIncompleteProximity} 
                                                setIsIncompleteProximity={setIsIncompleteProximity} 
                                                showProximityAlert={showProximityAlert} 
                                                setShowProximityAlert={setShowProximityAlert}
                                            />
                                        )
                                })
                            }
                        </div>
                    </div>

                ))}
                <div className="mt-3 step_one_next">
                    <Button
                        className='mx-5'
                        variant="primary"
                        onClick={() => forQuetionaireAnswer()}
                    >
                        {t('registration.buttons.next')}
                    </Button>
                </div>
            </div>
            {isIAlertOpen ?
                <DescriptionAlert
                    description={idescription}
                    show={isIAlertOpen}
                    handleClose={handleClose}
                /> : null}
        </div>
    )
}

export default Questionaire;