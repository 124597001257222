import {React, useState, useRef, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import imageLogo from '../../../assets/images/icon_profile_white-sugg1-svg.svg';
import { LoginContext } from '../../../context/loginContext';
import { useTranslation } from 'react-i18next';
import Cropper from './profileImageCropper';
import ViewAllGalleries from './viewAllGalleries';
import { deleteAvatar } from '../personalDetailsService';
import { Form, Button, Modal, ModalBody, Spinner} from "react-bootstrap";
import Toaster from '../../Toaster/Toaster';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';


function UploadImage(props) {    
    const [uploadType, setUploadType] = useState(null);
    const [imageBeforeSave, setImageBeforeSave] = useState(null);
    const [profileImg, setProfileImg] = useState();
    const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(false)
    const [isImageCrop, setIsImageCrop] = useState(false);
    const [showAllGalleries, setShowAllGalleries] = useState(false);
    const [confirmDeleteAvatar, setConfirmDeleteAvatar] = useState(false)
    
    let {
        loginData: {
          accessToken
        }, 
      } = useContext(LoginContext);
      const { t, i18n } = useTranslation();
    const inputImageSelect = useRef();


    const UploadFile = (e) => {
        e.preventDefault();
        if (e.target.files?.[0]) {
            const fileData = e.target.files[0];
            setProfileImg(fileData)
            setImageBeforeSave(URL.createObjectURL(e.target.files[0]));
        }
        setIsImageCrop(true)
    }

      const onImageSave = () => {
            setUploadType(props.type)
            inputImageSelect.current.click()
      }
    
    const openAllGalleriesPopup = async() => {
        setShowAllGalleries(true)
        setUploadType(props.type)
    }

    const closeAllGalleriesPopup = () => {
        setShowAllGalleries(false)
    }

    const base64Image = (image) => {
        return `data:image/png;base64,${image}`;
    };

    const removeAvatar = () => {
        const headers = {
            language: i18n.language,
            accessToken
        }
        deleteAvatar(headers, props.type).then((response) => {
            if (response?.status === 200) {
                props.setAvatar(null);
                setConfirmDeleteAvatar(false);
                handleShowToast();
            }
        })
    }

    const close = () => {
        setConfirmDeleteAvatar(false);
    }

    const toastRef = useRef();
    let toastMessage = t('myprofile.delete_successfully')
    const handleShowToast = () => {
        const toastElement = toastRef.current;
        const options = {
            animation: true,
            autohide: true,
            delay: 5000,
        };
        if (toastElement) {
            const bootstrapToast = new bootstrap.Toast(toastElement, options);
            bootstrapToast.show();
        }
    };


    return (
        <div className="modal show">
            <Modal className={'incomplete_q_modal '+props.type} show={props.show} onHide={() => props.handleClose()} >
                <Modal.Header closeButton>
                    <div className="profile_logo_name">
                        <div className="profile_info">
                            <h3 className="partnership_color"><FontAwesomeIcon icon={faUser} /> Avatar</h3>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='incomplete_questionaire'>
                    <div className="">
                        <div className="descriptions">
                            <p>{t('myprofile.avtardesc')}</p>
                            <p>{t('myprofile.category')}</p>
                        </div>
                        <h3 className="headings">
                            <img src={require("../../../assets/images/icon_category_partnership.png")} alt="Partnership Icon" className="categoryImage" /> 
                                {props.type == 'partnership' ? t('myprofile.partnership') : t('myprofile.friendship')}
                            </h3>
                    </div>
                    {!isImageCrop ? (
                        <>
                            <div className='profile_image'>
                                {props.isImageLoading ? <Spinner /> : <>
                                    {imageBeforeSave ? <img src={imageBeforeSave} alt='myprofileImg' /> : <img src={props.avatarUrl ? props.avatarUrl : imageLogo} alt='myprofileImg' />}
                                </>}
                            </div>
                            <div className="uploadimagebtn">
                                <div className="left-buttons">
                                    <Button
                                        disabled={isSaveBtnDisable}
                                        onClick={() => onImageSave()}
                                        variant="success">
                                        {profileImg ? t('myprofile.confirmsave') : t('myprofile.addimage')}
                                    </Button>
                                    <Button
                                        variant="success"
                                        onClick={() => openAllGalleriesPopup()}>
                                        {t('myprofile.fromgallery')}
                                    </Button>
                                </div>
                                {props.avatarUrl ? <div className="right-button">
                                    <Button
                                        variant="primary"
                                        onClick={() => setConfirmDeleteAvatar(true)}>
                                        {t('myprofile.delete_avatar')}
                                    </Button>
                                </div> : null}
                                <div className='card_input'>
                                    <input
                                        id="file-upload"
                                        accept="image/*"
                                        ref={inputImageSelect}
                                        name="audio-and-image-upload"
                                        onChange={(e) => UploadFile(e)}
                                        type="file"
                                        aria-label="Upload File"
                                    />
                                </div>
                            </div>
                            <>
                                <ViewAllGalleries
                                    close={closeAllGalleriesPopup}
                                    show={showAllGalleries}
                                    base64Image={base64Image}
                                    type={props.type}
                                    setAvatar={props.setAvatar}
                                    handleClose={props.handleClose}
                                    setImageCrop={setIsImageCrop}
                                    setImageBeforeSave={setImageBeforeSave}
                                />
                            </>
                        </>
                    ) : (
                        <Cropper
                            show={isImageCrop}
                            setShow={setIsImageCrop}
                            currentImage={imageBeforeSave}
                            setImages={setImageBeforeSave}
                            uploadType={uploadType}
                            setUploadType={setUploadType}
                            handleClose={props.handleClose}
                            setAvatar={props.setAvatar}
                            // isAvatarHomePage={props.isAvatarHomePage}
                        />
                    )}
                    <Toaster toastMessage={toastMessage} toastRef={toastRef} type={'success'}/>
                </Modal.Body>
            </Modal>
            <Modal className="registration_modal" show={confirmDeleteAvatar}>
                <ModalBody>
                    <Form>
                        <Form.Group
                            className="mb-3"
                            controlId="popupForm.ControlInput1"
                        >
                            <Form.Label>
                                {t('myprofile.confirm_delete')}
                            </Form.Label>
                        </Form.Group>
                    </Form>
                </ModalBody>
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={(e) => close()}>
                        {t("registration.buttons.cancel")}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={() => removeAvatar()}
                    >
                        {t("registration.buttons.proceed")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UploadImage;