import React, { useContext, useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ModalBody, Spinner } from 'react-bootstrap';
import { LoginContext } from '../../../context/loginContext.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import UserIcon from '../../../assets/images/user-icon01.png';
import heic2any from 'heic2any';
import Loader from '../../../components/spinner.jsx';
import { getAllGalleries, updateImageMetadata, getImage } from '../personalDetailsService.jsx';
import Alert from 'react-bootstrap/Alert';

function ViewAllGalleries(props) {
    const { close, show, base64Image, type, setAvatar, setImageCrop, setImageBeforeSave } = props;
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
    const [galleryImages, setGalleryImages] = useState([]);
    const [isGalleryLoading, setIsGalleryLoading] = useState(false);
    const [allGalleries, setAllGalleries] = useState(null)
    const [selectedImageId, setSelectedImageId] = useState(null);
    const [selectedGalleryId, setSelectedGalleryId] = useState(null);
    const [selectedImageURL, setSelectedImageURL] = useState(null)
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [avatars, setAvatars] = useState([]);

    const { t, i18n } = useTranslation();
    let {
        loginData: {
            accessToken
        },
    } = useContext(LoginContext);

    useEffect(() => {
        const payloadData = {
            accessToken: accessToken,
            language: i18n.language
        }
        setIsGalleryLoading(true)
        getAllGalleries(payloadData).then((result) => {
            if (result?.status === 200) {
                setAllGalleries(result.data.galleries)
                setIsGalleryLoading(false)
            }
            else {
                setAllGalleries(null)
                setShowErrorAlert(true)
                setIsGalleryLoading(false)
            }
        })
    }, [])

    const onGalleryClick = (gallery) => {
        setAvatars([])
        setGalleryImages([])
        setIsGalleryOpen(true);
        setGalleryImages([gallery?.mediaDTOS, gallery.id, gallery.name])
    };


    const observerRef = useRef(null);
    const memberRef = useRef([]);
    const avatarsRef = useRef({});

    useEffect(() => {
        observerRef.current = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const avatar_id = entry.target.getAttribute("avatarId");
                        if (avatar_id) {
                            fetchAvatars(avatar_id);
                        }
                    }
                });
            },
            { threshold: 0.2 }
        );

        memberRef.current.forEach((el) => {
            if (el) observerRef.current.observe(el);
        });

        return () => {
            observerRef.current.disconnect();
        };
    }, [galleryImages]);

    const fetchAvatars = async (avatarId) => {
        if (avatarsRef.current[avatarId]) {
            return;
        }

        try {
            const imageUrl = await onGetImage(avatarId);
            avatarsRef.current[avatarId] = imageUrl;
            setAvatars((prevImages) => [
                ...prevImages,
                { avatarId, imageUrl },
            ]);
        } catch (error) {
            console.error(`Error fetching avatar for user ${avatarId}:`, error);
        }
    }

    const onGetImage = (imageId) => {
        return new Promise((resolve, reject) => {
            const data = {
                accessToken: accessToken,
                language: i18n.language,
                id: imageId
            };

            getImage(data).then(async (response) => {
                if (response.status === 200) {
                    const blob = await response.blob();
                    let fileURL;
                    if (blob.type === 'image/heif') {
                        const jpegBlob = await heic2any({
                            blob: blob,
                            toType: 'image/jpeg',
                            quality: 1
                        });
                        fileURL = URL.createObjectURL(jpegBlob);
                    } else {
                        fileURL = URL.createObjectURL(blob);
                    }
                    resolve(fileURL);
                } else {
                    reject(new Error("Failed to get image."));
                }
            }).catch(error => {
                reject(error);
            });
        });
    };

    const onBack = () => {
        setIsGalleryOpen(false);
    }

    const saveImageAsAvatar = () => {
        const imageMetadata = {
            id: selectedImageId,
            makePartnershipProfileImage: type === 'partnership' ? true : false,
            makeFriendshipProfileImage: type === 'friendship' ? true : false
        }
        const payload = {
            language: i18n.language,
            accessToken: accessToken,
            renameData: imageMetadata,
            id: selectedGalleryId
        }
        updateImageMetadata(payload).then((response) => {
            if (response.status === 200) {
                setAvatar(selectedImageURL)
                close()
                setImageCrop(true);
                setImageBeforeSave(selectedImageURL)
            } else {
                setShowErrorAlert(true)
            }
        })
    }

    const updateImageSelection = (imageId, galleryId, imageUrl) => {
        setSelectedImageId(imageId)
        setSelectedGalleryId(galleryId)
        setSelectedImageURL(imageUrl)
    }

    return (
        <Modal className='openprofile' show={show} size='lg' onHide={() => close()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {!isGalleryOpen ? t('member.gallery.mygalleries') : t('member.gallery.photogalleries')}
                </Modal.Title>
            </Modal.Header>
            <ModalBody className='s_share_gallery'>
                <Alert className='alert_questionaire' show={showErrorAlert} dismissible
                    onClose={() => setShowErrorAlert(false)}
                    variant="danger" id={`err_1`}>{t('cropper.requestFailed')}</Alert>
                {isGalleryLoading ?
                    <Loader />
                    :
                    <div className='galleries_container' style={{ width: '100%' }}>
                        {!isGalleryOpen ?
                            <>
                                {allGalleries?.length > 0 ? null : <p>{t('member.gallery.nogallery')}</p>}
                                {allGalleries?.map((gallery, index) =>
                                    <div key={gallery.id} className='g_container'>
                                        <div className='galleries'>

                                            {gallery.mediaDTOS && gallery.mediaDTOS.length == 0 ?
                                                <p onClick={() => { onGalleryClick(gallery) }}
                                                    className='no_image'>{t('member.gallery.noImage')}</p>
                                                : null}

                                            {gallery.mediaDTOS && gallery.mediaDTOS.length > 4 ?
                                                <div
                                                    onClick={() => onGalleryClick(gallery)}
                                                    className='image_wrapper'>
                                                    <div className='single_image'><img className='images' src={gallery?.thumbnails?.[0] && base64Image(gallery?.thumbnails?.[0].thumbnail)} alt={`Thumbnail 1-${index}`} /></div>
                                                    <div className='single_image'><img className='images' src={gallery?.thumbnails?.[1] && base64Image(gallery?.thumbnails?.[1].thumbnail)} alt={`Thumbnail 2-${index}`} /></div>
                                                    <div className='single_image'><img className='images' src={gallery?.thumbnails?.[2] && base64Image(gallery?.thumbnails?.[2].thumbnail)} alt={`Thumbnail 3-${index}`} /></div>
                                                    <div className='single_image'><img id={gallery.mediaDTOS.length > 4 ? 'image4' : ''} className='images' src={gallery?.thumbnails?.[3] ? base64Image(gallery?.thumbnails?.[3].thumbnail) : UserIcon} alt={`Thumbnail 4-${index}`} />
                                                        {gallery.mediaDTOS.length > 4 ? <span className="plus_p">+{gallery.mediaDTOS && gallery.mediaDTOS.length - 4}</span> : null}</div>
                                                </div>
                                                : null
                                            }

                                            {gallery.mediaDTOS && gallery.mediaDTOS.length == 1 ?
                                                <div
                                                    onClick={() => onGalleryClick(gallery)}
                                                    className='galleryWith1img'>
                                                    <img className='' src={gallery?.thumbnails?.[0] && base64Image(gallery?.thumbnails?.[0].thumbnail)} alt={`Thumbnail-${index}`} />
                                                    <div className="img1plus">+</div>
                                                </div>
                                                : null
                                            }

                                            {gallery.mediaDTOS && gallery.mediaDTOS.length == 2 ?
                                                <div
                                                    onClick={() => onGalleryClick(gallery)}
                                                    className='galleryWith2img'>
                                                    <div>
                                                        <img className='' src={gallery?.thumbnails?.[0] && base64Image(gallery?.thumbnails?.[0].thumbnail)} alt={`Thumbnail 1-${index}`} />
                                                        <img className='' src={gallery?.thumbnails?.[1] && base64Image(gallery?.thumbnails?.[1].thumbnail)} alt={`Thumbnail 2-${index}`} />
                                                    </div>
                                                    <div>
                                                        <div className="img1plus">+</div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }

                                            {gallery.mediaDTOS && gallery.mediaDTOS.length == 3 ?
                                                <div
                                                    onClick={() => onGalleryClick(gallery)}
                                                    className='galleryWith3img'>
                                                    <div>
                                                        <img className='' src={gallery?.thumbnails?.[0] && base64Image(gallery?.thumbnails?.[0].thumbnail)} alt={`Thumbnail 1-${index}`} />
                                                        <img className='' src={gallery?.thumbnails?.[1] && base64Image(gallery?.thumbnails?.[1].thumbnail)} alt={`Thumbnail 2-${index}`} />
                                                    </div>
                                                    <div>
                                                        <img className='' src={gallery?.thumbnails?.[2] && base64Image(gallery?.thumbnails?.[2].thumbnail)} alt={`Thumbnail 3-${index}`} />
                                                        <div className="img3plus">+</div>
                                                    </div>

                                                </div>
                                                :
                                                null}

                                            {gallery.mediaDTOS && gallery.mediaDTOS.length == 4 ?
                                                <div
                                                    onClick={() => onGalleryClick(gallery)}
                                                    className='galleryWith4img'>
                                                    <div>
                                                        <img className='' src={gallery?.thumbnails?.[0] && base64Image(gallery?.thumbnails?.[0].thumbnail)} alt={`Thumbnail 1-${index}`} />
                                                        <img className='' src={gallery?.thumbnails?.[1] && base64Image(gallery?.thumbnails?.[1].thumbnail)} alt={`Thumbnail 2-${index}`} />
                                                    </div>
                                                    <div>
                                                        <img className='' src={gallery?.thumbnails?.[2] && base64Image(gallery?.thumbnails?.[2].thumbnail)} alt={`Thumbnail 3-${index}`} />
                                                        <img className='' src={gallery?.thumbnails?.[3] && base64Image(gallery?.thumbnails?.[3].thumbnail)} alt={`Thumbnail 4-${index}`} />
                                                    </div>

                                                </div>
                                                :
                                                null}

                                            &nbsp;
                                        </div>
                                        <div className='g_name_container'>
                                            <span className='galleryname'>
                                                {gallery?.name.length > 20 ? gallery?.name.substring(0, 20) : gallery?.name}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </>
                            :
                            <>
                                {galleryImages?.[0].length ?
                                    <div className='image-wrapper'>
                                        <Form.Group className='list_btn' >
                                            <div>
                                                <Button
                                                    onClick={() => onBack()}
                                                    className='margin_btn'
                                                    variant="outline-danger"
                                                >
                                                    <FontAwesomeIcon className='left_arrow' icon={faArrowLeft} />
                                                    {t('suggestions.back')}
                                                </Button>
                                                <Button
                                                    onClick={() => saveImageAsAvatar()}
                                                    className='margin_btn'
                                                    variant='primary'
                                                > {t('suggestions.save')}
                                                </Button>
                                            </div>
                                        </Form.Group>
                                        <div className='images_container'>
                                            {galleryImages?.[0].map((image, index) => (
                                                <div key={image.id} id={image.id}
                                                    ref={(el) => (memberRef.current[index] = el)}
                                                    avatarId={image.id}
                                                    className='g_container'>
                                                    <div className='gallery_images'>
                                                        <Form.Group className='image_select'>
                                                            {avatars.find(avatar => avatar.avatarId == image.id)?.imageUrl && (
                                                                <Form.Check
                                                                    aria-labelledby={`choose-img-${index}`}
                                                                    type="checkbox"
                                                                    checked={selectedImageId === image.id}
                                                                    onChange={() => updateImageSelection(image.id, galleryImages[1], avatars.find(avatar => avatar.avatarId == image.id)?.imageUrl)}
                                                                    className='msg_pre_checkbox'
                                                                />
                                                            )}
                                                        </Form.Group>
                                                        <div className='avatar_container' style={{ width: 200, height: 150 }}>
                                                            {avatars.find(avatar => avatar.avatarId == image.id)?.imageUrl ? (
                                                                <img
                                                                    id={`choose-img-${index}`}
                                                                    className='image'
                                                                    src={avatars.find(avatar => avatar.avatarId == image.id)?.imageUrl}
                                                                    alt={`Images ${index + 1}`}
                                                                />
                                                            ) : <Spinner/>}
                                                        </div>

                                                    </div>
                                                    <div className='g_name_container'>
                                                        <span className='galleryname'>
                                                            {image.name.length > 40 ? `${image.name.slice(0, 40)}...` : image.name}
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                        </div>
                                        <Form.Group className='list_btn' >
                                            <div>
                                                <Button
                                                    onClick={() => onBack()}
                                                    className='margin_btn'
                                                    variant="outline-danger"
                                                >
                                                    <FontAwesomeIcon className='left_arrow' icon={faArrowLeft} />
                                                    {t('suggestions.back')}
                                                </Button>
                                                <Button
                                                    onClick={() => saveImageAsAvatar()}
                                                    className='margin_btn'
                                                    variant='primary'
                                                > {t('suggestions.save')}
                                                </Button>
                                            </div>
                                        </Form.Group>
                                    </div> :
                                    <div className='openprofile_spinner'>
                                        <Loader />
                                    </div>
                                }
                            </>
                        }
                    </div>
                }
            </ModalBody>
        </Modal>
    )
}
export default ViewAllGalleries;

