// export const baseUrl = process.env.VITE_ENV;
export const baseUrl = import.meta.env.VITE_BASE_URL;

const questionaireURL = 'questionnaire/get';
const questionaireSaveURL = 'questionnaire/save';
const saveStatusURL = 'member/profile/status';
const getAvatarURL = 'galleries/profile/avatar';
const deleteRegionURL = 'questionnaire/delete/region';
const deleteProximityURL = 'questionnaire/delete/proximity';
const  profileQuestionURL= 'questionnaire/get/profile/questions';
const audioURL = 'audios';
const galleryURL = 'galleries';
const imageURL = 'galleries/image';
const shareGalleryURL = 'galleries/share';
const uploadImageURL = 'images';
const updateImageURL = 'batch';
const updateImgURL = 'image';
const getAllGalleriesURL =  'galleries/all';
const cropImageURL = 'edit';
const questionnaireIdURL = 'questionnaire/get/ids';
const deleteAvatarURL = 'member/avatar'

function getHeaders(parameters, includeAccept = false) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${parameters.accessToken}`,
    'Accept-Language': parameters.language,
  };

  if (includeAccept) {
    headers['Accept'] = 'application/json';
  }

  return headers;
}

export async function getQuestionaireURL(parameters, id) {
    if (parameters.accessToken) {
      return fetch(`${baseUrl}/${questionaireURL}/${id}`, {
        method: 'GET',
        headers: getHeaders(parameters),
        'mode': 'cors'
      })
        .then((response) =>
          response.json().then((data) => ({
            data: data,
            status: response.status,
          }))
        )
        .then(async (responseData) => {
          return responseData;
        })
        .catch((err) => {
          throw err;
        });
    }
  }

  export async function saveQuestionaire(data, parameters) {
    let body;
    if (data?.questionnaireId === 12 || data?.questionnaireId === 13) {
      body = {
        "questionnaireId": data.questionnaireId,
        "regionAnswerRequest" : data.answers,
      }
    } else {
      body = {    
        "questionnaireId": data.questionnaireId,
          "request": data.answers
        }
    }
    return fetch(`${baseUrl}/${questionaireSaveURL}`, {
      method: 'POST',
      headers: getHeaders(parameters, true),
      body: JSON.stringify(body),
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }

export async function saveStatus(data, parameters) {
  if (data) {
    return fetch(`${baseUrl}/${saveStatusURL}`, {
      method: 'PUT',
      headers: getHeaders(parameters, true),
      body: JSON.stringify(data),
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function getMyProfileData(parameters, myProfileURL) {
  if (parameters.accessToken) {
    return fetch(`${baseUrl}/${myProfileURL}`, {
      method: 'GET',
      headers: getHeaders(parameters),
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getAvatar(data) {
  if(data.accessToken) {
    return fetch(`${baseUrl}/${getAvatarURL}/${data.avatarId}`, {
      method: 'GET',
      headers: getHeaders(data),
      'mode': 'cors'
    })
      .then((response) =>{
        return (response)
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function fetchAudio(data) {
  if(data.accessToken) {
    return fetch(`${baseUrl}/${audioURL}/${data.audioId}`, {
      method: 'GET',
      headers: getHeaders(data),
      'mode': 'cors'
    })
    .then((response) =>{
      return (response)
    }) .catch((err) => {
      throw err;
    });
  }
}

export async function getAudio(data, config = {}) {
  if (data.accessToken) {
    try {
      const response = await fetchAudio(data)

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const contentLength = response.headers.get('Content-Length');
      const reader = response.body.getReader();
      const total = contentLength ? parseInt(contentLength, 10) : null;
      let loaded = 0;
      const stream = new ReadableStream({
        start(controller) {
          reader.read().then(function processText({ done, value }) {
            if (done) {
              controller.close();
              return;
            }
            loaded += value.byteLength;
            if (config.onDownloadProgress && total) {
              config.onDownloadProgress({ loaded, total });
            }
            controller.enqueue(value);
            reader.read().then(processText);
          });
        }
      });
      return new Response(stream).blob();
    } catch (error) {
      console.error('Error fetching audio:', error);
      throw error; 
    }
  }
}


export async function deleteRegion(accessToken, regionRequestId, answerId) {
  return fetch(`${baseUrl}/${deleteRegionURL}/${regionRequestId}/${answerId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    },
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })
  ).catch((err) => {
    throw err;
  });
}

export async function deleteProximity(accessToken, proximityRequestId, answerId) {
  return fetch(`${baseUrl}/${deleteProximityURL}/${proximityRequestId}/${answerId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    },
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })
  ).catch((err) => {
    throw err;
  });
}

export async function getProfileQustions(parameters, id) {
  if (parameters.accessToken) {
    return fetch(`${baseUrl}/${profileQuestionURL}/${id}`, {
      method: 'GET',
      headers: getHeaders(parameters),
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getAllAudios(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${audioURL}`, {
      method: 'GET',
      headers:getHeaders(data),
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function updateAudio(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${audioURL}/${data.body.id}`, {
      method: 'PUT',
      headers: getHeaders(data, true),
      body: JSON.stringify(data.body),
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function uploadAudio(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${audioURL}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${data.accessToken}`,
        'Accept-Language': data.language,
      },
      body: data.form
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function getGallery(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${galleryURL}`, {
      method: 'GET',
      headers: getHeaders(data),
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getImage(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${imageURL}/${data.id}`, {
      method: 'GET',
      headers: getHeaders(data),
      'mode': 'cors'
    })
    .then((response) =>{
      return (response)
    })
    .catch((err) => {
      throw err;
    });
  }
}

export async function addGallery(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${galleryURL}`, {
      method: 'POST',
      headers: getHeaders(data),
      body: JSON.stringify(data.newGalleryName)
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function RenameOrDeleteGallery(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${galleryURL}/${data.body.id}`, {
      method: 'PUT',
      headers: getHeaders(data, true),
      body: JSON.stringify(data.body),
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function shareGallery(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${shareGalleryURL}/${data.body.id}`, {
      method: 'PUT',
      headers: getHeaders(data, true),
      body: JSON.stringify(data.body),
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function uploadImage(imageData) {
  if (imageData.accessToken) {
    return fetch(`${baseUrl}/${galleryURL}/${imageData.id}/${uploadImageURL}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${imageData.accessToken}`,
        'Accept-Language': imageData.language
      },
      body: imageData.form
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function cropImage(imageData) {  
  if (imageData.accessToken) {
    return fetch(`${baseUrl}/${galleryURL}/${imageData.galleryId}/${imageData.mediaId}/${cropImageURL}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${imageData.accessToken}`,
        'Accept-Language': imageData.language,
      },
      body: imageData.form
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function updateImage(image) {
  if (image.accessToken) {
    return fetch(`${baseUrl}/${galleryURL}/${image.galleryId}/${updateImageURL}`, {
      method: 'PUT',
      headers: getHeaders(image, true),
      body: JSON.stringify(image.updatedImages),
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function updateImageMetadata(image) {
  if (image.accessToken) {
    return fetch(`${baseUrl}/${galleryURL}/${image.id}/${updateImgURL}`, {
      method: 'PUT',
      headers: getHeaders(image, true),
      body: JSON.stringify(image.renameData),
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })
    ).catch((err) => {
      throw err;
    });
  }
}

export async function getAllGalleries(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${getAllGalleriesURL}`, {
      method: 'GET',
      headers: getHeaders(data),
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getSuggestionToshareGallery(payload) {  
  const data = {
    accessToken: payload.accessToken,
    language: payload.language
  }

  if (payload.accessToken) {
    const params = `search=${payload.search}&pageNo=${payload.pageNo}&pageSize=${payload.pageSize}`;
    return fetch(`${baseUrl}/${shareGalleryURL}/${payload.galleryId}/members?${params}`, {
      method: 'GET',
      headers: getHeaders(data),
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getQuestionaireId(parameters) {
  if (parameters.accessToken) {
    return fetch(`${baseUrl}/${questionnaireIdURL}`, {
      method: 'GET',
      headers: getHeaders(parameters),
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getAllGalleriesForMyprofile(data, category) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${galleryURL}/${category}`, {
      method: 'GET',
      headers: getHeaders(data),
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function deleteAvatar(headers, category) {
  if (headers.accessToken) {
    return fetch(`${baseUrl}/${deleteAvatarURL}/${category}`, {
      method: 'DELETE',
      headers: getHeaders(headers),
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

