import React, { useEffect, useState, useContext, useRef } from 'react';
import GlkHeader from '../../components/Header/GlkHeader.jsx';
import Banner from '../../components/banner/banner.jsx';
import './personalDetails.css';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Spinner from '../../components/spinner.jsx';
import { LoginContext } from '../../context/loginContext.jsx';
import { saveQuestionaire } from './personalDetailsService.jsx';
import { useTranslation } from 'react-i18next';
import RegionalSearchMA from './regionSearchMA.jsx';
import Alert from 'react-bootstrap/Alert';
import { AlertHeading } from 'react-bootstrap';
import { autoFocusByID, numberOnlyWithDigit } from "../commonMethods/index.jsx";
import { Editor } from "react-draft-wysiwyg";
import 'draft-js/dist/Draft.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
faMultiply
} from '@fortawesome/free-solid-svg-icons';
import {
    EditorState,
    convertToRaw,
    ContentState,
    convertFromHTML,
  } from "draft-js";

function PersonalDetails(props) {
  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const [answers, setAnswers] = useState({})
  const [questionnaire, setQuestionnaire] = useState(null)
  const [errResponse, setErrResponse] = useState({errorMessageByQuestionId: {}})
  const [alertMessage, setAlertMessage] = useState('');
  const [alertMessageSelect, setAlertMessageSelect] = useState('')
  const [error, setError] = useState(false)
  const [errorSelect, setErrorSelect] = useState(null)
  const [freeTextError, setFreeTextError] = useState("")
  const [editorStates, setEditorStates] = useState({});
  const [selectedChildrenAge, setSelectedChildrenAge] = useState([]);
  const [numberOfChildren,setNumberOfChildren]=useState()
  const [childrenAgeData,setChildrenAgeData]=useState(null)
  const [childrenObjectData,setChildrenObjectData]=useState(null)
  const [textQuestionId,setTextQuestionId]=useState()
  const selectRef = useRef(null);
  const [errorMgsByID, setErrorMgsByID] = useState("");
  let {
    loginData: {
      accessToken
    },
  } = useContext(LoginContext);

  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  
  const token_lang = {
    language: currentLang,
    accessToken
  }

  useEffect(() => {
    setQuestionnaire(props?.questionaire)
    props?.questionaire?.questionGroups.map((item, index) => 
      item?.questions?.map((data) => {
        if (data.choiceQuestion?.type === "Checkbox" || data.choiceQuestion?.type === "Radio" || data.choiceQuestion?.type === "ComboBox") {
          let allAnswers = [];
          data?.choiceQuestion?.selectedChoices.map((choice) => allAnswers.push(choice.id))
          setSelectedAnswer(prevAnswers => ({
            ...prevAnswers,
            [data.id]: { 
              "answerValues": allAnswers, 
              "profileVisible": data.askVisibleToOthers && data.visibleToOthers 
            }
          }));
        } else if(data?.type === 'TextQuestion') { 
          const freeTextContent = data?.textQuestion?.answer || '';
          const initialEditorState = EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(freeTextContent))
          )
  
          setEditorStates(prevEditorStates => ({
            ...prevEditorStates,
            [data.id]: initialEditorState
          }));
  
          setSelectedAnswer(prevAnswers =>({
            ...prevAnswers,
            [data.id]: { 
              "answerValues": [data?.textQuestion?.answer], 
              "profileVisible": data.askVisibleToOthers && data.visibleToOthers 
            }
          }));
        } else if (data?.type === 'NumberQuestion') { 
          setSelectedAnswer(prevAnswers => ({
            ...prevAnswers,
            [data.id]: { 
              "answerValues": [data?.numberQuestion?.answer], 
              "profileVisible": data.askVisibleToOthers && data.visibleToOthers 
            }
          }));
        }
      })
    );
  
    props?.questionaire?.questionGroups?.filter((e) => e.id === 121).map((item, index) => {
      setChildrenObjectData(item)
      item?.questions?.filter((e) => e.id === 202).map((data) => {
        setNumberOfChildren(data.numberQuestion.answer);
      });
      item?.questions?.filter((e) => e.id === 204).map((data) => {
      setChildrenAgeData(data)
      setSelectedChildrenAge(
        data.textQuestion?.answer
          ?.split(',')
          .map(item => parseInt(item.trim(), 10)) 
          .filter(value => !isNaN(value)) 
      );
      
      });
    });
  }, [props]);
  

  const saveQuestionnaire = async (answer) => {
    const answerBody = {
      questionnaireId: props?.questionaire?.id,
      answers: answer
    }
    const response = await saveQuestionaire(answerBody, token_lang)
    if (response.status !== 200) {
      setErrResponse(response.data)
    } else { setErrResponse({})}
  }

  function stripHtml(html){
    var temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

const handleChange = (question, answer, questionGroup) => {
  if (question?.id === 202 && 1 <= answer && answer <= 20) {
    setNumberOfChildren(answer);
    if (answer < selectedChildrenAge?.length) {
      setSelectedChildrenAge([]);
      handleChange(childrenAgeData,[''],childrenObjectData ); 
    }
  }

  if (question.type === 'ChoiceQuestion' && Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).length > 0) {
      if (question.choiceQuestion.multiSelect === true) {
          let shouldEnable = false
          Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
              question.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                  shouldEnable = parseInt(answer) === enablerOption ||
                      (selectedAnswer[question.id] && selectedAnswer[question.id].answerValues.indexOf(enablerOption) >= 0);
                  if (shouldEnable) { return true; }
                  return false;
              })
              const tempQn = { ...questionnaire }
              tempQn.questionGroups.filter(qg => qg.id === questionGroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable
              setQuestionnaire(tempQn)
          })

          if (selectedAnswer[question.id] && selectedAnswer[question.id].answerValues.indexOf(answer) < 0) {
              selectedAnswer[question.id].answerValues = [...selectedAnswer[question.id].answerValues, answer]
              setSelectedAnswer({ ...selectedAnswer })
          } else if (!selectedAnswer[question.id]) {
              selectedAnswer[question.id] = { "answerValues": [answer], "profileVisible": false }
              setSelectedAnswer({ ...selectedAnswer })
          }
          let toSave = {};
          toSave[question.id] = selectedAnswer[question.id]
          saveQuestionnaire(toSave)
          return;
      }
      else {
          let shouldEnable = false;
          Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
              question.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                  shouldEnable = parseInt(answer) === enablerOption
                  if (shouldEnable) { return true; }
                  return false;
              })
              const tempQn = { ...questionnaire }
              tempQn.questionGroups.filter(qg => qg.id === questionGroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable
              if (!shouldEnable) {
                  selectedAnswer[key] = {}
                  hideChild(key, questionGroup.id, tempQn, selectedAnswer)
              }
              setQuestionnaire(tempQn)
          })
      }
  }
  if (question.type === 'ChoiceQuestion' && question.choiceQuestion.multiSelect === true) {
      if (selectedAnswer[question.id] && selectedAnswer[question.id].answerValues.indexOf(answer) < 0) {
          selectedAnswer[question.id].answerValues = [...selectedAnswer[question.id].answerValues, answer]
      } else if (!selectedAnswer[question.id]) {
          selectedAnswer[question.id] = { "answerValues": [answer], "profileVisible": false }
      }
      setSelectedAnswer({ ...selectedAnswer })
  } else {
      if (selectedAnswer[question.id]) {
        if (question.id == 204) {
          selectedAnswer[question.id].answerValues = answer;          
        } else {
          selectedAnswer[question.id].answerValues = [answer];          
        }
      } else {
          selectedAnswer[question.id] = { "answerValues": [answer], "profileVisible": false }
      }
      setSelectedAnswer({ ...selectedAnswer })
  }
  let toSave = {};
  toSave[question.id] = selectedAnswer[question.id]
  saveQuestionnaire(toSave)
};

const hideChild = (parentId, qgroupId, storeQuestionnaire, savedAnswers) => {
  const questionGroup = storeQuestionnaire.questionGroups.filter(qg => qg.id === qgroupId)[0]
  const parentQuestion = questionGroup.questions.filter(ques => ques.id === parseInt(parentId))[0]
  if (parentQuestion.type === 'ChoiceQuestion' && Object.keys(parentQuestion.choiceQuestion.enabledQuestionActivatingChoiceKeys).length > 0) {
      Object.keys(parentQuestion.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
          savedAnswers[key] = {}
          storeQuestionnaire.questionGroups.filter(qg => qg.id === qgroupId)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = false;
      })
  }
}

const handleBlur = (data, value, item, id) => {
  if (alertMessage === '') {
      handleChange(data, value, item);
      return true; 
    }
};


  const handleChangedata = (data, value, item, id) => {
    setTextQuestionId(id);
    let lineCount = value.trim().split(/\r|\r\n|\n/).length > 10;
    let errorMessage = "";

    if (value === "" || value === undefined || value === null) {
      errorMessage = t('registration.questionnaires.fieldRequired');
    } else if (value.trim().length < 200) {
      errorMessage = t('suggestions.freetextlimit');
    } else if (value.trim().length > 800) {
      errorMessage = t('suggestions.freetextlimit800');
    } else if (lineCount) {
      errorMessage = t('suggestions.freetextline');
    }

    if (value === "" ||
      value === undefined ||
      value === null || value.trim().length < 200 ||
      value.trim().length > 800 ||
      lineCount) {
      setAlertMessage(errorMessage);
    } else {
      setAlertMessage('');
    }
  }
  
const blockInvalidChar = (e) =>{
  const regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);
  return !e.key.match(regex) && e.preventDefault();
}
const validateWithTAB = (evt, data, selectedValue)  => {
  if(evt.type === "keydown" && evt.keyCode ===9  && evt.key === "Tab" ){
    if(selectedValue === "" && data.required){
      setAlertMessageSelect(evt.target.id)
      autoFocusByID(evt.target.id)
      setErrorSelect(t('member.personaldata.personaldetails.select'))
      setError(true)
    }else{
      setError(false)
    }
  }
}

const handleTextQuestion = (e, data) => {
    const isEmpty = e.target.value === "";
    const wordCount = e.target.value.length;
    if (e.type === "keydown" && e.keyCode === 9) {
      if (isEmpty || wordCount < 200) {
          setAlertMessage(t(isEmpty ? 'registration.questionnaires.fieldRequired' : 'suggestions.freetextlimit'));
          setError(true);
          setFreeTextError(data.id);
          e.preventDefault();
          return;
      }
    }
    setFreeTextError("");
    setError(false);
}
const handleInput = (e, data, item, value) => {
  if (e.keyCode === 9 && e.target.id !== 'textquestion' && !e.target.value) {
    handleChange(data, value, item);
    e.preventDefault();
  }
};

const onFreetextSave = (e, items, item) => {
  handleChange(items, e.target.value, item);
};

const onEditorStateChange = (editorState, items) => {
  setEditorStates(prevStates => ({
      ...prevStates,
      [items.id]: editorState
  }));
};

const options = [
  { value:1, label: 1 },
  { value:2, label: 2 },
  { value:3, label: 3 },
  { value:4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value:10, label: 10 },
  { value:11, label: 11 },
  { value:12, label: 12 },
  { value:13, label: 13 },
  { value:14, label: 14 },
  { value:15, label: 15},
  { value:16, label: 16},
  { value:17, label: 17},
  { value:18, label: 18},
  { value:19, label: 19},
  { value:20, label: 20},
];

const handleSelect = (data, selectedOption, item, id) => {
  const updatedItems = [...(selectedChildrenAge || []), selectedOption.value];
  
  if (updatedItems.length <= numberOfChildren) {
    setSelectedChildrenAge(updatedItems);
    handleBlur(data, updatedItems, id);
    setAlertMessage('');
    setTextQuestionId('');
  } else {
    setAlertMessage(t('registration.questionnaires.childrenAgeError', { numberOfChildren }));
    setTextQuestionId(id);
  }
};
const handleRemove = (index,data,item,id) => {
  const updatedItems = [...selectedChildrenAge];
  updatedItems.splice(index, 1);  
  setSelectedChildrenAge(updatedItems);
    handleBlur(data, updatedItems, id)
    if(updatedItems?.length <= numberOfChildren){
    setAlertMessage('')
    setTextQuestionId('')
    }
};
const validateMultipleRanges = (e, qId, GqId, data, item) => {
  let isFirstInput = false;
  let ele = null;
  if(document.querySelectorAll(`[data-qid='${GqId}']`).length > 0) {
      ele = document.querySelectorAll(`[data-qid='${GqId}']`)
  } else {
      ele = document.querySelectorAll(`[data-qid='${qId}']`)
  }
  if(ele.length > 0) {
      let range = [];
  
      ele.forEach((v, k) => {
          if(v.value) range.push({ value: Number(v.value), Gqid: GqId });
      });
      if(ele[0].id == e.target.id) isFirstInput = true;
      
      if(ele[0].value) {
          const minRange = e.target.min ? Number(e.target.min) : 0;
          const maxRange = e.target.max ? Number(e.target.max) : 0;
          const currentValue = Number(e.target.value);

          if(isFirstInput) {
              if (currentValue < minRange || currentValue > maxRange) {
                  updateErrorState(t("registration.questionnaires.minMaxRangeError", { minRange, maxRange }), qId);
                  return true;
              } else {
                  clearErrorState(qId);
                  return false;
              }
          } else {
              if(range.length >= 2) {
                  const firstValue    = range[0].value;
                  const secondValue   = range[1].value;
                  const resMinMax = secondValue - firstValue;
                  if((GqId == 2 || GqId == 16) && resMinMax < 5) {
                      updateErrorState(t("registration.questionnaires.maximumRange"), ele[1].id);
                      return true;
                  }
                  if(GqId == 13 && resMinMax < 10) {
                      updateErrorState(t("registration.questionnaires.maximumHeight"), ele[1].id);
                      return true;
                  }

                  if (currentValue < minRange || currentValue > maxRange) {
                      updateErrorState(t("registration.questionnaires.maximumValue", { minRange, maxRange }), qId);
                      return true;
                  } else {
                      if (Number(ele[0].value) < Number(ele[0].min) || Number(ele[0].value) > Number(ele[0].max)) {
                          updateErrorState(t("registration.questionnaires.minMaxRangeError", { minRange, maxRange }), ele[0].id);
                          if(e.type === 'keydown' && e.keyCode === 9 && e.shiftKey !== true) {
                              autoFocusByID(ele[0].id);
                          }
                          clearErrorState(qId);     
                          return true;
                      }
                      clearErrorState(ele[0].id);
                      clearErrorState(qId);
                      return false;
                  }
              } else {
                if (currentValue < minRange || currentValue > maxRange) {
                  updateErrorState(t("registration.questionnaires.maximumValue", { minRange, maxRange }), qId);
                  return true;
                }
              }
          }
      } else {
          if(e.target.value && ele[0].value == '') {
            let message = '';
            if(GqId == 2 || GqId == 16) message = t("registration.questionnaires.minAge")
            if(GqId == 13) message = t("registration.questionnaires.minHeight")
            updateErrorState(message, qId)
            return true;
          }
          clearErrorState(qId);
          return false;
      }
  }
};
const handleTapNumberQuestion = (e, data, GqId, item) => {  
  const value = e.target.value;
  const hasMinMax = e.target.min && e.target.max;
  let hasError = false;
  if (data.required && value === "") {
    updateErrorState(t('registration.questionnaires.fieldRequired'), data.id);
    hasError = true;
  } else if (hasMinMax) {
    hasError = validateMultipleRanges(e, data.id, GqId); 
  }
  if (!hasError) {
    clearErrorState(data.id);
    handleChange(data, value, item);
  }
}

const handleTapBlock = (e, data, GqId) => {
  if (e.type === "keydown" && e.keyCode === 9 && e.shiftKey !== true) {  
      if(data.required) {
          if (e.target.value === "") {
            updateErrorState((t('registration.questionnaires.fieldRequired')), data.id)
            e.preventDefault()
          } else {
            if (e.target.min && e.target.max) {
              if (validateMultipleRanges(e, data.id, GqId)) e.preventDefault()  
            } else {
              clearErrorState(data.id);
              }
          }
      } else {
        if (e.target.min && e.target.max) {
          if (validateMultipleRanges(e, data.id, GqId)) e.preventDefault()  
        }
      }
  }
};
const updateErrorState = (message, qId) => {     
  let updatedErrResponse = errResponse;
  if(!updatedErrResponse.errorMessageByQuestionId) updatedErrResponse = {errorMessageByQuestionId: {}}
  updatedErrResponse.errorMessageByQuestionId[qId] = message;
  setErrResponse(updatedErrResponse);
  setErrorMgsByID(message);       
} 

const clearErrorState = (qId) => {
  let errResponseTemp = errResponse;
  if(!errResponseTemp.errorMessageByQuestionId) errResponseTemp = {errorMessageByQuestionId: {}}
  if (errResponseTemp && errResponseTemp.errorMessageByQuestionId && errResponseTemp.errorMessageByQuestionId[qId]) {
    delete errResponseTemp.errorMessageByQuestionId[qId];
  }
  if(Object.keys(errResponseTemp.errorMessageByQuestionId).length === 0) errResponseTemp = {errorMessageByQuestionId: {}}
  setErrResponse(errResponseTemp);
  setErrorMgsByID('');
};

 const selectStyles = () => ({
        container: (base) => ({
          ...base,
          width: '100%', 
          maxWidth: '100%',
        }),
        singleValue: (base) => ({
          ...base,
          whiteSpace: 'normal', 
        }),
        control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused
              ? '0 0 0 4px rgba(13, 110, 253, 0.25)'
              : 'none',
            borderColor: state.isFocused || state.isHovered
              ? '#86b7fe' : '#9ea5ab',              
            transition: 'border-color 0.2s ease, box-shadow 0.2s ease',
            '&:hover': {
              borderColor: '#9ea5ab',
            },
          }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused
                ? '#0d6efd'
                : '#fff',

            color: state.isFocused
                ? '#fff'
                : '#333',
            cursor: 'pointer',
            fontSize: '1rem',
            fontWeight: '400',
        }),
          menu: (base) => ({
            ...base,
            zIndex: 1000,
          }),  
      });

  return (
	<>
    <GlkHeader/>
    <main className={'main_wrapper personal_details_wrapper '+props?.data.menuName}>
      <Banner data={props?.data} />
      {questionnaire ?
        <div className='questionaire_in_menubar'>

          <Alert className='alert_questionaire' show={errResponse && errResponse.message == "success"} variant="danger" id='main_alert'>
                    <AlertHeading>{t('registration.questionnaires.errorHeader')}</AlertHeading>
                    <p>{errResponse?.message}</p>
                </Alert>
            {questionnaire?.questionGroups.map((item, groupIndex) => (
            <Accordion key={groupIndex} defaultActiveKey={groupIndex === 0 ? "0" : ''}>
              <Accordion.Item eventKey="0">
                <Accordion.Header className='accordian_personal_detail'>{item?.name}</Accordion.Header>
                <Accordion.Body>
									<h3 className='description_pd' dangerouslySetInnerHTML={{ __html: item?.description }}></h3>
                  {item?.questions?.map((data, index) => {										
                    if (data.type === "ChoiceQuestion")
                      if (data.choiceQuestion?.type === "Checkbox")
                        return (
                          <div key={index}>
                            {data.enabledQuestion === true ? <div>
                            <Form.Group className="mt-3 mb-3 check_box" id={`Checkbox-${groupIndex * item.questions.length + index + 1}`}>
														<fieldset aria-describedby={`Checkbox-${groupIndex * item.questions.length + index + 1}`}>
															<legend>{data?.name}<span className='required_asterisks' aria-hidden='true'>{data.required ? '*' : null}</span></legend>
                              <div className='checkbox_questionair'>
                                {data?.choiceQuestion?.choices?.map((choice) => (
                                  <>
                                    <Form.Check
                                      defaultChecked={selectedAnswer[data?.id]?.answerValues?.includes(choice.id)}
                                      id={`${data?.id}-${choice?.id}`}
                                      name={data?.name}
                                      type="checkbox"
                                      label={choice?.value}
                                      className='msg_pre_checkbox'
                                      onClick={(e) => {
                                        if (e.target.checked) {
                                          handleChange(data, choice.id, item)
                                        } else if (selectedAnswer[data.id] && selectedAnswer[data.id].answerValues.indexOf(choice.id) >= 0) {
                                          selectedAnswer[data.id].answerValues.splice(selectedAnswer[data.id].answerValues.indexOf(choice.id), 1)
                                          let shouldEnable = false
                                          Object.keys(data.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                                              data.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                                                  shouldEnable = selectedAnswer[data.id] && selectedAnswer[data.id].answerValues.indexOf(enablerOption) >= 0;
                                                  if (shouldEnable) { return true; }
                                                  return false;
                                              })
                                              const tempQn = { questionnaire }
                                              tempQn.questionGroups.filter(qg => qg.id === item.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable;
                                              if (!shouldEnable) {
                                                  selectedAnswer[key] = {};
                                              }
                                              setQuestionnaire(tempQn)
                                          })
                                          setSelectedAnswer(selectedAnswer)
                                          let toSave = {};
                                          toSave[data.id] = selectedAnswer[data.id]
                                          saveQuestionnaire(toSave)
                                        }
                                      }}
                                    >
                                    </Form.Check>
                                  </>
                                ))}
                              </div>
														</fieldset>
                            </Form.Group>
                              {data.askVisibleToOthers === true ?
                                <Form.Check id={`profileVisible${data.id}`}
                                  name={`profileVisible${data.id}`}
                                  type="checkbox"
                                  defaultChecked={selectedAnswer[data?.id]?.profileVisible}
                                  label={t('common.visibleToOthers')}
                                  className='msg_pre_checkbox'
                                  onClick={(e) => {
                                    if (selectedAnswer[data.id] && selectedAnswer[data.id].answerValues.length > 0) {
                                      selectedAnswer[data.id].profileVisible = e.target.checked
                                      setSelectedAnswer(selectedAnswer)
                                      let toSave = {};
                                      toSave[data.id] = selectedAnswer[data.id]
                                      saveQuestionnaire(toSave)
                                    }
                                  }}
                                >
                                </Form.Check> : null}
                              <Alert className='alert_questionaire' show={ selectedAnswer[data.id].answerValues.length <= 0 && data.required && errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] ? true : false}
                                variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                            </div> : null}
                          </div>
                        )

                    if (data.choiceQuestion?.type === "Radio")
                      return (
                        <div key={index}>
                          {data.enabledQuestion === true ? <div>
                          <Form.Group className="mt-3 mb-3 radio" id={data.id+'_'+index}>
													<fieldset aria-describedby={data.id+'_'+index}>
														<legend>{data?.name}</legend>
															{data?.description !== null ?
															<p>{data.description}</p> : null}
                            <div id={data.id+'_'+index} className='radio_groups'>
                              {data?.choiceQuestion?.choices?.map((choice) => (
                                <Form.Check
                                  checked={selectedAnswer[data?.id]?.answerValues?.includes(choice.id)}
                                  id={`${data?.id}-${index++}`}
                                  name={data?.name}
                                  type="radio"
                                  label={choice?.value}
                                  className='msg_pre_checkbox'
                                  onChange={(e) => {
                                    handleChange(data, choice.id, item)
                                  }}
                                >
                                </Form.Check>
                              ))}
                            </div>
													</fieldset>
                          </Form.Group>
                          {data.askVisibleToOthers === true ?
                                <Form.Check id={`profileVisible${data.id}`}
                                  name={`profileVisible${data.id}`}
                                  type="checkbox"
                                  defaultChecked={selectedAnswer[data?.id]?.profileVisible}
                                  label={t('common.visibleToOthers')}
                                  className='msg_pre_checkbox'
                                  onClick={(e) => {
                                    if (selectedAnswer[data.id] && selectedAnswer[data.id].answerValues.length > 0) {
                                      selectedAnswer[data.id].profileVisible = e.target.checked
                                      setSelectedAnswer(selectedAnswer)
                                      let toSave = {};
                                      toSave[data.id] = selectedAnswer[data.id]
                                      saveQuestionnaire(toSave)
                                    }
                                  }}
                                >
                                </Form.Check> : null}
                                <Alert className='alert_questionaire' show={ data.required && errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] ? true : false}
                                variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                          </div> : null}
                        </div>
                      )

                    if (data.choiceQuestion?.type === "ComboBox")
                      return (<>
                        <div key={index}>
                          {data.enabledQuestion === true ? <div>
                            <Form.Group className="mt-3 mb-3 combo_box" id={`ComboBox-${groupIndex * item.questions.length + index ++}`}>
                              <label htmlFor={data.id+'_'+index}>{data.name}<span className='required_asterisks'>{data.required ? '*' : null}</span></label>
                              <Select
                                inputId={`${data.id}_${index}`}
                                className={error && alertMessageSelect === `${data.id}_${index}` ? 'is-invalid' : ''}
                                onChange={(selectedOption) => {
                                  handleChange(data, selectedOption?.value, item);
                                }}
                                onKeyDown={(evt) => validateWithTAB(evt, data || "")}
                                id={`${data.id}_${index}`}
                                options={data.choiceQuestion.choices.map(choice => ({
                                  value: choice.id,
                                  label: choice.value,
                                  isSelected: choice.id === data?.choiceQuestion?.selectedChoices[0]?.id,
                                }))}
                                placeholder={t('registration.questionnaires.pleaseSelectOption')}
                                defaultValue={data.choiceQuestion.choices.find(choice => choice.id === data?.choiceQuestion?.selectedChoices?.[0]?.id)? 
                                    {
                                      value: data.choiceQuestion.selectedChoices[0].id,
                                      label: data.choiceQuestion.selectedChoices[0].value,
                                    }
                                    : null
                                }
                                styles={selectStyles()}
                            />
                          </Form.Group>
                          {error && alertMessageSelect  == data.id+'_'+index && <Alert className='alert_questionaire' variant="danger" id={`err_${data.id}`}>{errorSelect}</Alert>}
                          {data.askVisibleToOthers === true ?
                                <Form.Check id={`profileVisible${data.id}`}
                                  name={`profileVisible${data.id}`}
                                  type="checkbox"
                                  defaultChecked={selectedAnswer[data?.id]?.profileVisible}
                                  label={t('common.visibleToOthers')}
                                  className='msg_pre_checkbox'
                                  onClick={(e) => {
                                    if (selectedAnswer[data.id] && selectedAnswer[data.id].answerValues?.length > 0) {
                                      selectedAnswer[data.id].profileVisible = e.target.checked
                                      setSelectedAnswer(selectedAnswer)
                                      let toSave = {};
                                      toSave[data.id] = selectedAnswer[data.id]
                                      saveQuestionnaire(toSave)
                                    }
                                  }}
                                >
                                </Form.Check> : null}
                                <Alert className='alert_questionaire' show={ data.required && errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] ? true : false}
                                variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                          </div> : null }
                        </div>
                        </>)

                    if (data?.type === "NumberQuestion")
                      return (
                        <div key={index}>
                          {data.enabledQuestion === true ? <div>
                          <Form.Group className="mt-3 number_question" id='numberquestion'>
                            <Form.Label className="" htmlFor={data.id}>{data.name}<span className='required_asterisks'>{data.required ? '*' : null}</span></Form.Label>
                            <Form.Control
                              defaultValue={selectedAnswer[data?.id]?.answerValues?.[0]}
                              type="text"
                              id={data.id}
                              data-qid={(item.questions.length == 2 ||  item.questions.length == 3) ?  item.id : data.id}
                              min={data.numberQuestion.minValue}
                              max={data.numberQuestion.maxValue}
                              aria-label={errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] && errorMgsByID  }
                              className={ errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] && 'is-invalid'}
                              onBlur={(e) => {
                                handleTapNumberQuestion(e, data ,item.id, item);
                              }}
                              onKeyDown={(e) => {
                                handleTapBlock(e,  data, item.id);
                                blockInvalidChar(e);
                              }}
                              onKeyUp={(e) => numberOnlyWithDigit(e, 3)}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Group>
                          {data.askVisibleToOthers === true ?
                                <Form.Check id={`profileVisible${data.id}`}
                                  name={`profileVisible${data.id}`}
                                  type="checkbox"
                                  defaultChecked={selectedAnswer[data?.id]?.profileVisible}
                                  label={t('common.visibleToOthers')}
                                  className='msg_pre_checkbox'
                                  onClick={(e) => {
                                    if (selectedAnswer[data.id] && selectedAnswer[data.id].answerValues.length > 0) {
                                      selectedAnswer[data.id].profileVisible = e.target.checked
                                      setSelectedAnswer(selectedAnswer)
                                      let toSave = {};
                                      toSave[data.id] = selectedAnswer[data.id]
                                      saveQuestionnaire(toSave)
                                    }
                                  }}
                                >
                                </Form.Check> : null}
                                <Alert className='alert_questionaire' show={ errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] ? true : false}
                                variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                          </div> : null}
                        </div>
                      )

                      
                    if (data?.type === "TextQuestion")
                      return (<>
                        {data.textQuestion.type === 'RichTextArea' ?
                          <div key={index}>
                            {data.enabledQuestion === true ? <div>
                            <Form.Group className="mt-3 text_question" id={`TextQuestion-${groupIndex * item.questions.length + index ++}`}>
                              <Form.Label className="" htmlFor={data.id}>{data.name}<span className='required_asterisks'>{data.required ? '*' : null}</span></Form.Label>                              
                                <Editor
                                  handlePastedText={() => false}
                                  editorState={editorStates[data.id] || EditorState.createEmpty()}
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                  onEditorStateChange={(editorState) => onEditorStateChange(editorState, data, item)}
                                  onBlur={() => {
                                    let htmlContent = draftToHtml(convertToRaw(editorStates[data.id].getCurrentContent())).trim();
                                    onFreetextSave({ target: { value: htmlContent } }, data, item);
                                  }}
                                  toolbar={{
                                    options:['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker','emoji','history'] ,   
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: true },
                                }}
                                />
                            </Form.Group>
                            <Alert className='alert_questionaire' show={errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] ? true : false}
                                variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                            </div> : null}
                          </div> :
                          <div key={index}>
                            {data.enabledQuestion === true ? <div>
                              <Form.Group className="mt-3 text_question" id={`TextQuestion-${groupIndex * item.questions.length + index + 1}`}>
                            <fieldset aria-describedby={data.id}>
                              <legend><p  htmlFor={data.id}  style={{ marginBottom: '0.10rem' }}>{data.name}<span className='required_asterisks'>{data.required ? '*' : null}</span></p></legend>
                              {data.id != 204 ? 
                              <Form.Control
                                aria-label={data.name}
                                defaultValue={data.textQuestion.answer}
                                as="textarea"
                                id={data.id}
                                label={error && freeTextError === data.id ? t('suggestions.freetextlimit') : data.name }
                                className={data.required &&  error && freeTextError === data.id && "is-invalid"}
                                onChange={(e) =>{
                                  if(questionnaire?.id === 25){
                                    handleChangedata(data, e.target.value, item,data.id)
                                  }
                                }
                                }
                                onBlur={(e) =>
                                  handleBlur(data, e.target.value, item,data.id)
                                }
                                onKeyDown={(e) => handleTextQuestion(e, data)}
                              /> 
                              : null}
                              {data.id == 204 ? 
                              <div className={data.required &&  error && freeTextError === data.id && "is-invalid"}>
                                {selectedChildrenAge?.length != numberOfChildren ? (
                                      <Select
                                        aria-label={data.name}
                                        options={options}
                                        onChange={(e) => handleSelect(data, e, item, data.id)}
                                        className={data.required && error && freeTextError === data.id && "is-invalid"}
                                        onKeyDown={(e) => handleTextQuestion(e, data)}
                                        placeholder="Select Age of children"
                                        isClearable={false}
                                        isSearchable={true}
                                        value={null}
                                      />
                                ):""}
                                      <div className="selected-items">
                                        {selectedChildrenAge?.map((item, index) => (
                                          <div key={index} className="selected-item">
                                            {item}
                                            <button onClick={() => handleRemove(index, data, item, data.id)}  aria-label="Remove Age"><FontAwesomeIcon icon={faMultiply} /></button>
                                          </div>
                                        ))}
                                      </div>
                              </div>
                              : null}
                            </fieldset>
                            </Form.Group>
          
                            {alertMessage && textQuestionId===data.id && data.id !== 204 &&
                            <Alert className='alert_questionaire' id={`err_${data.id}`}
                              variant="danger" >
                              {alertMessage}</Alert>}
                            </div> : null }
                          </div>}
                          {data.askVisibleToOthers === true ?
                                <Form.Check id={`profileVisible${data.id}`}
                                  name={`profileVisible${data.id}`}
                                  type="checkbox"
                                  defaultChecked={selectedAnswer[data?.id]?.profileVisible}
                                  label={t('common.visibleToOthers')}
                                  className='msg_pre_checkbox'
                                  onClick={(e) => {
                                    if (selectedAnswer[data.id] && selectedAnswer[data.id].answerValues.length > 0) {
                                      selectedAnswer[data.id].profileVisible = e.target.checked
                                      setSelectedAnswer(selectedAnswer)
                                      let toSave = {};
                                      toSave[data.id] = selectedAnswer[data.id]
                                      saveQuestionnaire(toSave)
                                    }
                                  }}
                                >
                                </Form.Check> : null}
                      </>)

                    if (data.type === 'RegionQuestion')
                      return (
                        <RegionalSearchMA
                          data={data}
                          answerData={props.questionaire}
                          questionaireId={props.questionaire?.id}
                          setAnswers={setAnswers}
                          isFromRegistration={false}
                          setReloadQuestionaire={props.setReloadQuestionaire}
                        />
                      )
                  })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </div>
        : <Spinner />}
    </main>
	</>
  )
}

export default PersonalDetails;