import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UploadImage from "../personalDetails/myprofile/uploadImage.jsx";
import { getAvatar } from "../personalDetails/personalDetailsService.jsx";
import AudioButton from "./audiobutton.jsx";
import { getAudio, getAvatar as thumbnailAvatar } from "./homeServices.jsx";
import { CardGroup, Button, Card } from "react-bootstrap";
import volumeIcon from "../../assets/images/volume_icon.png";
import userIcon from "../../assets/images/user-icon01.png";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { LoginContext } from "../../context/loginContext.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function YourRelationships(props) {
  const { incompleteQueData, setIncompleteQueData } =
    props;
  const [partnerShipAvatar, setPartnerShipAvatar] = useState(null);
  const [friendShipAvatar, setFriendShipAvatar] = useState(null);
  const [partnershipAvatarThumbnail, setPartnershipAvatarThumbnail] = useState(null);
  const [isUploadImgeOpen, setIsUploadImgeOpen] = useState(false);
  const [friendshipAvatarThumbnail, setFriendshipAvatarThumbnail] = useState(null);
  const [partnershipAudio, setPartnershipAudio] = useState(null);
  const [friendshipAudio, setFriendshipAudio] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(false);

  const [uploadType, setUploadType] = useState(null);
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  let {
    loginData: { accessToken, memberDetails },
  } = useContext(LoginContext);

  const setThumbnailAvatar = async () => {
    try {
      if (
        incompleteQueData?.partnershipAvatar?.id &&
        incompleteQueData.partnershipAvatar.id !== null
      ) {
        const data = {
          accessToken: accessToken,
          language: currentLang,
          avatarId: incompleteQueData.partnershipAvatar.id,
        };
        thumbnailAvatar(data).then((response) => {
          processImage(response, "partnership", "small");
        });
      }
      if (
        incompleteQueData?.friendshipAvatar?.id &&
        incompleteQueData.friendshipAvatar.id !== null
      ) {
        const data = {
          accessToken: accessToken,
          language: currentLang,
          avatarId: incompleteQueData.friendshipAvatar.id,
        };
        thumbnailAvatar(data).then((response) => {
          processImage(response, "friendship", "small");
        });       
      }
    } catch (err) {
      console.error(err);
    }
  };


  const setAvatar = async (type) => {
    try {
      if (partnerShipAvatar == null && type.includes("partnership") &&
        incompleteQueData?.partnershipAvatar?.id &&
        incompleteQueData.partnershipAvatar.id !== null
      ) {
        const data = {
          accessToken: accessToken,
          language: currentLang,
          avatarId: incompleteQueData.partnershipAvatar.id,
        };       
        getAvatar(data).then((response) => {
            processImage(response, "partnership", "big");
            openUploadImagePopup();          
        });
      }else{
        openUploadImagePopup();
      }
      if (friendShipAvatar == null && type.includes("friendship") &&
        incompleteQueData?.friendshipAvatar?.id &&
        incompleteQueData.friendshipAvatar.id !== null
      ) {
        const data = {
          accessToken: accessToken,
          language: currentLang,
          avatarId: incompleteQueData.friendshipAvatar.id,
        };        
        getAvatar(data).then((response) => {
          processImage(response, "friendship", "big");
          openUploadImagePopup();
        });
      }else{
        openUploadImagePopup();
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setThumbnailAvatar();
  }, [incompleteQueData]);

  const processImage = async (response, type, mode) => {
    setIsImageLoading(true)
    if (response.status === 200) {                        
      const blob = await response.blob();      
      const fileURL = URL.createObjectURL(blob);   
      setIsImageLoading(false);         
      if (type == "partnership") {
        mode == "small"
          ? setPartnershipAvatarThumbnail(fileURL)
          : setPartnerShipAvatar(fileURL);
      } else {
        mode == "small"
          ? setFriendshipAvatarThumbnail(fileURL)
          : setFriendShipAvatar(fileURL);
      }
    }
  };

  useEffect(() => {
    if (
      incompleteQueData?.partnershipAudio?.id &&
      incompleteQueData.partnershipAudio.id !== null
    ) {
      const data = {
        accessToken: accessToken,
        language: currentLang,
        audioId: incompleteQueData.partnershipAudio.id,
      };
      getAudio(data).then((response) => {
        processAudio(response, "partnership");
      });
    }
    if (
      incompleteQueData?.friendshipAudio?.id &&
      incompleteQueData.friendshipAudio.id !== null
    ) {
      const data = {
        accessToken: accessToken,
        language: currentLang,
        audioId: incompleteQueData.friendshipAudio.id,
      };
      getAudio(data).then((response) => {
        processAudio(response, "friendship");
      });
    }
  }, [incompleteQueData]);

  const processAudio = async (response, type) => {
    if (response.status === 200) {
      const blob = await response.blob();
      const fileURL = URL.createObjectURL(blob);
      if (type == "partnership") {
        setPartnershipAudio(fileURL);
      } else {
        setFriendshipAudio(fileURL);
      }
    }
  };

  const closeUploadImagePopup = () => {
    setIsUploadImgeOpen(false);
  };
  const openUploadImagePopup = () => {
    setIsUploadImgeOpen(true);
  };

  const updateFriendShipAvatar = (newUrl) => {
    setFriendshipAvatarThumbnail(newUrl);
    setFriendShipAvatar(newUrl);  
    setIncompleteQueData((prev) => {
      const updatedMissingCategories = newUrl
        ? prev.missingAvatarCategories.filter((item) => item !== "FRIENDSHIP")
        : [...new Set([...prev.missingAvatarCategories, "FRIENDSHIP"])];
  
      return {
        ...prev,
        friendshipAvatar: newUrl
          ? { id: null }
          : null,
        missingAvatarCategories: updatedMissingCategories,
      };
    });
  };
  
  const updatePartnerShipAvatar = (newUrl) => {
    setPartnershipAvatarThumbnail(newUrl);
    setPartnerShipAvatar(newUrl);  
    setIncompleteQueData((prev) => {
      const updatedMissingCategories = newUrl
        ? prev.missingAvatarCategories.filter((item) => item !== "PARTNERSHIP")
        : [...new Set([...prev.missingAvatarCategories, "PARTNERSHIP"])];
  
      return {
        ...prev,
        partnershipAvatar: newUrl
          ? { id: null}
          : null,
        missingAvatarCategories: updatedMissingCategories,
      };
    });
  };
  
  const cardData = [
    {
      header: t("member.avtars.partnership"),
      icon: userIcon,
      info: t("member.avtars.profileMissing"),
      text: t("member.avtars.uploadPicture"),
      type: "partnership.avatar",
      isMissingAvtarOrAudio: incompleteQueData?.missingAvatarCategories.find(
        (item) => item === "PARTNERSHIP"
      ),
      show: memberDetails?.partnershipActive,
    },
    {
      header: t("member.avtars.friendship"),
      icon: userIcon,
      info: t("member.avtars.profileMissing"),
      text: t("member.avtars.uploadPicture"),
      type: "friendship.avatar",
      isMissingAvtarOrAudio: incompleteQueData?.missingAvatarCategories.find(
        (item) => item === "FRIENDSHIP"
      ),
      show: memberDetails?.friendshipActive,
    },
    {
      header: t("member.avtars.partnership"),
      icon: volumeIcon,
      info: t("member.avtars.missingAudio"),
      text: t("member.avtars.addAudioP"),
      type: "partnership.audio",
      isMissingAvtarOrAudio: incompleteQueData?.missingAudioCategories.find(
        (item) => item === "PARTNERSHIP"
      ),
      show: memberDetails?.partnershipActive,
    },
    {
      header: t("member.avtars.friendship"),
      icon: volumeIcon,
      info: t("member.avtars.missingAudio"),
      text: t("member.avtars.addAudioF"),
      type: "friendship.audio",
      isMissingAvtarOrAudio: incompleteQueData?.missingAudioCategories.find(
        (item) => item === "FRIENDSHIP"
      ),
      show: memberDetails?.friendshipActive,
    },
  ];

  return (
    <div className="card_container">
      <h3>{t("member.yourmembership.avatarsAndAudio")}</h3>
      {cardData.map((card, index) => {
        if (!card.show) return null;

        const isAvatar =
          card.type === "partnership.avatar" ||
          card.type === "friendship.avatar";
        const isAudio =
          card.type === "partnership.audio" || 
          card.type === "friendship.audio";

        const renderCard = ({
          header,
          icon,
          bodyContent,
          onClick,
          className,
        }) => (
          <Button className={`card_btn ${className}`} key={index}>
            <CardGroup>
              <Card
                key={index}
                text={"Success".toLowerCase() === "light" ? "dark" : "white"}
                className="mb-2 card_for_missings"
                onClick={onClick}
              >
                <Card.Header className={`${card.isMissingAvtarOrAudio ? "missing_header" : ""}`}>
                  <FontAwesomeIcon
                    icon={
                      card.isMissingAvtarOrAudio ? faTimesCircle : faCheckCircle
                    }
                  />
                  {header}
                </Card.Header>
                <Card.Body className="card_body">
                  <Card.Title className="cards_icon">{icon}</Card.Title>
                  {bodyContent}
                </Card.Body>
              </Card>
            </CardGroup>
          </Button>
        );

        if (card.isMissingAvtarOrAudio) {
          if (isAvatar) {
            return renderCard({
              header: card.header,
              icon: <img src={card.icon} alt="Card Icon" />,
              bodyContent: (
                <>
                  <Card.Text className="card_info">{card.info}</Card.Text>
                  <Card.Text className="button_style missing_button">
                    {card.text}
                  </Card.Text>
                </>
              ),
              onClick: () => {
                setUploadType(card.type);
                openUploadImagePopup();
              },
              className: "",
            });
          } else {
            return renderCard({
              header: card.header,
              icon: <img src={card.icon} alt="Card Icon" />,
              bodyContent: (
                <>
                  <Card.Text className="card_info">{card.info}</Card.Text>
                  <Card.Text className="button_style missing_button">
                    {card.text}
                  </Card.Text>
                </>
              ),
              onClick: () => navigate("/personal-details/audio"),
              className: "",
            });
          }
        } else if (isAvatar) {
          const avatarSrc =
            card.type === "partnership.avatar"
              ? partnershipAvatarThumbnail || userIcon
              : friendshipAvatarThumbnail || userIcon;

          return renderCard({
            header: card.header,
            icon: (
              <img
                src={avatarSrc}
                alt={t(
                  `member.avtars.avatarFor${
                    isAvatar ? "Partnership" : "Friendship"
                  }`
                )}
              />
            ),
            bodyContent: (
              <Button
                className="picture_btn"
                aria-label={
                  card.type === "partnership.avatar"
                    ? t("member.avtars.changePictureP")
                    : t("member.avtars.changePictureF")
                }
              >
                {t("member.avtars.changePicture")}
              </Button>
            ),
            onClick: () => {
              setUploadType(card.type);
                setAvatar(card.type)
            },
            className: "available_avatar",
          });
        } else if (isAudio) {
          const audioUrl =
            card.type === "partnership.audio"
              ? partnershipAudio
              : friendshipAudio;

          return renderCard({
            header: card.header,
            icon: <AudioButton url={audioUrl} />,
            bodyContent: (
              <Button
                className="picture_btn"
                aria-label={
                  card.type === "partnership.audio"
                    ? "manage audio for Partnership"
                    : "manage audio for Friendship"
                }
              >
                {t("member.avtars.changeAudio")}
              </Button>
            ),
            onClick: () => navigate("/personal-details/audio"),
            className: "available_audio",
          });
        }

        return null;
      })}
      {isUploadImgeOpen ? (
        <UploadImage
          show={isUploadImgeOpen}
          type={
            uploadType.includes("partnership") ? "partnership" : "friendship"
          }
          handleClose={closeUploadImagePopup}
          setAvatar={
            uploadType.includes("partnership")
              ? updatePartnerShipAvatar
              : updateFriendShipAvatar
          }
          avatarUrl={
            uploadType.includes("partnership")
              ? partnerShipAvatar
              : friendShipAvatar
          }
          isImageLoading={isImageLoading}
        />
      ) : null}
    </div>
  );
}

export default YourRelationships;
