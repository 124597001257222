import React, { useEffect, useState, useRef } from 'react'
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleInfo, faMultiply
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert';
import { Editor } from "react-draft-wysiwyg";
import 'draft-js/dist/Draft.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import Select from 'react-select';
import {
    EditorState,
    convertToRaw,
} from "draft-js";

function TextQuestions(props) {
    const [error, setError] = useState(false);

    const {
        question,
        questionReference,
        errResponse,
        answers,
        setAnswers,
        onAlertOpen,
        qgroup,
        handleOptionClick,
        handleFreeText,
        errorMgsByID,
        setEditorStates,
        editorStates,
        setSelectedChildrenAge,
        selectedChildrenAge,
        freeTextError,
        freeTextId,
        numberOfChildren,
        communityContactErrors
    } = props;
    const { t, i18n } = useTranslation();

    const onFreetextSave = (htmlContent, question, qgroup, editorState) => {
        handleOptionClick(question, htmlContent, qgroup, editorState)
        if (htmlContent !== "") setError(false)
    };

    const onEditorStateChange = (editorState, question) => {
        setEditorStates(prevStates => ({
            ...prevStates,
            [question.id]: editorState
        }));
    };

    const options = [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
        { value: 5, label: 5 },
        { value: 6, label: 6 },
        { value: 7, label: 7 },
        { value: 8, label: 8 },
        { value: 9, label: 9 },
        { value: 10, label: 10 },
        { value: 11, label: 11 },
        { value: 12, label: 12 },
        { value: 13, label: 13 },
        { value: 14, label: 14 },
        { value: 15, label: 15 },
        { value: 16, label: 16 },
        { value: 17, label: 17 },
        { value: 18, label: 18 },
        { value: 19, label: 19 },
        { value: 20, label: 20 },
    ];   

    const handleTapBlockFreeText = (e, question) => {
        if (question.required && e.type === "keydown" && e.key === "Tab") {
            const isError = handleFreeText(e, question);
            if (isError) {
                e.preventDefault();
            }
        }
    }

    const handleSelect = (question, selectedOption, item, id) => {
        const updatedItems = [...(selectedChildrenAge || []), selectedOption.value];

        if (updatedItems.length <= numberOfChildren) {
            setSelectedChildrenAge(updatedItems);
            handleOptionClick(question, updatedItems, id);
        }
    };

    const handleRemove = (index, question, item, id) => {
        const updatedItems = [...selectedChildrenAge];
        updatedItems.splice(index, 1);
        setSelectedChildrenAge(updatedItems);
        handleOptionClick(question, updatedItems, id)
    };

    let textQuestions =
        <div
            key={`text-${question.id}`}
            className='questionair_alignment'
            ref={(e) => questionReference(question, e)}
        >
            {question.enabledQuestion === true ? <div>
                <Form.Group className="mt-3 text_question">
                    <span className="disriptionI_label">
                        {question?.description ?
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                onClick={() => onAlertOpen(question?.description)}
                            /> : null}
                        <Form.Label className="radio_label" htmlFor={question.id}>
                            {question.name} <span className='required_asterisks' aria-hidden="true">{question.required ? '*' : null}</span>
                        </Form.Label>
                    </span>
                    {question?.textQuestion?.type == 'TextArea' ?
                        <>
                            {question.id != 204 ?
                                <Form.Control
                                    value={answers[question.id] != null && answers[question.id].answerValues != null && answers[question.id].answerValues.length > 0
                                        ? answers[question.id]?.answerValues[0] : ''}
                                    as="textarea"
                                    className={errResponse?.errorMessageByQuestionId?.[question.id] && 'is-invalid'}
                                    id={question.id}
                                    aria-label={errResponse?.errorMessageByQuestionId?.[question.id] && errorMgsByID}
                                    aria-required={question.required ? 'true' : 'false'}
                                    onChange={(e) => {
                                        handleOptionClick(question, e.target.value, qgroup)
                                    }}
                                    onKeyDown={(e) => handleTapBlockFreeText(e, question)}
                                    onBlur={(e) => handleFreeText(e, question)}
                                />
                                :
                                <div className={question.required && error && freeTextError === question.id && "is-invalid"}>
                                    {selectedChildrenAge?.length != numberOfChildren ? (
                                        <Select
                                            options={options}
                                            onChange={(e) => handleSelect(question, e, qgroup, question.id)}
                                            className={question.required && error && freeTextError === question.id && "is-invalid"}
                                            placeholder="Select Age of children"
                                            isClearable={false}
                                            isSearchable={true}
                                            value={null}
                                        />
                                    ) : ""}
                                    <div className="selected-items">
                                        {selectedChildrenAge?.map((item, index) => (
                                            <div key={index} className="selected-item">
                                                {item}
                                                <div className='selected-item-button' onClick={() => handleRemove(index, question, item, question.id)}><FontAwesomeIcon icon={faMultiply} /></div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                        </>
                        :
                        null
                    }
                    {question?.textQuestion?.type == 'RichTextArea' ?                   
                        <Editor
                            handlePastedText={() => false}
                            editorState={editorStates[question.id] || EditorState.createEmpty()}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(editorState) => {
                                onEditorStateChange(editorState, question);
                                let htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent())).trim();
                                onFreetextSave(htmlContent, question, qgroup, editorState);
                            }}
                            toolbar={{
                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'emoji', 'history'],
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                            }}
                        /> : null
                    }
                    {question.askVisibleToOthers === true ?
                        <Form.Check id={`profileVisible${question.id}`}
                            name={`profileVisible${question.id}`}
                            type="checkbox"
                            label={t('common.visibleToOthers')}
                            className='msg_pre_checkbox'
                            onClick={(e) => {
                                if (answers?.[question.id]?.answerValues.length > 0) {
                                    answers[question.id].profileVisible = e.target.checked
                                    setAnswers(answers)
                                }
                            }}
                        >
                        </Form.Check> : null}
                </Form.Group>
                {communityContactErrors?.[question.id] && (
                    <Alert className='alert_questionaire'
                        variant="danger" id={`err_${question.id}`}>{communityContactErrors?.find((error) => error.id == question.id).errorMessage}</Alert>
                )}
                {errResponse?.errorMessageByQuestionId?.[question.id] && (
                    <Alert className='alert_questionaire'
                        variant="danger" id={`err_${question.id}`}>{errResponse?.errorMessageByQuestionId?.[question.id]}</Alert>
                )}

                {freeTextError && freeTextId && freeTextId === question.id &&
                    <Alert className='alert_questionaire' id={question.id}
                        variant="danger" >
                        {freeTextError ? freeTextError : ""}
                    </Alert>}
            </div> : null}
        </div>

    return (
        <div>{textQuestions}</div>
    )
}

export default TextQuestions;