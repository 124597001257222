import React, { useContext, useEffect, useState, useRef } from 'react';
import GlkHeader from '../../components/Header/GlkHeader';
import './personalData.css';
import './subscription.css';
import membershipIcon from '../../assets/images/membership.png';
import { useTranslation } from 'react-i18next';
import Banner from '../../components/banner/banner';
import { Accordion, Alert, Form, Button, Col, Spinner,  Modal, ModalBody } from 'react-bootstrap';
import { getServiceOffer, getUpgradeOffer, saveOffer } from './membershipServices';
import { LoginContext } from '../../context/loginContext';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Toaster from '../Toaster/Toaster';
import AlertModal from '../commonComponents/alert';
import SubscriptionDetail from './subscriptionDetail';

function Subscription() {
  const [serviceOffer, setServiceOffer] = useState(null);
  const [upgradeOffers, setUpgradeOffers] = useState(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState({upgrade: false, serviceOffer: false});
  const [upgradeOffersErrors, setUpgradeOffersErrors] = useState({error: [], message: ''});
  const [serviceOfferErrors, setServiceOfferErrors] = useState({error: [], message: ''});
  const [isError, setIsError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState({productId: [] , productDetail:''});
  const [openOfferConfirmationPopup, setOpenOfferConfirmationPopup] = useState(false);
  const [offerError, setOfferError] = useState({ error: false, errorMsg: '' });
  const [toastMessage, setToastMessage] = useState();
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState();
  const [activeKey, setActiveKey] = useState('0');

  const { t,i18n } = useTranslation();

  let {
    loginData: {
      accessToken
    },
  } = useContext(LoginContext);

  const token_lang = {
    language: i18n.language,
    accessToken,
  };

  const data = {
    image: membershipIcon,
    menuName: t('mymembership.mymembership'),
    header: t('mymembership.subscription.subscription'),
    description: t('mymembership.subscription.description'),
    color: '#063978'
  };

  const ViewUpgrades = () => {
    setLoader(true);
    const fetchUpgrade = async () => {
      const offersPayload = {
        accessToken: accessToken,
        language: i18n.language
      };
      try {
        const upgradeResponse = await getUpgradeOffer(offersPayload);
        setLoader(false);
        if (upgradeResponse.status === 200) {
          setUpgradeOffers(upgradeResponse.data);
        } else if (upgradeResponse.status === 400) {
          setIsError(true);
          setUpgradeOffers(null);
          setUpgradeOffersErrors({ error: upgradeResponse?.data?.error, message: upgradeResponse?.data?.message })
        } else {
          setIsError(true);
          setUpgradeOffers(null);
          setUpgradeOffersErrors({ error: '', message: t('mymembership.subscription.no_upgrade_offer') })
        }
      } catch (error) {
        console.error('Error fetching upgrade offer:', error);
        setUpgradeOffers(null);
      }
    };
    fetchUpgrade();
  }

  const viewServiceOffer = () => {
    setLoader(true);
    const fetchServiceOffers = async () => {
      const offersPayload = {
        accessToken: accessToken,
        language: i18n.language
      };
      try {
        const serviceResponse = await getServiceOffer(offersPayload);
        setLoader(false);
        if (serviceResponse.status === 200) {
          setServiceOffer(serviceResponse.data);
        } else if (serviceResponse.status === 400) {
          setIsError(true);
          setServiceOffer(null);
          setServiceOfferErrors({ error: serviceResponse?.data?.error, message: serviceResponse?.data?.message })
        } else {
          setIsError(true);
          setServiceOffer(null);
          setServiceOfferErrors({ error: '', message: t('mymembership.subscription.no_service_offer') })
        }
      } catch (error) {
        console.error('Error fetching service offer:', error);
        setServiceOffer(null);
      }
    };
    fetchServiceOffers();
  }

  const toggleAccordianView = (isOpen) => {
    setOfferError({ error: false, errorMsg: '' });
    if (upgradeOffers == null && isOpen.upgrade) {
      setSelectedOffer({productId: [] , productDetail:''});
      ViewUpgrades();
    }else{
      setIsError(false);
    }
    
    if (serviceOffer == null && isOpen.serviceOffer) {
      viewServiceOffer();
      setSelectedOffer({productId: [] , productDetail:''});
    }
    else{
      setIsError(false);
    }
    setIsAccordionOpen(isOpen);
  };

  function serivceOffer() {
    if (selectedOffer.productId?.length) {
      setOfferError({ error: false, errorMsg: '' });
      setOpenOfferConfirmationPopup(true)      
    }
    else{
      setOfferError({ error: true, errorMsg: t('mymembership.subscription.select_offer' )})
    }   
  }

  function upgradeOffer() {
    if (selectedOffer.productId?.length) {
      setOfferError({ error: false, errorMsg: '' });
      setOpenOfferConfirmationPopup(true)      
    }
    else{
      setOfferError({ error: true, errorMsg: t('mymembership.subscription.upgrade_offer' )})
    }   
  }

  function confirmOffer() {
    const payload = {
      productId: selectedOffer.productId?.[0],
      paymentMethod: null
    }
    saveOffer(payload, accessToken).then((result) => {
      if (result?.status === 200) {
        handleShowToast()
        setOpenOfferConfirmationPopup(false);
        setOfferError({ error: false, errorMsg: '' })
        setToastMessage( t('mymembership.subscription.success' ));
        if (currentPaymentMethod == "PREPAYMENT") {
          setServiceOffer(null);
          setUpgradeOffers(null);
          setActiveKey();
          window.scrollTo(0, 0);
          toggleAccordianView({ upgrade: false, serviceOffer: false })
        }
      }
      else{
        setOpenOfferConfirmationPopup(false);
        setOfferError({ error: true, errorMsg: result?.data?.message })
      }
    })
    .catch((err) => {
    })
  }

  function closeOfferConfirmationPopup() {
    setOpenOfferConfirmationPopup(false)      
  }

  const toastRef = useRef();
	const handleShowToast = () => {
		const toastElement = toastRef.current;
		const options = {
			animation: true,
			autohide: true,
			delay: 3000,
		};
		if (toastElement) {
			const bootstrapToast = new bootstrap.Toast(toastElement, options);
			bootstrapToast.show();
		}
	};  

  function selectActiveKey(key) {
    setActiveKey(key);
  }
  
  return (
    <div>
    <GlkHeader/>
    <main className="main_wrapper membership_wrapper">
      <Banner data={data} />
      <div className='questionaire_in_menubar'>
        <div className="membership_container">
          <Accordion activeKey={activeKey} onSelect={selectActiveKey}>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="accordian_header">{t('mymembership.subscription.subscription')}</Accordion.Header>
              <Accordion.Body>
                <SubscriptionDetail
                setToastMessage={setToastMessage}
                handleShowToast={handleShowToast}
                setCurrentPaymentMethod={setCurrentPaymentMethod}
                />
              </Accordion.Body>
            </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header
                  onClick={() => toggleAccordianView({ upgrade: !isAccordionOpen.upgrade, serviceOffer: false })}
                  className="accordian_header">{t('mymembership.subscription.upgrade')}</Accordion.Header>
                <Accordion.Body>
                  <div className="upgrade-offers">
                    {!loader ? <div className="products-list">
                      {upgradeOffers?.products?.map((product) => (
                        <div key={product.id} className="product">
                          <Form.Group className="offer_btn">
                            <Form.Check
                              className='msg_pre_checkbox'
                              type="radio"
                              name="offer"
                              onChange={() => {
                                setSelectedOffer({productId: [product.id] , productDetail: product})}
                              }
                            />
                          </Form.Group>
                          <span
                            dangerouslySetInnerHTML={{ __html: product.value }}
                            style={{ marginLeft: "10px" }}
                          />
                        </div>
                      ))}
                    </div> : <div className='subscription_loader'> <Spinner /></div>}
                    {!loader && isError ? <Alert
                      className='member_address'
                      variant="danger"
                      id='main_alert'
                      dismissible
                      onClose={() => setIsError(false)}
                    >
                      <p>{upgradeOffersErrors.message}</p>
                      {upgradeOffersErrors.error?.length ? upgradeOffersErrors.error?.map((error) => (<p>{error}</p>)) : null}
                    </Alert> : null}
                    <AlertModal isDismissible={true} className={'mb-3'} show={offerError.error} setShow={() => setOfferError({ error: false, errorMsg: '' })} heading={'Error'} body={offerError.errorMsg}></AlertModal>
                    {upgradeOffers && <div className="changePass_btn">
                      <Button onClick={upgradeOffer} style={{ marginTop: '0px' }} className="mx-5" as={Col} variant="primary" type="submit">
                      { t('mymembership.subscription.purchase')}
                      </Button>
                    </div>}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header
                  onClick={() => toggleAccordianView({ upgrade: false, serviceOffer: !isAccordionOpen.serviceOffer })}
                  className="accordian_header">{t('mymembership.subscription.serviceoffer')}</Accordion.Header>
                <Accordion.Body>
                  <div className="upgrade-offers">
                    {!loader ? <div className="products-list">
                      {serviceOffer?.products?.map((product) => (
                        <div key={product.id} className="product">
                          <Form.Group className="offer_btn">
                            <Form.Check
                              className='msg_pre_checkbox'
                              type="radio"
                              name="offer"
                              onChange={(e) => {
                                setSelectedOffer({productId: [product.id] , productDetail: product})
                              }}
                            />
                          </Form.Group>
                          <span
                            dangerouslySetInnerHTML={{ __html: product.value }}
                            style={{ marginLeft: "10px" }}
                          />
                        </div>
                      ))}
                    </div> : <div className='subscription_loader'> <Spinner /></div>}
                    {!loader && isError ? <Alert
                      className='member_address'
                      variant="danger"
                      id='main_alert'
                      dismissible
                      onClose={() => setIsError(false)}
                    >
                      <p>{serviceOfferErrors.message}</p>
                      {serviceOfferErrors.error?.length ? serviceOfferErrors.error?.map((error) => (<p>{error}</p>)) : null}
                    </Alert> : null}
                    <AlertModal isDismissible={true} className={'mb-3'} show={offerError.error} setShow={() => setOfferError({ error: false, errorMsg: '' })} heading={'Error'} body={offerError.errorMsg}></AlertModal>
                    <div className="changePass_btn">

                      {serviceOffer && <Button 
                      onClick={serivceOffer}
                      style={{ marginTop: '0px' }} 
                      className="mx-5" as={Col} 
                      variant="primary" 
                      type="submit">
                        { t('mymembership.subscription.purchase')}
                      </Button>}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header className="accordian_header">{t('mymembership.subscription.activeCategories')}</Accordion.Header>
              <Accordion.Body>
                <p className="upgrads">{t('mymembership.subscription.deselectCategory')}</p>
                <p className="m-0"><strong>{t('mymembership.subscription.yourActiveCategories')}</strong></p>
                <div className="active_categories">
                  <Form.Group className="my-3 category_f" id="formGridCheckbox">
                    <Form.Check className="msg_pre_checkbox" type="checkbox" label={t('mymembership.subscription.friendship')} />
                  </Form.Group>
                  <Form.Group className="my-3 category_f" id="formGridCheckbox">
                    <Form.Check className="msg_pre_checkbox" type="checkbox" label={t('mymembership.subscription.partnership')} />
                  </Form.Group>
                </div>
                <p>{t('mymembership.subscription.usingAllOptions')}</p>
                <div className="changePass_btn">
                  <Button style={{ marginTop: '0px' }} className="mx-5" as={Col} variant="primary" type="submit">
                    {t('mymembership.subscription.cancel')}
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header className="accordian_header">{t('mymembership.subscription.suspendService')}</Accordion.Header>
              <Accordion.Body>
                <p className="upgrads">{t('mymembership.subscription.deselectCategory')}</p>
                <div className="">
                  <Form.Group className="my-3 category_f" id="formGridCheckbox">
                    <Form.Check className="msg_pre_checkbox" type="checkbox" label={t('mymembership.subscription.friendship')} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Control type="password" placeholder={t('mymembership.subscription.suspendUntil')} />
                  </Form.Group>
                  <Form.Group className="my-3 category_f" id="formGridCheckbox">
                    <Form.Check className="msg_pre_checkbox" type="checkbox" label={t('mymembership.subscription.partnership')} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Control type="password" placeholder={t('mymembership.subscription.suspendUntil')} />
                  </Form.Group>
                </div>
                <div className="changePass_btn">
                  <Button className="mx-5 mtop" as={Col} variant="primary" type="submit">
                    {t('mymembership.subscription.save')}
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
		</main>
      <Modal
        show={openOfferConfirmationPopup}
        onHide={() => closeOfferConfirmationPopup()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{ t('mymembership.subscription.confirm_offer')}</Modal.Title>
        </Modal.Header>
        <ModalBody>
          <Form>
            <Form.Group className="mb-3" controlId="popupForm.ControlInput1">
              <Form.Label>
              <p>{ t('mymembership.subscription.confirm_message',{ product_name : selectedOffer.productDetail.name, amount: selectedOffer.productDetail.amount})}</p>
              </Form.Label>
            </Form.Group>
          </Form>
        </ModalBody>
        <Modal.Footer>
          <Button variant="outline-danger"
            onClick={() => closeOfferConfirmationPopup()}
          >
            {t('registration.buttons.no')}
          </Button>
          <Button variant="secondary"
            onClick={() => confirmOffer()}
          >
            {t('registration.buttons.yes')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Toaster
        toastMessage={toastMessage}
        toastRef={toastRef}
        type={'success'}
      />
    </div>
  );
}

export default Subscription;
