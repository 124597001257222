
// import React from "react";
// import "./index.css";
// import App from "./App";
// import { render } from "react-dom";    

// /*** this change made resolved editor formating and dropdown issue ***/ 

// // const root = ReactDOM.createRoot(document.getElementById("root"));
// // root.render(

// render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

/****  ReactDOM.render is no longer supported in React 18.
//  Use createRoot instead. Until you switch to the new API, your app will behave as if it’s running React 17. Learn more:
//  https://reactjs.org/link/switch-to-createroot  ****/

// import { createRoot } from 'react-dom/client';
// import App from "./App";
// console.log('this is called');


// const container = document.getElementById("root");
// const root = createRoot(container);
// root.render(<App />);

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App'; // Adjust the path if App.js is in a different location
import './index.css'; // Import your global styles (optional)

const container = document.getElementById('root'); // Make sure this matches the div in index.html
const root = createRoot(container); // React 18's createRoot API
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

