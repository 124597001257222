import React, { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../context/loginContext';
import {
  getCountry,
  getRegion,
  getZipCode,
  getContinentForMemberAddress,
  saveMemberAddress,
} from './registrationProcessService';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import Alert from 'react-bootstrap/Alert';
import { autoFocusByID } from '../commonMethods';
import usePreventBack from './preventBack/preventBack'
import AlertModal from '../commonComponents/alert';

function MemberAddress(props) {
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [countryId, setCountryId] = useState(null);
  const [continent, setContinent] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isFirstNameError, setIsFirstNameError] = useState(false);
  const [firstNameMsg, setFirstNameMsg] = useState('');
  const [isLastNameError, setIsLastNameError] = useState(false);
  const [LastNameMsg, setLastNameMsg] = useState('');
  const [isError, setIsError] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [address, setAddress] = useState([
    {
      continent: '',
      country: '',
      region: '',
      zip: '',
      isZipMissing: false,
      newZip: '',
      city: '',
      street: '',
    },
  ]);
  const [addressForView, setAddressForView] = useState([
    {
      continent: '',
      country: '',
      region: '',
      zip: '',
      isZipMissing: false,
      newZip: '',
      city: '',
      street: '',
    },
  ]);
  const [isAddressError, setIsAddressError] = useState([
    {
      continent: false,
      country: false,
      region: false,
      zip: false,
    },
  ]);
  usePreventBack();
  const {
    loginData: { memberDetails, accessToken },
    dispatch
  } = useContext(LoginContext);

  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const token_lang = {
    language: currentLang,
    accessToken,
  };

  useEffect(() => {
    const fetchContinentData = async () => {
      const result = await getContinentForMemberAddress(token_lang);
      if (result?.status === 200) {
        const modifiedContinent = result.data.locations.map((item) => ({
          value: item.id,
          label: item.value,
        }));
        setContinent(modifiedContinent);
      }
    };
    fetchContinentData();
  }, []);

  const getCountryData = async (id) => {
    const data = await getCountry(token_lang, id);
    if (data?.status === 200) {
      const modifiedCountry = data.data.locations.map((item) => ({
        value: item.id,
        label: item.value,
      }));
      setCountries(modifiedCountry);
    }
  };

  const getRegionData = async (countryId) => {
    const res = await getRegion(accessToken, countryId);
    if (res?.status === 200) {
      const modifiedRegions = res.data.locations.map((item) => ({
        value: item.id,
        label: item.value,
      }));
      setRegions(modifiedRegions);
    }
  };

  const onContinentSelect = (e, index) => {
    getCountryData(e.value);
    setDataAddress(index, 'continent', e.value);
    setDataAddressForView(index, 'continent', e);
    validateSELOptionWithTAB({}, {value: e.value}, 'continent', index);
    onResetSelectOptionByLevel('continent', index)
  }

  const onCountrySelect = (e, index) => {
    getRegionData(e.value);
    setCountryId(e.value);
    setDataAddress(index, 'country', e.value);
    setDataAddressForView(index, 'country', e);
    validateSELOptionWithTAB({}, {value: e.value}, 'country', index);
    onResetSelectOptionByLevel('country', index)
  }

  const onRegionSelect = (e, index) => {
    setDataAddress(index, 'region', e.value);
    setDataAddressForView(index, 'region', e);
    validateSELOptionWithTAB({}, {value: e.value}, 'region', index);
    onResetSelectOptionByLevel('region', index)
  }

  const onNewZipSelect = (e, index) => {
    setDataAddress(index, 'newZip', e.target.value);
    setDataAddressForView(index, 'newZip', e.target.value);
  }

  const onZipSelect = (e, index) => {
    setDataAddress(index, 'zip', e.value);
    setDataAddressForView(index, 'zip', e);
    validateSELOptionWithTAB({}, {value: e.value}, 'zip', index);
  }

  const onResetSelectOptionByLevel = (type, index) => {
    if(type === 'continent') {
      //Reset select option country
      setDataAddress(index, 'country', '');
      setDataAddressForView(index, 'country', '');
      //Reset select option region
      setDataAddress(index, 'region', '');
      setDataAddressForView(index, 'region', '');
      //Reset select option zip
      setDataAddress(index, 'zip', '');
      setDataAddressForView(index, 'zip', '');
    }

    if(type === 'country') {
      //Reset select option region
      setDataAddress(index, 'region', '');
      setDataAddressForView(index, 'region', '');
      //Reset select option zip
      setDataAddress(index, 'zip', '');
      setDataAddressForView(index, 'zip', '');
    }

    if(type === 'region') {
      //Reset select option zip
      setDataAddress(index, 'zip', '');
      setDataAddressForView(index, 'zip', '');
    }
  }

  async function loadZipOptions(search, loadedOptions, { page }) {
    const res = await getZipCode(accessToken, countryId, page, search);
    if (res?.status === 200) {
      const adaptedJson = {
        results: res.data.locations.map((item) => ({
          value: item.id,
          label: item.value,
        })),
        has_more: res.data.locations.length > 0,
      };

      return {
        options: adaptedJson.results,
        hasMore: adaptedJson.has_more,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  const addAddress = () => {
    setAddress((prevAddresses) => [
      ...prevAddresses,
      {
        continent: '',
        country: '',
        region: '',
        zip: '',
        isZipMissing: false,
        newZip: '',
        city: '',
        street: '',
      },
    ]);
    setAddressForView((prevAddresses) => [
      ...prevAddresses,
      {
        continent: '',
        country: '',
        region: '',
        zip: '',
        isZipMissing: false,
        newZip: '',
        city: '',
        street: '',
      },
    ]);
    setIsAddressError((prevAddresses) => [
      ...prevAddresses,
      {
        continent: false,
        country: false,
        region: false,
        zip: false,
      },
    ]);

    autoFocusByID("formGridContinent"+address.length)
  };

  const setDataAddressForView = (index, field, value) => {
    setAddressForView((prevAddresses) => {
      let updatedAddresses = [...prevAddresses];
      updatedAddresses[index][field] = value;
      return updatedAddresses;
    });
  };

  const setDataAddress = (index, field, value) => {
    setAddress((prevAddresses) => {
      const updatedAddresses = [...prevAddresses];
      updatedAddresses[index][field] = value;
      return updatedAddresses;
    });
  };

  const setIsDataAddressError = (index, field, value) => {
    setIsAddressError((prevAddresses) => {
      const updatedAddresses = [...prevAddresses];
      updatedAddresses[index][field] = value;
      return updatedAddresses;
    });
  };

  const saveAddress = async () => {
    if (firstName === '') {
      setIsError(t('registration.address.firstname'));
      setIsAlertOpen(true);
      return;
    }

    if (lastName === '') {
      setIsError(t('registration.address.lastname'));
      setIsAlertOpen(true);
      return;
    }

    for (let err of address) {
      if (err?.continent === '') {
        setIsError(t('registration.address.continent'));
        setIsAlertOpen(true);
        return;
      }
      if (err?.country === '') {
        setIsError(t('registration.address.country'));
        setIsAlertOpen(true);
        return;
      }
      if (err?.region === '') {
        setIsError(t('registration.address.region'));
        setIsAlertOpen(true);
        return;
      }
      if (err?.zip === '' && err?.newZip === '') {
        setIsError(t('registration.address.zip'));
        setIsAlertOpen(true);
        return;
      }
    }

    setIsAlertOpen(false);

    const body = {
      firstName,
      lastName,
      addresses: address,
    };

    const response = await saveMemberAddress(body, accessToken);

    if (response?.status === 200) {
      dispatch({ type: 'CURRENT_URL', data: response.data });
      props?.setQuestionaireId(response?.data?.nextStep);
    } else {
      response?.data?.errorMessages?.forEach((err) => {
        setIsError(err);
        setIsAlertOpen(true);
      });
    }
  };


  const deleteAddress = (indexToDelete) => {
    setAddress((prevAddresses) => prevAddresses.filter((_, index) => index !== indexToDelete));
    setAddressForView((prevAddr) => prevAddr.filter((_, index) => index !== indexToDelete));
  };


  const autofillAnswers = () => {
    setFirstName('John');
    setLastName('Doe');
    setAddress([
      {
        continent: '3',
        country: '53', 
        region: '1069',
        zip: '13934',
        isZipMissing: false,
        newZip: '',
        city: 'Smart City',
        street: '123 S S',
      },
    ]);
    setAddressForView([
      {
          continent: {
              "value": 3,
              "label": "Europe"
          },
          country: {
              "value": 53,
              "label": "Germany"
          },
          region: {
              "value": 1069,
              "label": "Berlin"
          },
          zip: {
              "value": 13934,
              "label": "15834"
          },
          isZipMissing: false,
          newZip: "",
          city: "Smart city",
          street: "123 S S"
      }
  ]);
  };

  const validateWithTAB = (evt) => {
    let isError = null;
    if(evt.type === 'keydown' && evt.keyCode === 9 && evt.shiftKey !== true) {
      if(evt.target.id === 'formGridFirstName') isError = handleFirstName(evt);
      if(evt.target.id === 'formGridLastName') isError = handleLastName(evt);
      if(evt.target.value === '' || isError) evt.preventDefault();
    }
  }

  const validateSELOptionWithTAB = (evt, isdata, type, index) => {
    let rsType = '';
    if(type === 'continent') rsType = 'continent';
    if(type === 'country') rsType = 'country';
    if(type === 'region') rsType = 'region';
    if(type === 'zip') rsType = 'zip';

    if(!isdata && !isdata.value) {
      setIsDataAddressError(index, rsType, true)
    } else {
      setIsDataAddressError(index, rsType, false)
    }
    if(evt.type === "keydown" && evt.keyCode === 9) {
      if(!isdata && !isdata.value) autoFocusByID(evt.target.id)
    }
  }

  const handleFirstName = (e) => {
    if(!e.target.value) {
      setIsFirstNameError(true)
      setFirstNameMsg(t('registration.address.firstname'))
      return true;
    } else {
      setIsFirstNameError(false)
      setFirstNameMsg('')
      return false;
    }
  }

  const handleLastName = (e) => {
    if(!e.target.value) {
      setIsLastNameError(true)
      setLastNameMsg(t('registration.address.lastname'))
      return true;
    } else {
      setIsLastNameError(false)
      setLastNameMsg('')
      return false;
    }
  }

  const selectStyles = () => ({
    control: (base) => ({
      ...base,
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused 
        ? '#e2e5cb'      
        : state.isSelected 
        ? '#f0f0f0'       
        : '#fff',         
      color: state.isSelected || state.isFocused
        ? '#333'        
        : '#333',      
      cursor: 'pointer',
    }),
    menu: (base) => ({
      ...base,
      zIndex: 1000,
    }),
  });
  

  return (
    <>
      <div className="looking_for">
        <div className="questionnaireHeader">
          <h3 className="lineH3 text-center"><strong>{t('member.yourmembership.membership')}</strong></h3>
          <h4>{t('member.personaldata.personaldetails.yourAddress')}</h4>
          <p>{t('member.personaldata.personaldetails.addressDescription')}</p>
        </div>
      </div>
      {import.meta.env.VITE_ENV != 'PROD' ?
        <div className="mt-3 mb-3 auto_fill_wrapper">
          <Button
            className='mx-5'
            variant="success"
            onClick={autofillAnswers}
          >
            {t('registration.buttons.autofill')}
          </Button>
          <Button
            className='mx-5'
            variant="primary"
            onClick={() => saveAddress()}
          >
            {t('registration.buttons.next')}
          </Button>
        </div> : null}
      <div className='from_control questionair_container member_address_registration'>
        <div>
          <h3 className='questionair_heading mb-5'>address</h3>
        </div>
        <div className="questions_wrapper">
          <Form>
            <Row className='mb-3 for_mobile'>
              <Form.Group as={Col} controlId='formGridAlias'>
                <Form.Label>{t('member.personaldata.personaldetails.alias')} <span className='required_asterisks' aria-hidden="true">*</span></Form.Label>
                <Form.Control type='text' disabled readOnly defaultValue={memberDetails?.alias} />
              </Form.Group>

              <Form.Group as={Col} controlId='formGridFirstName'>
                <Form.Label>{t('member.personaldata.personaldetails.firstname')} <span className='required_asterisks' aria-hidden="true">*</span></Form.Label>
                <Form.Control
                  aria-label={t('registration.address.firstname')} 
                  aria-required="true" 
                  autoComplete='off'
                  className={isFirstNameError && "is-invalid"}
                  type='text'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  onBlur={(e) => handleFirstName(e)}
                  onKeyDown={(e) => validateWithTAB(e)}
                />
                <AlertModal
                  className={'mt-3'}
                  show={isFirstNameError}
                  setShow={setIsFirstNameError}
                  heading={t('registration.address.firstname')}
                  body={firstNameMsg}>
                </AlertModal>
              </Form.Group>
              <Form.Group as={Col} controlId='formGridLastName'>
                <Form.Label>{t('member.personaldata.personaldetails.lastname')} <span className='required_asterisks' aria-hidden="true">*</span></Form.Label>
                <Form.Control
                  aria-label={t('registration.address.lastname')} 
                  aria-required="true" 
                  autoComplete='off'
                  className={isLastNameError && "is-invalid"}
                  type='text'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  onBlur={(e) => handleLastName(e)}
                  onKeyDown={(e) => validateWithTAB(e)}
                />
                <AlertModal
                  className={'mt-3'}
                  show={isLastNameError}
                  setShow={setIsLastNameError}
                  heading={t('registration.address.lastname')}
                  body={LastNameMsg}>
                </AlertModal>
              </Form.Group>
            </Row>
            {address.map((data, index) => (
              <fieldset key={index}>
                <legend className='legend-head'><h4>{`${t('member.personaldata.personaldetails.address')}`}</h4></legend>
                <Row className='mb-3 for_mobile'>
                  <Form.Group as={Col} controlId={'formGridContinent'+index}>
                    <Form.Label>{t('member.personaldata.personaldetails.continent')} <span className='required_asterisks' aria-hidden="true">*</span></Form.Label>
                    <Select
                      inputId={'formGridContinent'+index}
                      className={isAddressError[index].continent && "is-invalid"}
                      value={addressForView[index].continent}
                      options={continent}
                      onChange={(e) => onContinentSelect(e, index)}
                      onKeyDown={(e) => validateSELOptionWithTAB(e, addressForView[index].continent, 'continent', index)}
                      aria-label={t('registration.address.continent')} 
                      required={true} 
                      placeholder={false}
                      styles={selectStyles()}
                    />
                    <AlertModal
                      className={'mt-3'}
                      show={isAddressError[index].continent}
                      setShow={setIsAddressError}
                      heading={t('registration.address.continent')}
                      body={t('registration.address.continent')}
                      >
                    </AlertModal>
                  </Form.Group>

                  <Form.Group as={Col} controlId={'formGridCountry'+index}>
                    <Form.Label>{t('member.personaldata.personaldetails.country')} <span className='required_asterisks' aria-hidden="true">*</span></Form.Label>
                    <Select
                      inputId={'formGridCountry'+index}
                      className={isAddressError[index].country && "is-invalid"}
                      value={addressForView[index].country}
                      isDisabled={!addressForView[index].continent}
                      options={countries}
                      onChange={(e) => onCountrySelect(e, index)}
                      onKeyDown={(e) => validateSELOptionWithTAB(e, addressForView[index].country, 'country', index)}
                      aria-label={t('registration.address.country')} 
                      required={true} 
                      placeholder={false}
                      styles={selectStyles()}
                    />
                   
                    <AlertModal
                      className={'mt-3'}
                      show={isAddressError[index].country}
                      setShow={setIsAddressError}
                      heading={t('registration.address.country')}
                      body={t('registration.address.country')}
                      >
                    </AlertModal>
                  </Form.Group>
                  <Form.Group as={Col} controlId={'formGridRegion'+index}>
                    <Form.Label>{t('member.personaldata.personaldetails.region')} <span className='required_asterisks' aria-hidden="true">*</span></Form.Label>
                    <Select
                      inputId={'formGridRegion'+index}
                      className={isAddressError[index].region && "is-invalid"}
                      value={addressForView[index].region}
                      isDisabled={!addressForView[index].country}
                      options={regions}
                      onChange={(e) => onRegionSelect(e, index)}
                      onKeyDown={(e) => validateSELOptionWithTAB(e, addressForView[index].region, 'region', index)}
                      aria-label={t('registration.address.region')} 
                      required={true} 
                      placeholder={false}
                      styles={selectStyles()}
                    />
                    <AlertModal
                      className={'mt-3'}
                      show={isAddressError[index].region}
                      setShow={setIsAddressError}
                      heading={t('registration.address.region')}
                      body={t('registration.address.region')}
                      >
                    </AlertModal>
                  </Form.Group>
                </Row>
                <Row className='for_mobile'>
                  {data.isZipMissing ? (
                    <Form.Group as={Col} controlId='formGridNewZip'>
                      <Form.Label>{t('registration.buttons.newzip')} <span className='required_asterisks' aria-hidden="true">*</span></Form.Label>
                      <Form.Control
                        onChange={(e) => onNewZipSelect(e, index)}
                        type='text'
                        value={addressForView[index].newZip}
                        aria-label={t('registration.buttons.newzip')} 
                        required={true} 
                         styles={selectStyles()}
                      />
                    </Form.Group>
                  ) : (
                    <Form.Group as={Col} controlId={'formGridZip'+index}>
                      <Form.Label>{t('member.personaldata.personaldetails.zip')} <span className='required_asterisks' aria-hidden="true">*</span></Form.Label>
                      <AsyncPaginate
                        inputId={'formGridZip'+index}
                        value={addressForView[index].zip}
                        className={isAddressError[index].zip ? "is-invalid async_paginate" : "async_paginate"}
                        loadOptions={loadZipOptions}
                        isDisabled={!addressForView[index].region}
                        cacheUniqs={[countryId]}
                        onChange={(e) => onZipSelect(e, index)}
                        onKeyDown={(e) => validateSELOptionWithTAB(e, addressForView[index].zip, 'zip', index)}
                        aria-label={t('registration.address.zip')} 
                        required={true} 
                        additional={{ page: 1 }}
                        placeholder={false}
                        styles={selectStyles()}
                      />
                    <AlertModal
                      className={'mt-3'}
                      show={isAddressError[index].zip}
                      setShow={setIsAddressError}
                      heading={t('registration.address.zip')}
                      body={t('registration.buttons.newzip')}
                      >
                    </AlertModal>
                    </Form.Group>
                  )}
                  <Form.Group as={Col} controlId={'formGridCity'+index}>
                  <Form.Label>{t('member.personaldata.personaldetails.city')}</Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        setDataAddress(index, 'city', e.target.value);
                        setDataAddressForView(index, 'city', e.target.value);
                      }}
                      aria-label={t('registration.address.city')}
                      autoComplete='off'
                      type='text'
                      value={addressForView[index].city}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId={'formGridStreet'+index}>
                  <Form.Label>{t('member.personaldata.personaldetails.street')}</Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        setDataAddress(index, 'street', e.target.value);
                        setDataAddressForView(index, 'street', e.target.value)
                      }}
                      aria-label={t('registration.address.street')}
                      autoComplete='off'
                      type='text'
                      value={addressForView[index].street}
                    />
                  </Form.Group>

                  <Row className='mb-3 mt-3 for_mobile'>
                    <Form.Group as={Col} className='missing_zip'>
                      <Form.Check
                        onChange={(e) => {
                          setDataAddress(index, 'isZipMissing', e.target.checked);
                          setDataAddressForView(index, 'isZipMissing', e.target.checked);
                        }}
                        type='checkbox'
                        label={t('member.personaldata.personaldetails.zipmissing')}
                        value={addressForView[index].isZipMissing}
                        id={`formGridCheckbox-${index}`}
                      />
                    </Form.Group>
                    {address.length === 1 ? null : <Button
                      onClick={() => deleteAddress(index)}
                      className='remove_address'
                      as={Col}
                      variant="danger"
                      type="submit">
                      {t('registration.buttons.removeaddress')}
                    </Button>}
                  </Row>
                </Row>
              </fieldset>
            ))}
            {isAlertOpen ? <Alert
              className='member_address'
              variant="danger"
              id='main_alert'
              dismissible
              onClose={() => setIsAlertOpen(false)}
            >
              <p>{isError}</p>
            </Alert> : ''}
            <div className="personal_data_btn">
              <Button
                onClick={() => addAddress()}
                className='mx-5 add_address'
                as={Col}
                variant="primary"
                type="submit">
                {t('member.personaldata.personaldetails.addfurtheraddress')}
              </Button>
              <Button
                onClick={() => saveAddress()}
                className='mx-5 address_next'
                as={Col}
                variant="primary"
                type="submit">
                {t('registration.buttons.next')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default MemberAddress;
