import React from 'react'
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleInfo
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert';
import { numberOnlyWithDigit } from '../../commonMethods';

function NumberQuestions(props) {
    const {
        question,
        questionReference,
        errResponse,
        answers,
        setAnswers,
        onAlertOpen,
        qgroup,
        handleOptionClick,
        updateErrorState,
        clearErrorState,
        errorMgsByID,
        validateMultipleRanges,
        gqIndex,
        qIndex
    } = props;

    const { t, i18n } = useTranslation();

    const handleTapNumberQuestion = (e, question, GqId) => {
        if (question.required) {
            if (e.target.value === "") {
                updateErrorState((t('registration.questionnaires.fieldRequired')), question.id)
            } else {
                if (e.target.min && e.target.max) {
                    validateMultipleRanges(e, question.id, GqId)
                } else {
                    clearErrorState(question.id);
                }
            }
        } else {
            if (e.target.min && e.target.max) {
                validateMultipleRanges(e, question.id, GqId)
            }
        }
    }

    const handleTapBlock = (e, question, GqId) => {
        if (e.type === "keydown" && e.keyCode === 9 && e.shiftKey !== true) {
            if (question.required) {
                if (e.target.value === "") {
                    updateErrorState((t('registration.questionnaires.fieldRequired')), question.id)
                    e.preventDefault()
                } else {
                    if (e.target.min && e.target.max) {
                        if (validateMultipleRanges(e, question.id, GqId)) e.preventDefault()
                    } else {
                        clearErrorState(question.id);
                    }
                }
            } else {
                if (e.target.min && e.target.max) {
                    if (validateMultipleRanges(e, question.id, GqId)) e.preventDefault()
                }
            }
        }
    }


    let numberQuestions = <div key={`number-${question.id}`} className='questionair_alignment'
        ref={(e) => questionReference(question, e)}
    >
        {question.enabledQuestion === true ? <div>
            <Form.Group className="mt-3 dropdown">
                <div className="disriptionI_label" id={`description-${question.id}`}>
                    {question?.description ?
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            onClick={() => onAlertOpen(question?.description)}
                        /> : null}
                    <Form.Label id={`label-${question.id}`} className="radio_label" htmlFor={question.id}>
                        {question.name} <span className='required_asterisks' aria-hidden="true">{question.required ? '*' : null}</span>
                    </Form.Label>
                </div>
                <Form.Control
                    value={answers[question.id]?.answerValues[0] || ''}
                    type="text"
                    id={question.id}
                    data-qid={(qgroup.questions.length == 2 || qgroup.questions.length == 3) ? qgroup.id : question.id}
                    aria-label={errResponse?.errorMessageByQuestionId?.[question.id] && errorMgsByID}
                    className={errResponse?.errorMessageByQuestionId?.[question.id] && 'is-invalid'}
                    min={question.numberQuestion.minValue}
                    max={question.numberQuestion.maxValue}
                    data-gqindex={gqIndex}
                    data-qindex={qIndex}
                    onChange={(e) => {
                        handleOptionClick(question, e.target.value, qgroup)
                    }}
                    onKeyUp={(e) => numberOnlyWithDigit(e, 3)}
                    onKeyDown={(e) => handleTapBlock(e, question, qgroup.id)}
                    onBlur={(e) => handleTapNumberQuestion(e, question, qgroup.id)}
                    aria-required={question.required ? 'true' : 'false'}
                />

                {question.askVisibleToOthers === true ?
                    <Form.Check id={`profileVisible${question.id}`}
                        name={`profileVisible${question.id}`}
                        type="checkbox"
                        label={t('common.visibleToOthers')}
                        className='msg_pre_checkbox'
                        onClick={(e) => {
                            if (answers[question.id] && answers[question.id].answerValues.length > 0) {
                                answers[question.id].profileVisible = e.target.checked
                                setAnswers(answers)
                            }
                        }}
                    >
                    </Form.Check> : null}
            </Form.Group>
            {errResponse?.errorMessageByQuestionId?.[question.id] && Array.isArray(errResponse?.errorMessageByQuestionId?.[question.id]) ? 
                errResponse?.errorMessageByQuestionId?.[question.id].map((err, i) => {
                    return(
                        <Alert className='alert_questionaire' variant="danger" id={`err_${question.id}_${i}`}>{err}</Alert>
                    )
                })
            : 
                errResponse?.errorMessageByQuestionId?.[question.id] && (
                    <Alert className='alert_questionaire' variant="danger" id={`err_${question.id}`}>{errResponse?.errorMessageByQuestionId?.[question.id]}</Alert>
                )
            }
        </div> : null}
    </div>

    return (
        <div>{numberQuestions}</div>
    )
}

export default NumberQuestions;