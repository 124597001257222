// export const baseUrl = process.env.REACT_APP_BASE_URL;
export const baseUrl = import.meta.env.VITE_BASE_URL;

const homeURL = 'member/home';
const incompleteQueURL = 'member/profile/incomplete';
const questionnairesURL = 'member/getIncompleteQuestions';
const pdfURL = 'member/profile/download';
const saveAnswersURL = 'member/profile/answers';
const uploadAvatarURL = 'member/avatar/upload';
const uploadAudioURL = 'member/audio/upload';
const newViewMarkedURL = 'member/profile/hideNews'
const getAvatarURL = 'galleries/avatar';
const getAudioURL = 'audios';
const generateUserPinURL = 'member/generate/pin'

export async function getAvatar(data) {
  if(data.accessToken) {
    return fetch(`${baseUrl}/${getAvatarURL}/${data.avatarId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${data.accessToken}`,
        'Accept-Language': data.language, 
      },
      'mode': 'cors'
    })
      .then((response) =>{
        return (response)
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getAudio(data) {
  if(data.accessToken) {
    return fetch(`${baseUrl}/${getAudioURL}/${data.audioId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${data.accessToken}`,
        'Accept-Language': data.language, 
      },
      'mode': 'cors'
    })
      .then((response) =>{
        return (response)
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getHomeDetails(token_lang) {
  if (token_lang.accessToken) {
    return fetch(`${baseUrl}/${homeURL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language,
      },
      'mode': 'cors',
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function getIncompleteQuetions(token_lang) {
  if (token_lang.accessToken) {
    return fetch(`${baseUrl}/${incompleteQueURL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language, 
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function incompleteQuestionnaires(data) {
  const body = {
    "id": data.id,
    "unAnsweredQuestions": data.questionsId,
  }
  return fetch(`${baseUrl}/${questionnairesURL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': `Bearer ${data.accessToken}`,
      'Accept-Language': data.language, 
    },
    body: JSON.stringify(body),
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })    
  ).catch((err) => {
    throw err;
  });
}

export async function downloadPdf(data) {
  if (data.accessToken) {
    return fetch(`${baseUrl}/${pdfURL}/${data.type}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${data.accessToken}`,
        'Accept-Language': data.language, 
      },
      'mode': 'cors'
    })
      .then((response) =>{
        return (response)
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function saveAnswers(data) {
  return fetch(`${baseUrl}/${saveAnswersURL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': `Bearer ${data.accessToken}`,
      'Accept-Language': data.language, 
    },
    body: JSON.stringify(data.body),
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })    
  ).catch((err) => {
    throw err;
  });
}

export async function uploadAvatar(data) {
  
  return fetch(`${baseUrl}/${uploadAvatarURL}/${data.category}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${data.accessToken}`,
      'Accept-Language': data.language, 
    },
    body: data.form
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })    
  ).catch((err) => {
    throw err;
  });
}

export async function uploadAudio(data) {
  
  return fetch(`${baseUrl}/${uploadAudioURL}/${data.category}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${data.accessToken}`,
      'Accept-Language': data.language, 
    },
    body: data.form
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })    
  ).catch((err) => {
    throw err;
  });
}

export async function newsViewMarked(token_lang, newsid) {
  if (token_lang.accessToken) {
    return fetch(`${baseUrl}/${newViewMarkedURL}/${newsid}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language, 
      },
      'mode': 'cors'
    })
      .then((response) =>{
        return (response)
      })
      .catch((err) => {
        throw err;
      });
  }
}

export async function generateUserPin(token_lang) {
  if(token_lang.accessToken) {
    return fetch(`${baseUrl}/${generateUserPinURL}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token_lang.accessToken}`,
        'Accept-Language': token_lang.language, 
      },
      'mode': 'cors'
    })
    .then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      }))
    )
    .then(async (responseData) => {
      return responseData;
    })
    .catch((err) => {
      throw err;
    });
  }
}


